.l-wrap {
  max-width: 1220px;
  width: 100%;
  padding: 0 3rem;
  margin: 0 auto;
  @include sp {
    padding: 0 2rem;
  }
  
  &_over {
    min-height: 100%;
    position: relative;
    overflow: hidden;
    left: 0;
    transition: left 0.25s;

    &.is-active {
      left: -25rem;
      @include pc {
        left: -26rem;
      }
    }
  }

  &_area {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }

  &_cont {
    max-width: 100rem;
    width: 100%;
    margin: 0 auto;
  }

  &_990 {
    max-width: 99rem;
    width: 100%;
    margin: 0 auto;
  }

  &_980 {
    max-width: 98rem;
    width: 100%;
    margin: 0 auto;
  }

  &_960 {
    max-width: 96rem;
    width: 100%;
    margin: 0 auto;
  }

  &_920 {
    max-width: 92rem;
    width: 100%;
    margin: 0 auto;
  }

  &_850 {
    max-width: 85rem;
    width: 100%;
    margin: 0 auto;
  }

  &_840 {
    max-width: 84rem;
    width: 100%;
    margin: 0 auto;
  }

  &Faq {
    
  }
}