.p-option {
  &Cap {
    &_ttl {
      @include sp {
        font-size: 1.6rem;
      }
      span {
        margin-right: 2rem;
      }
    }
  }

  &Tool {
    &_flag {
      &--ttl {
        .space {
          @include tab02 {
            display: none;
          }
        }
      }
    }
  }

  &Contact {
    &_ttl {
      @include sp {
        font-size: 1.6rem;
      }
    }
    &_btn {
      max-width: 40rem;
      width: 100%;
      margin: 1.5rem auto 0;
    }
  }
}