.p-racehorces {
  &Human {
    &_text {
      line-height: 1.666;
    }

    &_caption {
      @include sp {
        text-align: left;
        text-indent: -1em;
        padding-left: 1em;
      }
    }
  }

  &Report {
    &_cont {
      padding: 8rem 4rem;
      @include sp {
        padding: 3rem 2rem;
      }

      &--ttl {
        line-height: 1.875;
      }

      &--detail {
        line-height: 1.5;
        @include sp {
          font-size: 1.4rem;
        }
      }
    }

    &_movie {
      max-width: 85rem;
      width: 100%;
      margin: 8rem auto 0;
      @include sp {
        margin-top: 4rem;
      }

      &--item {
        width: 47.8%;
        border-radius: 2rem;
        overflow: hidden;
        box-shadow: $shadow02;
        @include sp {
          border-radius: 1.5rem;
          box-shadow: $shadowSp02;
        }

        &:not(:first-child) {
          @include sp {
            margin-top: 2rem;
          }
        }
      }
    }

    &_movie3 {
      width: 100%;
      margin: 8rem auto 0;
      @include sp {
        margin-top: 4rem;
      }

      &--item {
        border-radius: 2rem;
        overflow: hidden;
        box-shadow: $shadow02;
        @include sp {
          border-radius: 1.5rem;
          box-shadow: $shadowSp02;
        }

        &:not(:first-child) {
          @include sp {
            margin-top: 2rem;
          }
        }

		p {
			padding: 0.5em;
		}
      }
    }

  }
}