.c-flex {
  display: flex;

  &_wrap {
    flex-wrap: wrap;
  }

  &_justify {
    &--bet {
      justify-content: space-between;
    }

    &--center {
      justify-content: center;
    }

    &--end {
      justify-content: flex-end;
    }

    &--around {
      justify-content: space-around;
    }
  }

  &_items {
    &--center {
      align-items: center;
    }

    &--start {
      align-items: flex-start;
    }

    &--end {
      align-items: flex-end;
    }
  }

  &_direction {
    &--reverse {
      flex-direction: row-reverse;
    }
  }
}