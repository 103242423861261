.c-modal {
  &_overlay {
    width: 100%;
    height: 100%;
    background-color: rgba( $white, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 1000;
    cursor: pointer;

    &.is-view {
      display: block;
    }
  }

  &Cont {
    max-width: 83rem;
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1001;
    display: none;
    @include sp {
      width: 100%;
      padding: 0 2rem;
    }
    &.is-view {
      display: block;
    }
  }

  &_movie {
    position: relative;
    width: 100%;
    padding-top: 56.25%;

    iframe,video {
      position: absolute;
      top: 0;
      right: 0;
      width: 100% !important;
      height: 100% !important;
      cursor: pointer;
    }
  }

  &_close {
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: -7rem;
    right: 0;
    @include sp {
      width: 4rem;
      height: 4rem;
      top: -5rem;
      right: 2rem;
    }
    
    span {
      width: 100%;
      height: 0.2rem;
      background-color: $darkgray;
      display: block;

      &:first-child {
        transform: rotate(-45deg);
      }
      &:last-child {
        transform: rotate(45deg);
      }
    }
  }
}