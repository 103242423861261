.p-research {
  &_point {
    font-size: 2rem;
    line-height: 1.75;
    font-weight: bold;

    @include tab {
      font-size: 1.8rem;
    }
    @include sp {
      font-size: 1.6rem;
    }
  }

  &_annotation {
    position: relative;

    &::after {
      content: "(Medicell)";
      position: absolute;
      font-size: 1.6rem;
      text-align: center;
      font-weight: bold;
      bottom: -1.5em;
      left: 0;
      right: 0;

      @include tab {
        font-size: 1.4rem;
      }
      @include sp {
        font-size: 1.2rem;
      }
    }
  }

  &_box {
    &_ttl {
      font-size: 3.2rem;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;

      @include tab {
        font-size: 2.4rem;
      }
      @include sp {
        font-size: 1.8rem;
      }
    }

    &_img {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
      height: auto;
      display: block;
    }
  }

  &_btn {
    max-width: 400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    .c-btn_cmn {
      &::after {
        transform: rotate(135deg);
      }
    }

    .p-research_btn_cmn {
      &--open {
        display: block;
      }
      &--close {
        display: none;
      }
    }

    &.is-active {
      .p-research_btn_cmn {
        &--open {
          display: none;
        }
        &--close {
          display: block;

          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}