.c-m {
  &t {
    &--100 {
      margin-top: 10rem;
      @include sp {
        margin-top: 6rem;
      }
    }
    &--80 {
      margin-top: 8rem;

      @include sp {
        margin-top: 6rem;
      }
    }

    &--70 {
      margin-top: 7rem;
      @include sp {
        margin-top: 4rem;
      }
    }
    
    &--60 {
      margin-top: 6rem;

      @include sp {
        margin-top: 4rem;
      }
    }

    &--55 {
      margin-top: 5.5rem;
      @include sp {
        margin-top: 3.5rem;
      }
    }

    &--50 {
      margin-top: 5rem;

      @include sp {
        margin-top: 3rem;
      }
    }

    &--45 {
      margin-top: 4.5rem;
      @include sp {
        margin-top: 3rem;
      }
    }
    
    &--40 {
      margin-top: 4rem;

      @include sp {
        margin-top: 3rem;
      }
    }

    &--35 {
      margin-top: 3.5rem;
      @include sp {
        margin-top: 2.5rem;
      }
    }

    &--30 {
      margin-top: 3rem;

      @include sp {
        margin-top: 2rem;
      }
    }

    &--25 {
      margin-top: 2.5rem;
      @include sp {
        margin-top: 1.5rem;
      }
    }

    &--20 {
      margin-top: 2rem;
      @include sp {
        margin-top: 1rem;
      }
    }

    &--15 {
      margin-top: 1.5rem;
      @include sp {
        margin-top: 1rem;
      }
    }

    &--10 {
      margin-top: 1rem;
      @include sp {
        margin-top: 0.5rem;
      }
    }

    &--5 {
      margin-top: 0.5rem;
    }

    &--0 {
      margin-top: 0 !important;
    }
  }
}