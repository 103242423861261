.p-company {
  &Local {
    .c-btn_cmn {
      @include tab {
        padding-right: 3%;
      }
      @include sp {
        padding-right: 0.5rem;
      }
      &:after {
        @include tab {
          right: 5%;
        }
        @include sp {
          width: 0.6rem;
          height: 0.6rem;
          margin-top: -0.4rem;
          right: 1rem;
        }
      }
    }
  }

  &Philosophy {
    &_cont {
      width: 100%;
      height: 14rem;
      @include sp {
        height: 10rem;
      }

      &--ttl {
        font-size: 6rem;
        line-height: 14rem;
        @include tab {
          font-size: 4rem;
        }
        @include sp {
          font-size: 3rem;
          line-height: 10rem;
        }
      }
    }
  }

  &Slogan {
    &_pic {
      max-width: 24.2rem;
      width: 75%;
      margin: 4rem auto 0;
      @include sp {
        max-width: none;
        width: 18rem;
        margin-top: 3rem;
      }
    }
  }

  &Mission {
    &_text {
      line-height: 1.5;
    }
  }

  &Message {
    &_wrap {
      @include tab02 {
        display: block;
      }
      &--right {
        width: 37.7%;
        @include tab {
          margin: 0 auto;
        }
        @include sp {
          width: 22rem;
          margin: 0 auto;
        }
      }

      &--left {
        width: 57.4%;
        @include tab {
          width: 100%;
          margin-top: 5rem;
        }
        @include sp {
          width: 100%;
          margin-top: 3rem;
        }
      }
    }

    &_pic {
      position: relative;
    }

    &_name {
      position: absolute;
      bottom: -2rem;
      left: -3rem;
      @include sp {
        bottom: -1rem;
        left: -2rem;
      }
      &--box {
        width: 13rem;
        height: 4rem;
        @include sp {
          width: 10rem;
          height: 3rem;
        }

        &Text {
          font-size: 1.6rem;
          line-height: 4rem;
          @include sp {
            font-size: 1.4rem;
            line-height: 3rem;
          }
        }
      }

      &--position {
        width: 14rem;
        @include sp {
          width: 12rem;
        }
      }
    }
  }

  &Bright {
    &_logo {
      width: 60%;
      margin: 4rem auto 0;
      @include sp {
        width: 100%;
        margin-top: 3rem;
      }
    }

    &_pic {
      width: 36.8%;
      margin: 4rem auto 0;
      @include sp {
        width: 75%;
        margin-top: 3rem;
      }
    }
  }

  &History {
    padding-bottom: 18.5rem;
    @include sp {
      padding-bottom: 8rem;
    }
  }
}