.p-kinmakubiryo {
  &Intro {
    &_ttl {
      span {
        @include sp {
          font-size: 2.8rem;
          letter-spacing: 0.1rem;
          padding: 0 0rem 1.5rem;
        }
      }
    }
    &_about {
      &--pic {
        max-width: 40.7rem;
        width: 100%;
        margin: 4rem auto 0;
        border-radius: 3rem;
        overflow: hidden;
        box-shadow: $shadow02;
        @include sp {
          margin-top: 3rem;
          box-shadow: $shadowSp02;
          border-radius: 1.5rem;
        }
      }

      &--text {
        line-height: 1.75;
      }
    }
  }

  &Exercise {
    &_text {
      line-height: 1.666;
    }
  }
}