@charset "UTF-8";


//color

//white
$white: #fff;
$offwhite: #F6F6F6;
$perlWhite: #F7F7F7;

//black
$black: #000;

//red
$red: #D30F1B;
$lightRed: #ED1B24;
$tomatoRed: #D64B4B;
$quietRed: #CF7A87;
$rose: #CA5175;

//blue
$blue: #06009F;
$lightblue: #389BFF;
$greenBlue: #2E66AD;
$seaBlue:#446DCB;
$darkBlue: #384194;

//gray
$gray: #DDD;
$darkgray: #404040;
$lightgray: #F8F8F8;
$dullColor: #c0c0c0;

//brown
$brown: #3B0709;
$woodBrown: #69442D;
$bronze: #A06834;

//yellow
$yellow: #FFA719;
$orange: #E8903F;
$orangeYellow: #F2B24A;
$lemon: #FFFE01;

//green
$green: #43964F;
$summerGreen: #3A6940;
$lightGreen: #1BAF5A;

//silver
$silver: #E6E6E6;

//purple
$purple: #910886;

//pink
$pink: #FD9CB6;
$azalea: #EA5795;



//font
//notoSans
$notoSans: 'Noto Sans JP', sans-serif;

//Abril Fatface
$abrilFatface: 'Abril Fatface', cursive;

//Josefin Sans
$josefin: 'Josefin Sans', sans-serif;


//transiton
$anime: 0.25s ease;
$animeSlow: 0.45s cubic-bezier(0.23, 1, 0.58, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.58, 1);


//box-shadow
$shadow: 0.2rem 0.3rem 2rem rgba( 0,0,0,0.26);
$shadowSp: 0.1rem 0.15rem 1rem rgba( 0,0,0,0.26);

$shadow02: 0.2rem 0.3rem 2rem rgba( 0,0,0,0.16);
$shadowSp02: 0.1rem 0.15rem 1rem rgba( 0,0,0,0.16);

$shadow03: 0 0.3rem 0.6rem rgba($black, 0.16);

$shadow04: 0.3rem 0.6rem 2rem rgba($black, 0.3);
$shadowSp04: 0.15rem 0.3rem 1rem rgba($black, 0.3);

$shadow_box: 0 0.3rem 2rem rgba( 0,0,0,0.26);
$shadow_boxSp: 0 0.15rem 1rem rgba( 0,0,0,0.26);
$shadow_text: 0.4rem 0.4rem 0.3rem rgba(0,0,0,0.2);
$shadow_textSp: 0.2rem 0.2rem 0.15rem rgba(0,0,0,0.2);