.c-list {
  &News {
    &_item {
      border-bottom: 0.1rem solid $red;

      a {
        padding: 1.5rem 0;
        display: block;
        @include sp {
          padding: 1rem 0;
        }
      }

      &--wrap {
        @include tab02 {
          flex-wrap: wrap;
          justify-content: flex-start;
        }
      }

      &--date {
        vertical-align: middle;
        @include tab02 {
          margin-right: 2rem;
        }
        span {
          font-size: 1.4rem;
          margin-left: 2rem;
          @include sp {
            margin-left: 1rem;
          }
        }
      }

      &--cate {
        font-size: 1.2rem;
        width: 12rem;
        height: 2.6rem;
        line-height: 2.6rem;
        display: block;
        @include sp {
          font-size: 1rem;
          width: 10rem;
          height: 2rem;
          line-height: 2rem;
        }

        &.news {
          background-color: #ED1B24;
        }

        &.seminar {
          background-color: $yellow;
        }

        &.release {
          background-color: $lightblue;
        }
      }

      &--ttl {
        width: calc( 100% - 26rem);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @include tab02 {
          width: 100%;
        }
        @include tab {
          margin-top: 1rem;
        }
        @include sp {
          margin-top: 0.5rem;
        }
      }
    }
  }

  &_column02 {
    @include sp {
      display: block;
    }

    &--item {
      max-width: 47.8rem;
      width: 49%;
      @include sp {
        width: 100%;
      }
      &:not(:nth-child(-n+2)) {
        margin-top: 2rem;
      }
      &:not(:first-child) {
        @include sp {
          margin-top: 1rem;
        }
      }
    }
  }

  &_column03 {
    display: flex;
    flex-wrap: wrap;
    @include sp {
      display: block;
    }

    &--item {
      max-width: 30.7rem;
      width: 30.6666%;
      @include sp {
        max-width: none;
        width: 100%;
      }

      &:not(:nth-child(3n)) {
        margin-right: 4%;
        @include sp {
          margin-right: 0;
        }
      }

      &:not(:nth-child(-n+3)) {
        margin-top: 2rem;
      }

      &:not(:first-child) {
        @include sp {
          margin-top: 1rem;
        }
      }

    }
  }

  &_research {
    &--item {
      @include sp {
        display: block;
      }
      &:not(:first-child) {
        margin-top: 4rem;
        @include sp {
          margin-top: 2rem;
        }
      }
    }

    &--ttl {
      font-size: 2rem;
      width: 15rem;
      display: block;
      @include tab {
        font-size: 1.7rem;
        width: 12rem;
      }
      @include sp {
        font-size: 1.5rem;
        width: auto;
      }
    }

    &--box {
      width: calc( 100% - 15rem);
      @include tab {
        width: calc( 100% - 12rem);
      }
      @include sp {
        width: auto;
        margin-top: 0.5rem;
      }
    }
  }

  &_localBtn {
    @include sp {
      justify-content: space-between;
    }
    &--item {
      width: 23%;
      @include sp {
        width: 48%;
      }
      &:not(:nth-child(4n)) {
        margin-right: 2.5%;
        @include sp {
          margin-right: 0;
        }
      }

      &:not(:nth-child(-n+4)) {
        margin-top: 3rem;
      }

      &:not(:nth-child(-n+2)) {
        @include sp {
          margin-top: 1.5rem;
        }
      }
    }
  }

  &_option {
    @include sp {
      display: block;
    }
    &--item {
      margin-right: 3.2%;
      &:not(:nth-child(-n+3)) {
        margin-top: 3rem;
      }
      &:not(:first-child) {
        @include sp {
          margin: 2rem 0 0;
        }
      }
      &Small {
        width: 31.2%;
        @include sp {
          width: 100%;
        }

        .c-boxOption {
          @include pc04 {
            min-height: 58rem;
          }
        }
      }

      &Large {
        width: 48.4%;
        @include sp {
          width: 100%;
        }
      }

      &Right {
        margin-right: 0;
      }
    }
  }

  &Voice {
    &_item {
      @include sp {
        display: block;
      }
    }
    &_person {
      width: 18%;
      @include sp {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(2rem);
      }
      &--pic {
        border-radius: 50%;
        overflow: hidden;
        @include sp {
          width: 8rem;
          margin-right: 2rem;
        }
      }

      &--name {
        font-size: 2rem;
        margin-top: 2rem;
        @include tab {
          font-size: 1.6rem;
        }
        @include sp {
          font-size: 1.6rem;
          margin-top: 0;
        }
      }
    }

    &_comment {
      width: 73%;
      margin-top: 2.5rem;
      position: relative;
      @include tab {
        margin-top: 0;
      }
      @include sp {
        width: 100%;
      }
      &:before {
        content: '';
        width: 4.5rem;
        height: 4.5rem;
        background-color: $white;
        box-shadow: $shadow_box;
        margin: auto 0;
        transform: rotate(-45deg) skew( calc((90deg - 30deg) / 2), calc((90deg - 30deg) / 2) );
        position: absolute;
        top: 0;
        bottom: 0;
        left: -2rem;
        z-index: 1;
        @include tab {
          width: 3rem;
          height: 3rem;
          left: -1rem;
        }
        @include sp {
          width: 4rem;
          height: 4rem;
          box-shadow: $shadow_boxSp;
          transform: rotate(-5deg) skew( calc((90deg - 30deg) / 2), calc((90deg - 30deg) / 2) );
          top: auto;
          left: 2rem;
          bottom: -1.1rem;
        }
      }

      &--inner {
        min-height: 13.5rem;
        padding: 4rem 5.3%;
        background-color: $white;
        position: relative;
        display: flex;
        align-items: center;
        z-index: 2;
        @include tab {
          min-height: 12rem;
          padding: 2rem 5.3%;
        }
        @include sp {
          min-height: 0;
          padding: 2rem;
        }
      }

      &--text {
        @include tab {
          font-size: 1.4rem;
        }
        @include sp {
          font-size: 1.3rem;
        }
      }
    }

    &_item {
      &:not(:first-child) {
        margin-top: 3rem;
      }
      &:nth-child(even) {
        .c-listVoice_comment {
          @include tab {
            margin-top: 0;
          }
          &:before {
            left: auto;
            right: -2rem;
            @include tab {
              right: -1rem;
            }
            @include sp {
              left: auto;
              right: 2rem;
              transform: rotate(95deg) skew(30deg,30deg);
            }
          }
        }

        .c-listVoice_person {
          @include sp {
            transform: translateX(-2rem);
          }
        }
      }
    }
  }

  &Tool {
    @include sp {
      display: block;
    }
    &_item {
      .c-text_size--default {
        @include tab {
          font-size: 1.4rem;
        }
      }
    }
	&_ttl {
	position: relative;
	  .ico-new {
		display: inline-block;
		background-color: $red;
		margin-right: .75em;
		padding: .15em .75em;
		font-size: .8em;
		line-height: 1;
		color: #fff;
	  }
	}
    &_column02 {
      &--item {
        max-width: 41.3rem;
        width: 46%;
        @include sp {
          max-width: none;
          width: 100%;
        }

        &:not(:nth-child(-n+2)) {
          margin-top: 4rem;
        }
        &:not(:first-child) {
          @include sp {
            margin-top: 3rem;
          }
        }
      }

      &--pic {
        width: 87.4%;
        margin: 0 auto;
        @include sp {
          width: 100%;
        }
      }
    }

    &_column03 {
      &--item {
        max-width: 28rem;
        width: 30.4%;
        @include sp {
          max-width: none;
          width: 100%;
        }

        &:not(:nth-child(-n+3)) {
          margin-top: 4rem;
        }
        &:not(:first-child) {
          @include sp {
            margin-top: 3rem;
          }
        }
      }
    }


    &_price {
      span {
        font-size: 2rem;
        font-weight: bold;
        @include sp {
          font-size: 1.8rem;
        }
      }
    }
  }

  &_btn {
    &--item {
      @include sp {
        width: 100%;
      }
    }
    &--btn {
      max-width: 40rem;
      width: 100%;
      margin: 1.5rem auto 0;
      @include sp {
        max-width: none;
      }
    }
  }

  &_facility {
    @include sp {
      justify-content: space-between;
    }
    &--item {
      width: 31.2%;
      @include sp {
        width: 100%;
      }
      &:not(:nth-child(3n)) {
        margin-right: 3.2%;
        @include sp {
          margin-right: 0;
        }
      }
      &:not(:nth-child(-n+3)) {
        margin-top: 3rem;
      }
      &:not(:first-child) {
        @include sp {
          margin-top: 2rem;
        }
      }
    }
  }

  &_num {
    &--item {
      padding-left: 2.5rem;
      line-height: 1.666;
      position: relative;
      @include sp {
        padding-left: 2rem;
      }
      &:not(:first-child) {
        margin-top: 2rem;
      }

      span {
        position: absolute;
        top: 0.35rem;
        left: 0;
        line-height: 1;
        @include tab {
          top: 0.3rem;
        }
        @include sp {
          font-size: 2rem;
          top: 0.5rem;
        }
      }

      a {
        text-decoration: underline;
      }

      h4 {
        @include sp {
          font-size: 1.8rem;
        }
      }
    }
  }

  &_seminar {
    margin-top: 2rem;
    &--item {
      &:not(:first-child) {
        margin-top: 3rem;
        @include sp {
          margin-top: 1rem;
        }
      }

      span {
        width: 6rem;
        display: block;
        @include sp {
          width: 5rem;
        }
		&.long {
			width: 9rem;
		}
      }
    }

    &--text {
      width: calc( 100% - 6rem);
        &.long {
          width: calc(100% - 9rem);
        }
    }

	&--item.full {
		flex-direction: column;
		span {
			width: 100%;
		}
	    p {
			width: 100%;
		}
	}

  }
}