.c-color {
  &_text {
    &--white {
      color: $white !important;
    }

    &--red {
      color: $red !important;
    }

    &--summerGreen {
      color: $summerGreen;
    }

    &--woodBrown {
      color: $woodBrown;
    }

    &--darkBlue {
      color: $darkBlue;
    }

    &--rose {
      color: $rose;
    }

    &--orange {
      color: $orange;
    }
  }

  &_bg {
    &--white {
      background-color: $white;
    }

    &--offwhite {
      background-color: $offwhite;
    }

    &--perlWhite {
      background-color: $perlWhite;
    }

    &--red {
      background-color: $red !important;
    }

    &--gray {
      background-color: $gray;
    }

    &--darkgray {
      background-color: $darkgray;
    }

    &--lightgray {
      background-color: $lightgray;
    }

    &--brown {
      background-color: $brown;
    }

    &--green {
      background-color: $green;
    }

    &--greenBlue {
      background-color: $greenBlue;
    }

    &--silver {
      background-color: $silver;
    }

    &--quietRed {
      background-color: $quietRed;
    }

    &--orange {
      background-color: $orange;
    }

    &--bronze {
      background-color: $bronze;
    }
  }
}