.p-recruit {
  &_btn {
    width: 23%;
    position: absolute;
    top: 0;
    right: -3.1%;
    transform: translateY(-50%);
    @include sp {
      width: 12rem;
      right: -1.5rem;
    }
  }

  &Local {
    &_list {
      max-width: 74.6rem;
      margin: 0 auto;

      &--item {
        width: 31%;
        margin-right: 3.5%;
        @include sp {
          margin-right: 0;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        &.last {
          margin-right: 0;
        }

        &:not(:nth-child(-n+3)) {
          margin-top: 3rem;
        }

        .c-btn_white--text {
          @include sp {
            font-size: 1.1rem;
          }
        }
      }
    }
  }

  &Message {
    &_ttl {
      span {
        @include sp {
          letter-spacing: 0.1rem;
        }
      }
    }
    &_wrap {
      position: relative;
    }
    &_box {
      width: 60.5%;
      background-color: rgba( $white, 0.6 );
      position: relative;
      z-index: 2;
      @include tab02 {
        width: 100%;
      }
      @include tab {
        margin-top: -4rem;
      }
      @include sp {
        margin-top: -2rem;
      }
    }

    &_pic {
      width: 54%;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      @include tab02 {
        width: 65%;
        margin: 0 auto;
        position: relative;
        right: auto;
        top: auto;
        transform: translateY(0);
      }
    }
  }

  &About {
    &_wrap {
      @include sp {
        display: block;
      }
      &--left {
        width: 42.4%;
        @include sp {
          width: 25rem;
          margin: 0 auto;
        }
      }

      &--right {
        width: 53.4%;
        @include sp {
          width: 100%;
          margin-top: 2rem;
        }
      }
    }

    &_pic {
      border-radius: 2rem;
      overflow: hidden;
      box-shadow: $shadow02;
      @include sp {
        box-shadow: $shadowSp02;
      }
    }
  }

  &Candidate {
    &_list {
      width: 100%;
      @include sp {
        display: block;
      }

      &--item {
        max-width: 40rem;
        width: 41.6%;
        @include sp {
          width: 100%;
        }

        &:first-child {
          margin-right: 3rem;
          @include sp {
            margin: 0 0 2rem;
          }
        }
      }

      &--bnr {
        @include sp {
          width: 20rem;
          margin: 1.5rem auto 0;
        }
      }
    }

    &_cont {
      &Wrap {
        @include sp {
          display: block;
        }
        &:first-child {
          padding-bottom: 8rem;
          border-bottom: 0.2rem solid $gray;
          @include sp {
            padding-bottom: 4rem;
          }
        }
        &:nth-child(2) {
          padding-bottom: 8rem;
          border-bottom: 0.2rem solid $gray;
          @include sp {
            padding-bottom: 4rem;
          }
        }
        &--left {
          width: 40%;
          position: relative;
          @include sp {
            width: 25rem;
            margin: 0 auto;
          }
        }

        &--right {
          width: 56.4%;
          @include sp {
            width: 100%;
            margin-top: 4rem;
          }
        }
      }

      &--pic {
        padding-left: 2rem;
      }

      &--box {
        position: absolute;
        bottom: -2rem;
        left: 0;
      }

      &--position {
        font-size: 1.6rem;
        width: 19.6rem;
        height: 4rem;
        line-height: 4rem;
        display: block;
        @include sp {
          font-size: 1.2rem;
          width: 14rem;
          height: 3rem;
          line-height: 3rem;
        }
      }

      &--name {
        font-size: 1.8rem;
        width: 13rem;
        height: 4rem;
        line-height: 4rem;
        margin-top: 1rem;
        display: block;
        @include sp {
          font-size: 1.4rem;
          width: 10rem;
          height: 3rem;
          line-height: 3rem;
          margin-top: 0.5rem;
        }
      }
    }
  }

  &Work {
    &_list {
      @include sp {
        display: block;
      }
      &--item {
        width: 30%;
        @include tab04 {
          width: 48%;
        }
        @include sp {
          width: 100%;
        }

        &:not(:nth-child(3n)) {
          margin-right: 5%;
          @include tab04 {
            margin-right: 0;
          }
        }

        &:nth-child(2n) {
          @include tab04 {
            margin-left: 4%;
          }
          @include sp {
            margin-left: 0;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &:not(:nth-child(-n+3)) {
          margin-top: 4rem;
        }

        &:not(:nth-child(-n+2)) {
          @include tab04 {
            margin-top: 4rem;
          }
        }

        &:not(:first-child) {
          @include sp {
            margin-top: 3rem;
          }
        }
      }
    }
  }

  &Entry {
    position: relative;
    &_inner {
      padding: 10rem 0;
      @include sp {
        padding: 5rem 0;
      }
    }

    &_list {
      @include sp {
        display: block;
      }
      &--item {
        transform: translateY(1.7rem);
        @include sp {
          transform: translateY(0);
          margin-bottom: 3rem !important;
        }
      }
    }
  }

  &Entry2 {
    position: relative;
	margin-bottom: 10rem;

    &_inner {
      padding: 10rem 0;
      @include sp {
        padding: 5rem 0;
      }
    }

	h2 {
		font-size: 5.0rem;
		@include sp {
			font-size: 2.4rem;
		}
	}

	&_list {
		align-items: stretch;
		@include sp {
			display: block;
		}

		&--item {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100rem;
			margin: 0 3rem;
			padding: 2rem;
			@include sp {
				width: 100%;
				margin: 0;
			}
			&:first-of-type {
				@include sp {
					margin-bottom: 3rem;
				}
			}


		}
	}
  }

  &Slogan {
    &_text {
      line-height: 1.44;
    }
  }

  .c-ttl_sub--en {
    @include sp {
      font-size: 3.2rem;
    }
  }
}