.p-pets {
  &Intro {
    &_movie {
      &--text {
        line-height: 1.7;
      }

      &--pic {
        max-width: 40.7rem;
        width: 100%;
        margin: 1.5rem auto 0;
        border-radius: 2rem;
        overflow: hidden;
        @include sp {
          border-radius: 1rem;
        }
      }
    }
  }
}