.c-btn {
  &_header {
    color: $white;
    font-size: 1.6rem;
    font-weight: 500;
    width: 100%;
    height: 5rem;
    background-color: $red;
    border: 0.2rem solid $red;
    border-radius: 1rem;
    line-height: 4.6rem;
    text-align: center;
    display: block;
    box-shadow: 0.2rem 0.3rem 1.1rem rgba( $black , 0.26);
    @include pc {
      transition: 0.4s;
    }
    @include sp {
      color: $red;
      font-size: 1.2rem;
      height: 100%;
      line-height: 1.4;
      background-color: $white;
      border: 1px solid $red;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      @include sp {
        display: block;
      }
    }

    &.blue {
      background-color: $blue;
      border-color: $blue;
      @include sp {
        color: $white;
        border-color: $blue;
      }
    }

    &.contact {
      @include sp {
        padding-top: 2.7rem;
        position: relative;
      }
      &:before {
        @include sp {
          content: '';
          width: 2.6rem;
          height: 1.8rem;
          margin: 0 auto;
          background: url('../img/common/ico_contact01.svg') no-repeat;
          background-size: contain;
          position: absolute;
          top: 1.4rem;
          right: 0;
          left: 0;
        }
      }
    }

    &:hover {
      @include pc {
        opacity: 1;
        color: $red;
        background-color: $white;
      }

      &.blue {
        @include pc {
          color: $blue;
        }
      }
    }
  }

  &_contact {
    color: $white;
    font-size: 1.8rem;
    font-weight: bold;
    width: 100%;
    height: 7rem;
    background-color: $red;
    line-height: 7rem;
    text-align: center;
    border-radius: 3.5rem;
    box-shadow: 2px 3px 20px rgba( $black , 0.26);
    display: block;
    @include sp {
      font-size: 1.4rem;
      height: 5rem;
      line-height: 5rem;
    }

    &--tel {
      line-height: 1 !important;
      background-color: $white;
      text-align: left;
      padding-left: 12%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:before {
        content: '';
        width: 12.5%;
        padding-bottom: 7.5%;
        background: url('../img/common/ico_customer_tel01.gif');
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 19%;
        transform: translateY(-50%);
        @include tab {
          left: 15%;
        }
      }

      &.pc {
        display: flex;
        @include sp {
          display: none;
        }
      }

      &.sp {
        display: flex;
        @include pc {
          display: none;
        }
      }

      &Wrap {
        text-align: center;
      }

      &Num {
        color: $black;
        font-size: 2.4rem;
        letter-spacing: 0.2;
        @include sp {
          font-size: 1.8rem;
        }
      }

      &Time {
        color: $black;
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: 0.5rem;
        display: block;
        @include sp {
          font-size: 1rem;
          margin-top: 0.3rem;
        }
      }

      &.customer {
        .c-btn_contact--tel {
          &Wrap {
            // padding-left: 6.5rem;
            // position: relative;
            // @include sp {
            //   padding-left: 4rem;
            // }
            // &:before {
            //   content: '';
            //   width: 5rem;
            //   height: 3rem;
            //   background: url('../img/common/ico_customer_tel01.svg') no-repeat;
            //   background-size: contain;
            //   position: absolute;
            //   top: 50%;
            //   left: 0;
            //   transform: translateY(-50%);
            //   @include sp {
            //     width: 3.5rem;
            //     height: 2.1rem;
            //   }
            // }
          }

          &Num {
            color: #1C1C1C;
          }
        }
      }
    }

    &--inquiry {
      border: 0.4rem solid $red;
      line-height: 6.2rem;
      transition: 0.4s;
      @include sp {
        border-width: 0.2rem;
        line-height: 4.6rem;
      }
      &:hover {
        @include pc {
          color: $red;
          background-color: $white;
          opacity: 1;
        }
      }
    }
  }

  &_hamburger {
    width: 100%;
    height: 100%;
    background-color: $red;
    border: 0.1rem solid $red;
    border-radius: 1rem;
    box-shadow: 0.2rem 0.3rem 1.1rem rgba( $black , 0.26);
    position: relative;
    transition: background-color 0.4s;

    span {
      width: 2.6rem;
      height: 0.1rem;
      margin: auto;
      background-color: $white;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      transition: 0.4s;

      &:first-child {
        transform: translateY(-0.8rem);
      }

      &:last-child {
        transform: translateY(0.8rem);
      }
    }

    &.is-active {
      background-color: $white;

      span {
        background-color: $red;

        &:first-child {
          transform: translateY(0) rotate(-45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:last-child {
          transform: translateY(0) rotate(45deg);
        }
      }
    }
  }

  &_cmn {
    color: $white;
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    width: 100%;
    height: 7rem;
    background-color: $red;
    border: 0.4rem solid $red;
    border-radius: 3.5rem;
    line-height: 6.2rem;
    box-shadow: $shadow;
    display: block;
    position: relative;
    @include pc {
      transition: color $anime, background-color $anime;      
    }
    @include sp {
      font-size: 1.4rem;
      height: 5rem;
      border-width: 0.2rem;
      line-height: 4.4rem;
      border-radius: 3rem;
      box-shadow: $shadowSp;
    }
    &:after {
      content: '';
      width: 8px;
      height: 8px;
      border: 0px;
      border-top: solid 2px $white;
      border-right: solid 2px $white;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 50%;
      right: 10%;
      margin-top: -5px;
      @include pc {
        transition: 0.4s;
      }
    }
    &:hover {
      @include pc {
        color: $red;
        background-color: $white;
        opacity: 1;
  
        &:after {
          border-color: $red;
        }
      }
    }

    &--accordion {
      &:after {
        transform: rotate(135deg);
      }

      &.is-active {
        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    &--pdf {
      &:after {
        width: 1.7rem;
        height: 1.8rem;
        margin: auto 0;
        border: none;
        background: url('../img/common/ico_btn01.svg') no-repeat;
        background-size: contain;
        transform: rotate(0);
        right: 7.5%;
        top: 0;
        bottom: 0;
        transition: background-image $anime;
      }

      &:hover {
        &:after {
          background-image: url('../img/common/ico_btn01_r.svg');
        }
      }
    }


    &--16 {
      font-size: 1.6rem;
      @include tab {
        font-size: 1.4rem;
      }
      @include sp {
        font-size: 1.2rem;
      }
    }

    &.c-flex {
      display: flex;
      line-height: 1.375;
    }
  }

  &_white {
    width: 100%;
    background-color: $white;
    border-radius: 3.5rem;
    box-shadow: $shadow;
    overflow: hidden;
    display: block;
    position: relative;
    transition: background-color $anime;
    cursor: pointer;
    @include tab {
      border-radius: 3rem;
    }
    @include sp {
      box-shadow: $shadowSp;
      border-radius: 2.5rem;
    }
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 3.5rem;
      background-color: $white;
      position: absolute;
      top: 0;
      left: 0;
      cursor: pointer;
      @include pc {
        transition: transform $anime;
      }
      @include tab {
        border-radius: 3rem;
      }
      @include sp {
        border-radius: 2.5rem;
      }
    }
    &:after {
      content: '';
      width: 1.3rem;
      height: 0.8rem;
      margin: auto 0;
      background: url('../img/common/arrow_index01.svg') no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 8.7%;
      @include pc {
        transition: background-image $anime;
      }
      @include tab {
        width: 0.9rem;
        height: 0.6rem;
        right: 5.5%;
      }
      @include sp {
        width: 0.7rem;
        height: 0.4rem;
        right: 1rem;
      }
    }

    &--wrap {
      width: 100%;
      height: 7rem;
      @include tab {
        height: 6rem;
        padding-right: 2%;
      }
      @include sp {
        height: 5rem;
      }
    }

    &--text {
      font-size: 1.6rem;
      line-height: 1.375;
      position: relative;
      z-index: 1;
      @include pc {
        transition: color $anime;
      }
      @include tab {
        font-size: 1.3rem;
      }
      @include sp {
        font-size: 1.2rem;
      }
    }

    &:hover {
      @include pc {
        background-color: $red;
        opacity: 1;
      }
      &:before {
        @include pc {
          transform: scale(0);
        }
      }
      &:after {
        @include pc {
          background-image: url('../img/common/arrow_index01_w.svg');
        }
      }

      .c-btn_white--text {
        @include pc {
          color: $white;
        }
      }
    }

    &--noArrow {
      &:after {
        display: none;
      }

      .c-btn_white--wrap {
        padding: 0;
      }
    }

    &--link {
      &:after {
        transform: rotate(-90deg);
      }
    }
  }

  &_search {
    width: 100%;
    height: 7rem;
    position: relative;
    @include sp {
      font-size: 1.4rem;
      height: 5rem;
      padding-right: 0.5rem;
    }
    &:after {
      content: '';
      width: 1.3rem;
      height: 0.8rem;
      margin: auto 0;
      background: url('../img/common/arrow_index01.svg') no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 7%;
      transition: transform $anime;
      @include sp {
        width: 0.9rem;
        height: 0.6rem;
      }
    }

    &.is-active {
      &:after {
        transform: rotate(-180deg);
      }
    }

    &--sub {
      font-size: 1.6rem;
      width: 100%;
      height: 6rem;
      transition: background-color $anime;
      border-bottom: 0.2rem solid $gray;
      @include sp {
        font-size: 1.3rem;
        height: 4rem;
      }
      &:hover {
        background-color: $silver;
      }

      &.is-close {
        background-color: $perlWhite;
      }

      &.is-active {
        background-color: $silver;
      }

      &.js-facility-accordion-trigger {
        position: relative;
        &:after {
          content: "";
          width: 1.3rem;
          height: 0.8rem;
          margin: auto 0;
          background: url(../img/common/arrow_index01.svg) no-repeat;
          background-size: contain;
          position: absolute;
          bottom: 0;
          right: 7%;
          top: 0;
          transition: transform $anime;
          @include sp {
            height: 0.6rem;
            width: 0.9rem;
          }
        }

        &.is-active {
          &:after {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
}