.c-scroll {
  &_fadein {
    opacity: 0;
    transform: translateY(5rem);
    transition: 0.4s;

    &.is-animated {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &_delay {
    &--200 {
      transition-delay: 200ms;
    }
    &--400 {
      transition-delay: 400ms;
    }
    &--600 {
      transition-delay: 600ms;
    }
  }

  &_enter {
    &.is-animated {
      .c-scroll {
        &_fadein {
          opacity: 1;
          transform: translateY(0); 
        }
      }
    }
  }
}