.c-sub {
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: calc( 100% - 10rem);
    background: url('../img/common/sub_bg01.png') repeat-y;
    background-size: 100% auto;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    @include sp {
      height: calc( 100% - 5rem);
    }
  }

  .c-ttl_textShadow {
    @include sp {
      font-size: 2rem;
    }
  }

  &_inner {
    position: relative;
  }

  &_topIco {
    width: 10.7%;
    margin-top: -5.2%;
    border-radius: 9.3%;
    box-shadow: $shadow;
    overflow: hidden;
    transform: rotate(-45deg);
    position: absolute;
    top: 0;
    right: 8.5%;
    z-index: 100;
    @include pc03 {
      width: 15rem;
      margin-top: 0;
      border-radius: 1.4rem;
      right: 12rem;
      top: -7.5rem;
    }
    @include sp {
      margin-top: 0;
      width: 8rem;
      right: 2rem;
      top: -4rem;
      box-shadow: $shadowSp;
    }
  }

  &Scene {
    &:before {
      height: 100%;
      background-image: url('../img/common/sub_bg02.png');
      z-index: auto;
    }
    &_inner {
      padding-top: 8rem;
      position: relative;
      @include sp {
        padding: 4rem 2rem 0;
      }
    }

    &_ico {
      max-width: 25.5rem;
      width: 20.9%;
      margin-top: -12.3%;
      position: absolute;
      top: 0;
      right: 0;
      @include sp {
        max-width: none;
        width: 10rem;
        margin-top: 0;
        top: -6rem;
        right: 2rem;
      }
    }

    &_exercise {
      &--item {
        &:not(:nth-child(-n+2)) {
          margin-top: 3rem;
          @include tab {
            margin-top: 2rem;
          }
        }

        &:not(:first-child) {
          @include sp {
            margin-top: 2rem;
          }
        }
      }
    }

    &_link {
      &List {
        @include tab {
          flex-wrap: wrap;
          justify-content: space-between;
        }

        &--item {
          @include tab {
            width: 49%;
            margin: 0 !important;
          }

          &:not(:nth-child(-n+2)) {
            @include tab {
              margin-top: 2rem !important;
            }
          }

          &:not(:first-child) {
            @include sp {
              margin-top: 2rem;
            }
          }
        }
      }
    }

    &Intro {
      &_text {
        line-height: 1.5;
      }
    }
  }

  &_404 {

    &--inner {
      width: 100%;
      padding: 10rem 0;
      background-color: $white;
    }

    &--btn {
      max-width: 40rem;
      width: 100%;
      margin: 8rem auto 0;
      @include sp {
        margin-top: 6rem;
      }
    }
  }
}