@mixin pc($width: 768px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin pc02 ($width: 1201px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin pc03 ($width: 1401px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin pc04 ($width: 901px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin pc05 ($width: 1025px) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin tab {
  @media screen and (min-width: 768px) and (max-width: 900px) {
    @content;
  }
}

@mixin tab02($width: 900px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin tab03 {
  @media screen and (min-width: 768px) and (max-width: 1250px) {
    @content;
  }
}

@mixin tab04 {
  @media screen and (min-width: 768px) and (max-width: 1025px) {
    @content;
  }
}

@mixin tab05 {
  @media screen and (max-width: 1025px) {
    @content;
  }
}

@mixin tab06 {
  @media screen and (min-width: 900px) and (max-width: 1025px) {
    @content;
  }
}

@mixin tab07($width: 1200px) {
  @media screen and (max-width: $width) {
    @content;
  }
}


@mixin sp($width: 767px) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin sp02($width: 360px) {
  @media screen and (max-width: $width) {
    @content;
  }
}
