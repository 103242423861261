.c-anime {
  &_small {
    &--box {
      @include pc {
        transition: transform $animeSlow;
        position: relative;
      }
      &:after {
        @include pc {
          content: '';
          width: 100%;
          height: 100%;
          background-color: #000;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          transition: opacity $animeSlow;
        }
      }
    }

    &:hover {
      .c-anime_small--box {
        @include pc {
          transform: scale(0.8);
        }
        &:after {
          @include pc {
            opacity: 0.4;
          }
        }
      }
    }
  }
}