html {
  font-size: 62.5%;
  @include sp {
    font-size: calc(100vw / 37.5);
  }
}

body {
  color: $black;
  font-family: $notoSans;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0;

  &.is-noscroll {
    overflow: hidden;
  }

  .pc {
    @include pc {
      display: block;
    }
  
    @include sp {
      display: none;
    }
  }
}

main {
  padding-top: 14.7rem;
  display: block;

  padding: 0;
  @include sp {
    padding-top: 8rem;

    padding: 0;
  }
}

p,h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}

a {
  color: $black;
  font-family: $notoSans;
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  @include pc {
    transition: opacity $anime;
  }
  &:hover {
    @include pc {
      opacity: 0.8;
    }
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

ul {
  margin: 0;
  padding: 0;
}


.pc04 {
  @include pc04 {
    display: block;
  }
  @include tab02 {
    display: none;
  }
}

.pc05 {
  display: none;

  @include pc05 {
    display: block;
  }
}

.tab {
  @include pc {
    display: none;
  }

  @include tab {
    display: block;
  }

  @include sp {
    display: none;
  }
}

.tab02 {
  @include pc {
    display: none;
  }

  @include tab02 {
    display: block;
  }
}

.tab04 {
  display: none;

  @include tab04 {
    display: block;
  }
}

.tab05 {
  display: none;
  @include tab05 {
    display: block;
  }
}

.sp {
  @include pc {
    display: none;
  }

  @include sp {
    display: block;
  }
}

.sp02 {
  display: none;

  @include sp02 {
    display: block;
  }
}

.abrilFatface {
  font-family: $abrilFatface;
  font-weight: 400;
}

.josefin {
  font-family: $josefin;
  font-weight: 700;
}

.displayNone {
  display: none;
}

button {
	background: none;
	border: none;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
  cursor: pointer;
}

.displayInlineBlock {
  display: inline-block;
}


//js関連
.js-modal {
  cursor: pointer;
}

form {
  input[type='text'],
  input[type="email"],
  select {
    line-height: 1;
  }

  input[name="mwform_submitButton-242"] {
    text-align: center;
  }
}

.signage {
  &.mysite-form {
    @include sp {
      background-image: url('/wordpress/wp-content/uploads/2018/11/mysite-header.jpg') !important;
    }
  }

  &.seminar-form {
    @include sp {
      background-image: url('/wordpress/wp-content/uploads/2017/09/0000.jpg') !important;
    }
  }
}