.c-text {
  &_weight {
    &--normal {
      font-weight: 400;
    }
    &--medium {
      font-weight: 500;
    }
    &--bold {
      font-weight: 700;
    }
    &-black {
      font-weight: 900;
    }
  }

  &_size {
    &--default {
      font-size: 1.6rem;
      line-height: 1.875;
      letter-spacing: 0.1rem;
      @include sp {
        font-size: 1.4rem;
      }
    }

    &--large {
      font-size: 2.5rem;
      @include tab {
        font-size: 2.2rem;
      }
      @include sp {
        font-size: 1.8rem;
      }
    }

    &--40 {
      font-size: 4rem;
      @include tab {
        font-size: 3rem;
      }
      @include sp {
        font-size: 2rem;
      }
    }

    &--32 {
      font-size: 3.2rem;
      @include tab {
        font-size: 2.4rem;
      }
      @include sp {
        font-size: 2rem;
      }
    }

    &--25 {
      font-size: 2.5rem;
      @include tab {
        font-size: 2.2rem;
      }
      @include sp {
        font-size: 1.8rem;
      }
    }

    &--24 {
      font-size: 2.4rem;
      @include tab {
        font-size: 2.2rem;
      }
      @include sp {
        font-size: 1.8rem;
      }
    }

    &--22 {
      font-size: 2.2rem;
      @include sp {
        font-size: 1.8rem;
      }
    }

    &--20 {
      font-size: 2rem;
      @include tab {
        font-size: 1.8rem;
      }
      @include sp {
        font-size: 1.6rem;
      }
    }

    &--18 {
      font-size: 1.8rem;
      @include sp {
        font-size: 1.6rem;
      }
    }

    &--16 {
      font-size: 1.6rem;
      @include sp {
        font-size: 1.4rem;
      }
    }

    &--14 {
      font-size: 1.4rem;
      @include sp {
        font-size: 1.2rem;
      }
    }
  }

  &_align {
    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }

    &--left {
      text-align: left;

      &Sp {
        @include sp {
          text-align: left;
        }
      }
    }
  }

  &_feature {
    font-feature-settings: 'palt';
  }

  &_space {
    &--1 {
      letter-spacing: 0.1rem;
    }
    &--2 {
      letter-spacing: 0.2rem;
    }
    &--3 {
      letter-spacing: 0.3rem;
    }
  }

  &_indent {
    text-indent: -1em;
    padding-left: 1em;
  }
}