.p-kinmaku {
  &_title {

  }

  &_block {
    &_ttl {
      margin-top: 6rem;
  
      @include sp {
        margin-top: 3rem;
      }
    }

    &_txt {
      margin-top: 4rem;
      letter-spacing: 0.1rem;

      @include sp {
        margin-top: 2rem;
      }
    }

    &_cap {
      @include pc {
        text-indent: -7em;
        padding-left: 7em;
      }
    }

    &_column {
      margin-top: 4rem;

      @include sp {
        margin-top: 2rem;
      }
    }

    &_img {
      margin-top: 4rem;

      @include sp {
        margin-top: 2rem;
      }
    }

    &_subttl {
      display: block;
      font-size: 1.8rem;
      font-weight: bold;

      @include sp {
        font-size: 1.6rem;
      }
    }
  }

  .pc.pc--i {
    @include pc {
      display: inline;
    }
  }

}