.l-header {
  color: $black;
  font-family: $notoSans;
  font-size: 1.6rem;
  line-height: normal;

  width: 100%;
  padding: 0 3.5rem;
  position: fixed;
  top: 6.65%;
  left: 0;
  z-index: 999;
  transition: left $anime, transform $animeSlow;
  @include sp {
    padding: 0 1rem;
    top: 1.4%;
  }

  &--wp {
    top: 0 !important;
    transition: left $anime, transform $animeSlow, top $anime;
    &.is-animated {
      top: 6rem !important;
      @include sp {
        top: 1rem !important;
      }
    }
  }

  &_top {
    padding-top: 7.8rem;
    @media screen and (min-width: 1321px) {
      padding-top: 9rem;
    }
    @include sp {
      padding-top: 7.1rem;
    }
  }

  a {
    color: $black;
    font-family: $notoSans;
    font-size: 1.6rem;
    text-decoration: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include pc {
      transition: opacity $anime;
    }
    &:hover {
      @include pc {
        opacity: 0.8;
      }
    }
  }

  &.is-fadeout {
    transform: translateY(-16rem);
    // top: -13.1rem;
    @include sp {
      transform: translateY(-9rem);
    }
  }

  &.is-fadein {
    transform: translateY(0) !important;
  }

  &.is-active {
    left: -25rem;
  }

  &_inner {
    width: 100%;
    padding: 2rem 4rem;
    background-color: rgba( $white , 0.7);
    border-radius: 4.5rem;
    position: relative;
    z-index: 2;
    // transition: background-color 0.3s;
    @media screen and (max-width: 1320px) {
      padding: 1.4rem 2rem;
    }
    @media screen and (max-width: 1050px) and (min-width: 768px) {
      justify-content: flex-end;
    }

    &.is-active {
      transition: background-color 0.3s;
      background-color: $white;
      box-shadow: 0.2rem 0.3rem 1.6rem rgba(0,0,0,0.16);
    }
  }

  &_logo {
    width: 17.2rem;
    display: block;
    @media screen and (max-width:1360px) and (min-width:1051px) {
      width: 12rem;
    }
    @media screen and (max-width: 1050px) and (min-width: 768px) {
      width: 12rem;
      height: 3.14rem;
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }

    @include sp {
      width: 10.2rem;
    }

    img {
      vertical-align: bottom;
    }
  }

  &Navi {
    &_cont {
      @media screen and (max-width: 1050px) {
        display: none;
      }
    }
    &_list {
      &--item {
        height: 5rem;
        line-height: 5rem;
        &:not(:first-child) {
          margin-left: 2.5rem;
          @media screen and (max-width:1360px) and (min-width:1051px) {
            margin-left: 1rem;
          }
        }
        > a {
          font-size: 1.6rem;
          @media screen and (max-width:1360px) and (min-width:1101px) {
            font-size: 1.4rem;
          }
          @media screen and (max-width:1100px) and (min-width:1051px) {
            font-size: 1.3rem;
          }

          &:hover {
            transition: color 0.4s;
            color: $red;
          }
        }

        &.js-dropdown {
          > a {
            padding-right: 1.5rem;
            position: relative;
            &:after {
              content: '';
              width: 7px;
              height: 7px;
              border: 0px;
              border-top: solid 2px #1C1C1C;
              border-right: solid 2px #1C1C1C;
              -ms-transform: rotate(135deg);
              -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
              position: absolute;
              top: 50%;
              right: 0;
              margin-top: -3px;
              transition: border-color 0.4s;
            }

            &:hover {
              &:after {
                border-color: $red;
              }
            }
          }
        }
      }
    }

    &_btn {
      margin-left: 3rem;
      @media screen and (max-width:1360px) and (min-width:1050px) {
        margin-left: 1.5rem;
      }
      @include sp {
        margin-left: 0;
      }
      &--box {
        width: 11.8rem;
        @media screen and (max-width:1360px) and (min-width:1101px) {
          width: 10rem;
        }
        @media screen and (max-width: 1100px) and (min-width: 768px) {
          width: 9rem;
        }
        @include sp {
          width: 7rem;
        }
        &:first-of-type {
          margin-right: 1.5rem;
          @include sp {
            margin-right: 1rem;
          }
        }

        a {
          font-size: 1.6rem;
          width: 100%;
          height: 5rem;
          background-color: $red;
          border: 0.1rem solid $red;
          border-radius: 1rem;
          display: block;
          line-height: 4.8rem;
          box-shadow: 0.2rem 0.3rem 1.1rem rgba(0,0,0,0.26);
          @media screen and (max-width:1360px) and (min-width:1050px) {
            font-size: 1.4rem;
          }
          @media screen and (max-width: 1050px) {
            height: 4.3rem;
            line-height: 1.25;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          @media screen and (max-width: 1100px) and (min-width: 768px) {
            font-size: 1.2rem;
          }
          @include pc {
            transition: color $anime, background-color $anime;
          }
          @include sp {
            font-size: 1rem;
          }

          &:hover {
            @include pc {
              color: $red !important;
              background-color: $white;
              opacity: 1;
            }
          }

          &.mysite {
            background-color: $blue;
            border-color: $blue;
            &:hover {
              @include pc {
                color: $blue !important;
                background-color: $white;
              }
            }
          }

          .tab01 {
            display: none;
            @media screen and (max-width: 1050px) {
              display: block;
            }
          }
        }
      }
    }

    &_global {
      width: 100%;
      position: absolute;
      top: 4.5rem;
      left: 0;
      z-index: -1;

      &--wrap {
        height: 0;
        overflow: hidden;
        transition: height 0.4s;

        &.is-view {
          position: relative;
          &:after {
            content: '';
            width: 100%;
            height: 1.6rem;
            background-color: $white;
            position: absolute;
            bottom: 100%;
            left: 0;
          }
        }
      }

      &--inner {
        padding: 9.5rem 4rem 2rem;
        border-radius: 0 0 4.5rem 4.5rem;
        position: relative;
        &:before {
          content: '';
          width: calc( 100% - 8rem);
          height: 0.2rem;
          margin: 0 auto;
          background-color: $red;
          position: absolute;
          top: 4.5rem;
          right: 0;
          left: 0;
        }
      }

      &--ttl {
        span {
          padding-right: 4.5rem;
          display: inline-block;
        }
        .en {
          font-size: 4rem;
          letter-spacing: 0.8rem;
          position: relative;
        }

        .ja {
          font-size: 1.4rem;
        }
      }

      &List {
        max-width: 104.7rem;
        width: 100%;
        margin: 3rem auto 0;
        &--item {
          width: 29.5%;
          margin: 0 5.75% 6rem 0;
          &:nth-child(3n) {
            margin-right: 0;
          }
          a {
            font-size: 1.6rem;
            line-height: 1.5 !important;
            padding: 1.4rem 2rem 1.4rem 0;
            border-bottom: 0.1rem solid #ddd;
            line-height: 1;
            display: block;
            position: relative;
            @include pc {
              transition: color $anime, border-color $anime;
            }
            @include tab07 {
              font-size: 1.4rem;
            }
            &:after {
              content: '';
              width: 8px;
              height: 8px;
              border: 0px;
              border-top: solid 2px $red;
              border-right: solid 2px $red;
              -ms-transform: rotate(45deg);
              -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
              position: absolute;
              top: 50%;
              right: 0;
              margin-top: -4px;
            }

            &:hover {
              @include pc {
                color: $red;
                border-color: $red;
              }
            }
          }
        }
      }
    }
  }

  &_hamburger {
    width: 3rem;
    height: 3rem;
    margin-left: 1.3rem;
    cursor: pointer;
    position: relative;
    @media screen and (min-width:1051px) {
      display: none;
    }

    span {
      width: 2.6rem;
      height: 2px;
      margin: 0 auto;
      background-color: $red;
      position: absolute;
      right: 0;
      left: 0;
      transition: transform 0.25s;
      &:first-child {
        top: 0.7rem;

      }
      &:nth-child(2) {
        top: 1.45rem;
        transition: opacity 0.25s;
      }
      &:last-child {
        bottom: 0.7rem;
      }
    }

    &.is-active {
      span {
        &:first-child {
          top: 50%;
          transform: rotate(45deg) translateY(-50%);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          bottom: 50%;
          transform: rotate(-45deg) translateY(50%);
        }
      }
    }
  }

  &Sp {
    width: 25rem;
    height: 100%;
    background-color: $white;
    position: fixed;
    top: 0;
    right: -25rem;
    transition: right 0.25s;
    z-index: 11;
    @media screen and (min-width:1050px) {
      display: none;
    }
    @include pc {
      width: 26rem;
      right: -26rem;
    }

    &.is-view {
      right: 0;
    }

    &_inner {
      width: 100%;
      height: 100%;
      padding: 0 1rem;
      transition: width 0.25s;
      overflow: hidden;

      &.is-hidden {
        width: 0;
      }
    }

    &_heading {
      width: 100%;
      padding-top: 4rem;
      white-space: nowrap;
      overflow: hidden;
    }

    &_list {
      margin-top: 1.5rem;
      &--item {
        border-bottom: 0.1rem solid $red;
        cursor: pointer;
        >a,span {
          font-size: 1.2rem;
          padding: 1.5rem 3rem 1.5rem 1rem;
          display: block;
          position: relative;
          white-space: nowrap;
          &:after {
            content: '';
            width: 8px;
            height: 8px;
            border: 0px;
            border-top: solid 2px $red;
            border-right: solid 2px $red;
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            position: absolute;
            top: 50%;
            right: 1rem;
            margin-top: -4px;
          }
        }
      }
    }

    &_sub {
      width: 0;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      transition: width 0.25s;
      overflow: hidden;
      white-space: nowrap;

      &.is-view {
        width: 100%;
      }

      &--inner {
        height: 100%;
        padding: 0 1rem;
      }

      &Heading {
        width: 100%;
        height: 12rem;

        &--en {
          font-size: 2.5rem;
          letter-spacing: 0.2rem;
          line-height: 0.9;
        }

        &--ja {
          font-size: 1.4rem;
          margin-top: 1rem;
        }
      }

      &Back {
        width: 100%;
        height: 5rem;
        line-height: 4rem;

        &--btn {
          font-size: 1.2rem;
        }
      }

      &--scroll {
        width: 100%;
        height: calc( 100% - 18.5rem);
        overflow: scroll;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
          @include sp {
            width: 10px;
          }
        }

        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          border-radius: 0.5rem;
        }
      }
    }
    
    &_btn {
      padding: 3rem 0;

      &--box {
        width: 100%;
        &:last-child {
          margin-top: 1rem;
        }
        
        a {
          font-size: 1.4rem;
          width: 100%;
          height: 4rem;
          background-color: $red;
          line-height: 4rem;
          border-radius: 1rem;
          display: block;

          &.mysite {
            background-color: $blue;
          }
        }
      }
    }

    &_overlay {
      width: calc( 100% - 25rem);
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      display: none;
      cursor: pointer;
      @include pc {
        width: calc( 100% - 26rem);
      }

      &.is-view {
        display: block;
      }
    }

    &_scroll {
      width: 100%;
      height: calc( 100% - 9.65rem);
      overflow: scroll;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        @include sp {
          width: 10px;
        }
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 0.5rem;
      }
    }
  }
}