.p-sdgs {
  &_inner {
    &:before {
      background-image: url('../img/common/sub_bg03.png');
    }
  }

  &Intro {
    position: relative;

    &:before,
    &:after {
      content: '';
      width: calc( 100% - 10rem);
      height: 0.775rem;
      margin: 0 auto;
      background: url('../img/company/sdgs/intro_bg01.png') no-repeat center;
      background-size: auto 0.775rem;
      position: absolute;
      right: 0;
      left: 0;
      @include sp {
        width: calc( 100% - 4rem);
        height: 0.38rem;
        background-size: auto 0.38rem;
      }
    }
    &:before {
      top: 4rem;
      @include sp {
        top: 2rem;
      }
    }
    &:after {
      bottom: 4rem;
      @include sp {
        bottom: 2rem;
      }
    }

    &_ttl {
      span {
        @include sp {
          font-size: 2.2rem;
        }
      }
    }

    &_text {
      @include sp {
        margin-top: 2rem;
      }
    }
  }

  &About {
    &_logo {
      width: 81.25%;
      margin: 4rem auto 0;
      @include sp {
        width: 100%;
        margin-top: 3rem;
      }
    }

    &_link {
      text-decoration: underline;
      word-break: break-all;
      &:after {
        content: '';
        width: 1.4rem;
        height: 1.4rem;
        background: url('../img/common/ico_link01.svg') no-repeat;
        background-size: contain;
        display: inline-block;
        position: relative;
        right: -1rem;
      }
    }
  }
}