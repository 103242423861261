.c-box {
  &_white {
    padding: 8rem 5% 8rem;
    background-color: $white;
    box-shadow: $shadow_box;
    overflow: hidden;
    @include sp {
      padding: 4rem 2rem 4rem;
      box-shadow: $shadow_boxSp;
    }

    &:not(:first-child) {
      margin-top: 9rem;
      @include tab {
        margin-top: 6rem;
      }
      @include sp {
        margin-top: 4rem;
      }
    }
  }

  &_shadow {
    box-shadow: $shadow_box;
    @include sp {
      box-shadow: $shadow_boxSp;
    }
  }

  &Num {
    padding: 4rem;
    background-color: $white;
    box-shadow: $shadow_box;
    position: relative;
    @include tab {
      padding: 3rem;
    }
    @include sp {
      padding: 2rem;
      box-shadow: $shadow_boxSp;
    }
    &:not(:first-child) {
      margin-top: 4rem;
      @include sp {
        margin-top: 2rem;
      }
    }
    &:before {
      content: '';
      width: 6.5rem;
      height: 6.5rem;
      background: url('../img/common/box_num_bg01.svg') no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 0;
      @include sp {
        width: 4rem;
        height: 4rem;
      }
    }

    &_num {
      font-size: 7.8rem;
      position: absolute;
      top: 2.5rem;
      left: 3rem;
      @include tab {
        font-size: 6rem;
      }
      @include sp {
        font-size: 3.4rem;
        top: 2rem;
        left: 2rem;
      }
    }

    &_ttl {
      padding-left: 6rem;
      @include tab {
        padding-left: 5rem;
      }
      @include sp {
        padding-left: 3rem;
      }
      span {
        font-size: 2.5rem !important;
        padding: 0 1rem 1.5rem !important;
        @include tab {
          font-size: 2.2rem !important;
        }
        @include sp {
          font-size: 1.6rem !important;
          font-feature-settings: 'palt';
          padding: 0 0.5rem 1rem !important;
        }
      }
    }

    &_text {
      padding-left: 6rem;
      margin-top: 2rem;
      @include tab {
        padding-left: 5rem;
      }
      @include sp {
        margin-top: 1.5rem;
        padding: 0;
      }
    }

    &_curriculum {
      margin-top: 2.5rem;
      @include sp {
        margin-top: 1.5rem;
        padding: 0;
      }

      &--box {
        &:not(:first-child) {
          margin-top: 3.5rem;
          @include sp {
            margin-top: 2.5rem;
          }
        }
      }
      &Heading {
        @include tab02 {
          display: block;
        }
        &--ico {
          font-size: 1.8rem;
          height: 3.5rem;
          padding: 0 1rem;
          margin-right: 3.5rem;
          line-height: 3.5rem;
          letter-spacing: 0.2rem;
          display: inline-block;
          position: relative;
          @include tab {
            font-size: 1.6rem;
          }
          @include sp {
            font-size: 1.2rem;
            height: 2.5rem;
            line-height: 2.5rem;
          }
          &:after {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 17.5px 0 17.5px 18px;
            border-color: transparent transparent transparent $red;
            position: absolute;
            top: 0;
            left: 100%;
            @include sp {
              border-width: 12.5px 0 12.5px 9px;
            }
          }
        }

        &--ttl {
          font-size: 2rem;
          letter-spacing: 0.1rem;
          margin-top: 0.2rem;
          @include tab {
            font-size: 1.8rem;
            margin-top: 0.5rem;
          }
          @include sp {
            font-size: 1.4rem;
            margin-top: 0.5rem;
          }
        }

        &.teacher {
          @include tab {
            display: flex;
          }

          .c-boxNum_curriculumHeading--ico {
            font-size: 1.7rem;
            @include tab {
              font-size: 1.6rem;
            }
            @include sp {
              font-size: 1.2rem;
            }
          }
        }
      }

      &--text {
        margin-top: 1rem;
        @include sp {
          font-size: 1.3rem;
        }
      }
    }
  }

  &Society {
    padding: 3rem 4rem 4rem;
    @include sp {
      padding: 2rem 2.5rem 2.5rem;
    }

    &_date {
      font-size: 2rem;
      letter-spacing: 0.2rem;
      line-height: 1.6;
      @include tab {
        font-size: 1.8rem;
      }
      @include sp {
        font-size: 1.4rem;
        letter-spacing: 0.1rem;
      }
    }

    &_box {
      &:not(:first-of-type) {
        margin-top: 2rem;
        @include sp {
          margin-top: 1rem;
        }
      }
    }

    &_ttl {
      margin-top: 1rem;
      @include sp {
        font-size: 1.3rem;
      }
    }

    &_text {
      @include sp {
        font-size: 1.3rem;
      }
    }
  }

  &Spec {
    padding: 4rem 6%;
    @include tab {
      font-size: 1.4rem;
    }
    @include sp {
      font-size: 1.2rem;
      padding: 2.5rem 2rem;
      display: block;
    }

    &_wrap {
      width: 47%;
      @include tab {
        width: 48%;
      }
      @include sp {
        width: 100%;
      }
      &:not(:first-child) {
        @include sp {
          margin-top: 1rem;
        }
      }
    }
    
    &_box {
      &:not(:first-child) {
        margin-top: 3rem;
        @include sp {
          margin-top: 1rem;
        }
      }
    }

    &_list {
      text-indent: -2em;
      padding-left: 2em;
      @include sp {
        text-indent: -1em;
        padding-left: 1em;
      }
    }
  }

  &Attention {
    padding: 4rem 6%;
	border: 2px solid $darkgray;
    @include sp {
      font-size: 1.2rem;
    }

    &_title {
		span {
			font-size: 2.5rem;
			@include sp {
				font-size: 1.5rem;
			}
		}
    }

    &_stitle {
		font-size: 2.2rem;
		@include sp {
			font-size: 1.5rem;
		}
    }

    &_box {
      padding: 3rem 4%;
      border: 1px solid $darkgray;
    }

    &_list {
      text-indent: -1em;
      padding-left: 1em;
    }
  }


  &Option {
    &_inner {
      padding: 4rem 0 3.5rem;
      @include sp {
        padding: 2rem 0;
      }
    }

    &_ttl {
      font-size: 2.4rem;
      position: relative;
      @include tab02 {
        font-size: 1.8rem;
      }

      span {
        font-size: 1.6rem;
        width: 100%;
        text-align: center;
        position: absolute;
        top: -2.5rem;
        display: block;
        @include tab02 {
          font-size: 1.2rem;
        }
        @include sp {
          position: static;
        }
      }
    }

    &_pic {
      margin-top: 1.5rem;
      padding: 0 4rem;
      @include tab {
        padding: 0 2rem;
      }
      @include sp {
        padding: 0 3rem;
      }
    }

    &_patent {
      margin-top: 1.5rem;
      @include sp {
        margin-top: 1rem;
      }
      &--inner {
        padding: 1rem 1.5rem;
        border: 0.1rem solid $red;
        display: inline-block;
        @include sp {
          padding: 0.5rem 1rem;
        }
      }

      &--text {
        font-size: 1.4rem;
        line-height: 1.7;
        @include tab {
          font-size: 1.3rem;
        }
        @include sp {
          font-size: 1.2rem;
        }
      }
    }

    &_price {
      font-size: 1.6rem;
      letter-spacing: 0.2rem;
      margin-top: 1.5rem;
      line-height: 1.8;
      @include tab02 {
        font-size: 1.4rem;
        margin-top: 1rem;
      }
      @include sp {
        letter-spacing: 0rem;
      }

      span {
        font-size: 2rem;
        @include tab02 {
          font-size: 1.8rem;
        }
      }
    }

    &_capacity {
      font-size: 1.4rem;
      margin-top: 1.5rem;
      line-height: 1.85;
      @include tab {
        font-size: 1.2rem;
      }
      @include sp {
        font-size: 1rem;
      }
    }

    &_text {
      margin-top: 1.5rem;
      padding: 0 4rem;
      @include tab {
        font-size: 1.4rem;
        padding: 0 2rem;
      }
      @include sp {
        font-size: 1.2rem;
        padding: 0 3rem;
        margin-top: 1rem;
      }

      span {
        font-size: 2rem;
        font-weight: bold;
        @include tab {
          font-size: 1.8rem;
        }
        @include sp {
          font-size: 1.6rem;
        }
      }
    }
  }

  &OptionBag {
    padding: 4rem 3.5rem 6rem;
    @include tab {
      padding: 3rem 2.5rem 4rem;
    }
    @include sp {
      padding: 3rem 2rem;
    }

    &_ttl {
      font-size: 2.4rem;
      letter-spacing: 0.1rem;
      @include tab {
        font-size: 2rem;
      }
      @include sp {
        font-size: 1.8rem;
      }
    }

    &_wrap {
      margin-top: 1.5rem;
      @include sp {
        display: block;
      }
    }

    &_left {
      width: 50%;
      @include tab {
        width: 52%;
      }
      @include sp {
        width: 100%;
      }
    }

    &_right {
      width: 45%;
      @include tab {
        font-size: 1.4rem;
      }
      @include sp {
        font-size: 1.4rem;
        width: 100%;
        margin-top: 2.5rem;
      }
    }

    &_list {
      margin-top: 2.5rem;
      @include sp {
        margin-top: 1.5rem;
        flex-wrap: wrap;
      }

      &--item {
        width: 30%;
        @include tab {
          width: 32%;
        }
        @include sp {
          width: 48%;
        }

        &:not(:nth-child(-n+2)) {
          @include sp {
            margin-top: 1rem;
          }
        }
      }

      &--text {
        font-size: 1.4rem;
        line-height: 1.85;
        margin-top: 0.5rem;
        @include tab {
          font-size: 1.1rem;
          font-feature-settings: 'palt';
        }
        @include sp {
          font-size: 1rem;
          font-feature-settings: 'palt';
        }
      }
    }

    &_limited {
		font-weight: bold;
		color: $red;
    }

    &_price {
      margin-top: 1.5rem;
      @include sp {
        margin-top: 1rem;
      }
      span {
        font-size: 2rem;
        @include sp {
          font-size: 1.8rem;
        }
      }
    }

    &_text {
      margin-top: 1.5rem;
      @include sp {
        margin-top: 1rem;
      }

      a {
        color: $lightRed;
        text-decoration: underline;
        @include tab {
          font-size: 1.4rem;
        }
      }
    }

    &_spec {
      margin-top: 1.5rem;
      @include sp {
        margin-top: 1rem;
      }
    }
  }

  &_default {
    padding: 4rem;
    @include tab {
      padding: 4rem 3rem;
    }
    @include sp {
      padding: 3rem 2rem;
    }
  }

  &Faq {
    padding: 0 3rem;
    @include sp {
      padding: 0 2rem;
    }

    &:not(:first-child) {
      margin-top: 4rem;
      @include sp {
        margin-top: 3rem;
      }
    }

    &_question {
      padding: 4.5rem 0;
      position: relative;
      @include sp {
        padding: 2rem 0;
      }

      &--ttl {
        font-size: 2rem;
        padding-left: 8rem;
        @include tab {
          font-size: 1.8rem;
          padding-left: 6.5rem;
        }
        @include sp {
          font-size: 1.6rem;
          font-feature-settings: 'palt';
          padding-left: 0;
        }
      }
    }

    &_ico {
      font-size: 7rem;
      position: absolute;
      top: 3rem;
      left: 0rem;
      display: block;
      line-height: 1;
      @include tab {
        font-size: 4rem;
        top: 4rem;
      }
      @include sp {
        font-size: 3.6rem;
        position: static;
      }
    }

    &_answer {
      padding: 4rem 0;
      background-image: linear-gradient(to right, $red, $red 12px, transparent 12px, transparent 24px);
      background-size: 24px 2px;
      background-repeat: repeat-x;
      background-position: left top;
      position: relative;
      @include sp {
        padding: 2rem 0;
        background-image: linear-gradient(to right, $red, $red 6px, transparent 6px, transparent 12px);
        background-size: 12px 1px;
      }

      &--text {
        padding-left: 8rem;
        @include tab {
          font-size: 1.4rem;
          padding-left: 6.5rem;
        }
        @include sp {
          font-size: 1.4rem;
          padding-left: 0;
        }
        a {
          color: $red;
          font-weight: bold;
          text-decoration: underline;
          @include tab02 {
            font-size: 1.4rem;
          }
        }
      }
    }

    &_accordion {
      padding: 0;
      @include sp {
        padding: 0;
      }
      &:not(:first-child) {
        margin-top: 4rem;
        @include sp {
          margin-top: 2rem;
        }
      }
      .c-boxFaq {
        &_ico {
          font-size: 4rem;
          top: 4rem;
          left: 4rem;
          @include sp {
            font-size: 3rem;
          }
        }

        &_question {
          padding: 4rem 9.5rem 4rem 4rem;
          position: relative;
          cursor: pointer;
          @include tab {
            padding: 4rem 6rem 4rem 3rem;
          }
          @include sp {
            padding: 2rem 4rem 2rem 2rem;
          }
          &:after {
            content: '';
            width: 1.7rem;
            height: 1rem;
            margin: auto 0;
            background: url('../img/common/arrow_index01.svg') no-repeat;
            background-size: contain;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 4rem;
            transition: transform $anime;
            @include tab {
              right: 2rem;
            }
            @include sp {
              width: 1.2rem;
              height: 0.7rem;
              right: 1.5rem;
            }
          }
          &--ttl {
            padding-left: 6rem;
            @include sp {
              padding-left: 0;
            }
          }

          &.is-active {
            &:after {
              transform: rotate(180deg);
            }
          }
        }

        &_answer {
          &--text {
            padding-left: 6rem;
            @include sp {
              padding-left: 0;
            }
          }

          .c-text_indent {
            padding-left: calc( 6rem + 1em);
            @include sp {
              padding-left: 1em;
            }
          }

          .c-boxFaq_ico {
            left: 0;
          }
        }
      }

      .js-accordion-triggerPanel {
        padding: 0 9.5rem 0 4rem;
        @include tab {
          padding: 0 6rem 0 3rem;
        }
        @include sp {
          padding: 0 4rem 0 2rem;
        }
      }
    }
  }

  &Scene {
    &_read {
      line-height: 1.5;
      vertical-align: middle;
      @include sp {
        letter-spacing: 0.1rem;
      }

      .cap {
        font-size: 2rem;
        @include sp {
          font-size: 1.4rem;
        }
      }
    }

    &_caption {
      @include sp {
        font-size: 1.2rem;
      }
    }
  }

  &Braces {
    text-align: center;
    &_inner {
      min-width: 64.5rem;
      padding: 0.5rem 2.5rem;
      border: 0.4rem solid $green;
      display: inline-block;
      position: relative;
      @include tab02 {
        border-width: 0.2rem;
      }
      @include tab {
        min-width: 40rem;
      }
      @include sp {
        min-width: 0;
        width: 100%;
        padding: 0.5rem 2.5rem;
      }
      &:before,
      &:after {
        content: '';
        width: calc( 100% - 3rem);
        height: 0.6rem;
        margin: 0 auto;
        background-color: $lightgray;
        position: absolute;
        right: 0;
        left: 0;
        @include sp {
          width: calc( 100% - 2rem);
          height: 0.4rem;
        }
      }
      &:before {
        top: -0.5rem;
        @include sp {
          top: -0.3rem;
        }
      }
      &:after {
        bottom: -0.5rem;
        @include sp {
          bottom: -0.3rem;
        }
      }
    }
    &_text {
      line-height: 1.52;
      @include sp {
        font-size: 1.6rem;
      }
    }

    &--greenBlue {
      .c-boxBraces_inner {
        border-color: $greenBlue;
      }
    }

    &--rose {
      .c-boxBraces_inner {
        border-color: $rose;
      }
    }

    &--orange {
      .c-boxBraces_inner {
        border-color: $orange;
      }
    }

    &--bronze {
      .c-boxBraces_inner {
        border-color: $bronze;
      }
    }
  }

  &Exercise {
    &_inner {
      padding: 4rem 7.3%;
      @include sp {
        padding: 3rem 2rem;
      }
    }
  }

  &_link {
    padding: 4rem 2rem;
    @include sp {
      padding: 3rem 2rem;
    }

    &--text {
      height: 6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      @include sp {
        height: auto;
      }
    }
  }

  &Column {
    padding: 6rem 6%;
    @include sp {
      padding: 3rem 2rem;
    }

    &_heading {
      &--ttl {
        @include sp {
          font-size: 1.8rem;
        }
      }
    }

    &_profile {
      @include sp {
        display: block;
      }
      &--pic {
        width: 36%;
        @include sp {
          width: 100%;
        }
      }

      &--box {
        width: 57%;
        @include sp {
          width: 100%;
          margin-top: 2rem;
        }
      }
    }

    &_btn {
      width: 40rem;
      margin: 6rem auto 0;
      @include sp {
        width: 100%;
        margin-top: 4rem;
      }

      .c-btn_cmn {
        @include sp {
          padding-right: 5%;
        }
        &:after {
          @include sp {
            right: 8%;
          }
        }
      }
    }
  }

  &Case {
    &_wrap {
      @include tab02 {
        display: block;
      }
      &--left {
        width: 45.6%;
        position: relative;
        @include tab {
          width: 80%;
          margin: 0 auto;
        }
        @include tab02 {
          text-align: right;
        }
        @include sp {
          width: 100%;
        }
      }

      &--right {
        width: 53.2%;
        @include tab02 {
          width: 100%;
        }
        @include sp {
          margin-top: -1rem;
        }
      }
    }

    &_pic {
      padding-right: 2rem;
      @include sp {
        padding-right: 1rem;
      }
    }

    &_heading {
      padding: 1rem 2rem;
      background-color: rgba($white, 0.8);
      box-shadow: $shadow03;
      display: inline-block;
      position: absolute;
      right: 0;
      top: 76%;
      @include tab02 {
        position: static;
        transform: translateY(-3rem);
      }
      @include sp {
        padding: 1rem;
      }

      &--name {
        @include tab06 {
          font-size: 1.8rem;
        }
        @include sp {
          font-size: 1.6rem;
        }

        span {
          @include tab06 {
            font-size: 1.6rem;
          }
        }
      }

      &--group {
        @include tab06 {
          font-size: 1.6rem;
        }
        @include sp {
          font-size: 1.4rem;
        }
      }
    }

    &_text {
      padding: 0 9%;
      @include tab02 {
        padding: 0;
      }
    }
  }

  &PetsVoice {
    &_wrap {
      @include tab02 {
        display: block;
      }
      &--left {
        width: 43%;
        @include tab02 {
          width: 100%;
        }
      }

      &--right {
        width: 52%;
        @include tab02 {
          width: 100%;
          margin-top: 2rem;
        }
      }
    }

    &_pic {
      @include tab {
        width: 75%;
        margin: 0 auto;
      }
    }
  }

  &Medicell {
    &_top {
      width: 100%;
      height: 10.2rem;
      padding-left: 27%;
      position: relative;
      cursor: pointer;
      @include sp {
        height: 7.5rem;
        padding-left: 7.5rem;
      }
      &:after {
        content: '';
        width: 1.3rem;
        height: 0.8rem;
        margin: auto 0;
        background: url('../img/common/arrow_index01.svg') no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 7%;
        transition: transform $anime;
      }

      &--ico {
        width: 17.3%;
        display: block;
        position: absolute;
        top: 50%;
        left: 7.7%;
        transform: translateY(-50%);
        @include sp {
          width: 4rem;
        }
      }

      &--ttl {
        line-height: 1.4;
        @include tab {
          font-size: 1.6rem;
        }
      }

      &.is-active {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }

    &_bottom {
      padding: 0 10% 3rem;
      min-height: 18rem;
      @include sp {
        min-height: 0;
      }
    }
  }

  &Facility {
    border-top: 0.2rem solid $gray;
    @include sp {
      padding: 2rem;
    }

    &_wrap {
      @include sp {
        display: block;
      }
      &--left {
        width: 35%;
        padding-right: 4rem;
        @include sp {
          width: 100%;
        }
      }

      &--right {
        width: 65%;
        @include sp {
          width: 100%;
        }
        &Inner {
          width: 100%;
          @include sp {
            display: block;
            margin-top: 1rem;
          }
        }
      }
    }

    &_category {
      &--item {
        font-size: 1.6rem;
        padding: 0 1rem;
        margin: 0.5rem 0;
        height: 2.4rem;
        line-height: 2.4rem;
        display: inline-block;
        @include sp {
          font-size: 1.2rem;
          height: 2rem;
          line-height: 2rem;
        }
        &:not(:last-child) {
          margin-right: 1.5rem;
          @include sp {
            margin-right: 0.5rem;
          }
        }

        &.treatment {
          background-color: $lightGreen;
        }
        &.beauty {
          background-color: $tomatoRed;
        }
        &.pets {
          background-color: $orangeYellow;
        }
        &.sport {
          background-color: $seaBlue;
        }
      }
    }

    &_detail {
      width: 74.2%;
      padding-right: 2rem;
      @include sp {
        width: 100%;
        padding: 0;
      }
      &--tel {
        @include sp {
          margin-top: 0 !important;
        }
      }

      &--link {
        a {
          word-break: break-all;
        }
      }
    }

    &_medicell {
      width: 25.8%;
      @include sp {
        width: 100%;
        margin-top: 1rem;
      }
      &--item {
        width: 25%;
        @include sp {
          width: 3rem;
        }
        &:not(:nth-child(-n+3)) {
          margin-top: 1rem;
          @include sp {
            margin-top: 0;
          }
        }
        &:not(:nth-child(3n)) {
          margin-right: 12.5%;
        }
        &:not(:last-child) {
          @include sp {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  &Product {
    &_ttl {
      @include sp {
        font-size: 1.8rem;
      }
    }
    &_wrap {
      @include sp {
        display: block;
      }
      &--left {
        width: 50%;
        @include sp {
          width: 100%;
        }
      }

      &--right {
        width: 45%;
        @include sp {
          width: 100%;
        }
      }
    }

    &_pic {
      &List {
        @include sp {
          flex-wrap: wrap;
          justify-content: center;
        }
        &--item {
          width: 30.4%;
          @include sp {
            width: 48%;
          }
          &:nth-child(2n) {
            @include sp {
              margin-left: 4%;
            }
          }

          &:not(:nth-child(-n+2)) {
            @include sp {
              margin-top: 1rem;
            }
          }
        }

        &--column3 {
          .c-boxProduct_picList--item {
            width: 23.5%;
            @include sp {
              width: 48%;
            }
          }
        }

        &--column2 {
          .c-boxProduct_picList--item {
            &:nth-child(2n) {
              margin-left: 4%;
            }
          }
        }
      }
    }

    &_detail {
      @include sp {
        margin-top: 2rem !important;
      }
    }

    &_bnr {
      width: 65.9%;
      margin: 4rem auto 0;
      @include sp {
        width: 100%;
        margin-top: 3rem;
      }
    }
  }

  &Sns {
    padding: 6rem 2rem;
    @include sp {
      padding: 3rem 2rem;
    }

    &_heading {
      text-align: center;
      &--inner {
        padding-left: 9.5rem;
        display: inline-block;
        position: relative;
        @include sp {
          padding-left: 5rem;
        }

        &:before {
          content: '';
          width: 8.5rem;
          height: 8.5rem;
          background: url('../img/pets/ico_instagram01.png') no-repeat;
          background-size: contain;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          @include sp {
            width: 4rem;
            height: 4rem;
          }
        }
      }

      .c-ttl_line {
        span {
          @include sp {
            font-size: 1.6rem;
          }
        }
      }
    }

    &_code {
      max-width: 20rem;
      width: 100%;
      margin: 4rem auto 0;
      @include sp {
        max-width: none;
        width: 15rem;
      }
    }

    &_caption {
      @include sp {
        text-indent: -1em;
        text-align: left;
        padding-left: 1em;
      }
    }
  }

  &_work {
    border-radius: 1rem;
    overflow: hidden;

    &--inner {
      padding: 4rem 7.8%;
      @include sp {
        padding: 3rem 2rem;
      }
    }

    .c-text_size--24 {
      @include sp {
        font-size: 1.8rem;
      }
    }
  }

  &Sports {
    &_pic {
      position: relative;
    }

    .c-text_size--20 {
      @include tab04 {
        font-size: 1.6rem;
      }
      @include sp {
        font-size: 1.2rem;
      }
    }

    .c-text_size--25 {
      @include tab04 {
        font-size: 2.2rem;
      }
      @include sp {
        font-size: 1.6rem;
      }
    }

    &_name {
      padding: 1rem;
      background-color: rgba( $white, 0.5);
      line-height: 1.55;
      position: absolute;
      right: -2rem;
      bottom: -4rem;
      @include sp {
        right: -1rem;
        bottom: -2rem;
      }
    }
  }

  &Sdgs {
    padding: 7rem 6%;
    @include sp {
      padding: 3rem 2rem;
    }

    &:not(:first-child) {
      margin-top: 4rem;
      @include sp {
        margin-top: 3rem;
      }
    }

    &_ttl {
      span {
        @include sp {
          font-size: 1.8rem !important;
          letter-spacing: .1rem !important;
        }
      }
    }

    &_list {
      @include sp {
        display: block;
      }
      &--item {
        max-width: 38rem;
        width: 43%;
        @include sp {
          width: 100%;
        }
      }

      li {
        & + li {
          margin-left: 4.3%;
          @include sp {
            margin: 2rem 0 0;
          }
        }
      }
    }

    &_accordion {
      margin-top: 4rem;
      @include sp {
        margin-top: 9rem;
      }
      &--panel {
        height: 6.5rem;
        transition: height $anime;
        overflow: hidden;
        @include sp {
          height: 5rem;
        }

        &.is-close {
          
        }
        &.is-view {
          height: auto;
        }
        &.is-view02 {
          overflow: visible;
        }
      }

      &--btn {
        max-width: 40rem;
        width: 45.45%;
        margin: 6rem auto 0;
        @include sp {
          width: 100%;
          margin-top: 4rem;
        }
      }
    }

    &.box04 {
      .c-boxSdgs_accordion--panel {
        height: auto;
        @include sp {
          height: 5rem;
        }
      }
    }

    &.box05 {
      .c-ttl_line {
        span {
          padding: 0 2rem 2rem;
        }
      }
    }
  }

  &Seminar {
    padding: 4rem 6%;

    &_btn {
      max-width: 40rem;
      width: 100%;
      margin: 6rem auto 0;
      @include sp {
        margin-top: 4rem;
      }
    }

    &_cont {
      &--ttl {
        font-size: 2.5rem;
        line-height: 1.48;
        @include tab {
          font-size: 2.2rem;
        }
        @include sp {
          font-size: 1.6rem;
        }
      }
    }

    &_date {
      &--ttl {
        font-size: 3rem;
        @include tab {
          font-size: 2.6rem;
        }
        @include sp {
          font-size: 2rem;
        }
      }

      &List {
        @include tab02 {
          display: block;
        }
        &--item {
          max-width: 42rem;
          width: 47.7%;
          @include tab02 {
            width: 100%;
            margin: 0 auto;
          }

          &:not(:nth-child(-n+2)) {
            margin-top: 4rem;
          }

          &:not(:first-child) {
            @include tab02 {
              margin-top: 3rem;
            }
          }

          &--100 {
            max-width: none;
            width: 100%;
          }
        }
      }
    }

    &_detail {
      position: relative;
      padding: 4rem 7.15%;
      @include sp {
        padding: 3rem 2rem;
      }

      &--ticker {
        position: absolute;
        top: 0;
        left: 50%;
		background-color: $red;
		min-width: 6em; 
		padding: .5em 1em;
        font-size: 1.8rem;
		color: #fff;
		line-height: 1;
		text-align: center;
		transform: translate(-50%, -50%);
        @include sp {
          font-size: 1.4rem;
        }
        &.full {
			background-color: $red;
		}
        &.afew {
			background-color: $orange;
		}
        &.vacant {
			background-color: $seaBlue;
		}
      }
      &--ttl {
        font-size: 2.4rem;
        text-shadow: 0.3rem 0.3rem 0.6rem rgba( $black, 0.2);
        @include tab {
          font-size: 2rem;
        }
        @include sp {
          font-size: 1.6rem;
          text-shadow: 0.15rem 0.15rem 0.3rem rgba( $black, 0.2);
        }
      }

      &--wrap {
        @include sp {
          display: block;
        }
        &--item {
          max-width: 35.5rem;
          width: 47.333%;
          @include sp {
            max-width: none;
            width: 100%;
          }
        }

        ul {
          & + ul {
            @include sp {
              margin-top: 1rem;
            }
          }
        }
      }
    }

    &_season {
      &--ttl {
        font-size: 2.4rem;
        line-height: 1.5;
        @include tab {
          font-size: 2rem;
        }
        @include sp {
          font-size: 1.6rem;
        }

        span {
          font-size: 3.2rem;
          @include tab {
            font-size: 2.6rem;
          }
          @include sp {
            font-size: 2rem;
          }
        }
      }

      &--pic {
        max-width: 69.5rem;
        width: 79%;
        margin: 4rem auto 0;
        @include sp {
          width: 100%;
          margin-top: 3rem;
        }
      }
    }

    &.kinmakubiryo {
      .c-ttlSeminar_ttl--text {
        @include sp {
          font-feature-settings: 'palt';
        }
      }
    }
  }

  &_category {
    font-size: 1.2rem;
    width: 12rem;
    height: 2.5rem;
    line-height: 2.5rem;
    margin-right: 2rem;
    display: block;

    &--release {
      background-color: $lightblue;
    }

    &--news {
      background-color: $lightRed;
    }
  }
}