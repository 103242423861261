.p-news {
  &_list {
    &--item {
      border-color: $gray;

      .c-listNews_item--date {
        width: 14rem;
        @include sp {
          width: auto;
        }
      }

      .c-listNews_item--ttl {
        padding: 0 2.5rem;
        @include tab02 {
          padding: 0;
        }
      }
    }
  }

  &Seminar {
    &_img {
      max-width: 60rem;
      width: 60%;
      margin: 4rem auto 0;
      @include sp {
        width: 100%;
        margin-top: 3rem;
      }
    }

    &_banner {
      max-width: 75rem;
      width: 75%;
      margin: 4rem auto 0;
      @include sp {
        width: 100%;
        margin-top: 3rem;
      }

	  p {
	      margin-bottom: 2rem;
	      font-size: 2.2rem;
	  }
    }

    &_movie {
      &--text {
        line-height: 1.7;
      }

      &--pic {
        max-width: 50.7rem;
        width: 100%;
        margin: 1.5rem auto 0;
        border-radius: 2rem;
        overflow: hidden;
        @include sp {
          border-radius: 1rem;
        }
      }
    }

	.c-boxSeminar {
		&.members-only {
			position: relative;
			&::before {
				content: '協会員限定';
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				background-color: $red;
				padding: .5em 1em;
				font-size: 2.4rem;
				color: #fff;
				transform: translate(2rem, -50%);
				@include sp {
					font-size: 1.4rem;
					transform: translate(1rem, -50%);
				}
			}
		}
	}

		&Link {
			&_list {
				@include sp {
					justify-content: space-between;
				}
				&--item {
					width: 47.5%;
					@include sp {
						width: 100%;
					}

					&:not(:nth-child(even)) {
						margin-right: 5%;
						@include sp {
							margin-right: 0;
						}
					}
					&:not(:nth-child(-n+2)) {
						margin-top: 3rem;
					}
					&:not(:first-child) {
						@include sp {
							margin-top: 2rem;
						}
					}
				}
			}
		}

  }

  &Detail {
    padding: 8rem 0;
    @include sp {
      padding: 4rem 2rem;
    }

    &_wrap {
      @include sp {
        display: block;
      }
      &--left {
        width: calc( 100% - 20rem );
        @include tab {
          width: calc( 100% - 15rem );
        }
        @include sp {
          width: 100%;
        }
      }

      &--right {
        width: 20rem;
        @include tab {
          width: 15rem;
        }
        @include sp {
          width: 100%;
          margin-top: 4rem;
        }
      }
    }
  }

  &Archive {
    &_ttl {
      font-size: 2rem;
      width: 100%;
      height: 5.5rem;
      line-height: 5.5rem;
      position: relative;
      @include tab02 {
        font-size: 1.6rem;
        height: 4rem;
        line-height: 4rem;
      }

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 55px 16px 0 0;
        border-color: $white transparent transparent transparent;
        position: absolute;
        top: 0;
        left: 0;
        @include tab02 {
          border-width: 40px 10px 0 0;
        }
      }
    }

    &_list {
      &--item {
        border-bottom: 0.1rem solid $gray;

        a {
          display: block;
          @include tab {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  &Post {
    padding: 0 8.333%;
    @include tab {
      padding: 0 4rem;
    }
    @include sp {
      padding: 0;
    }

    &_ttl {
      font-size: 2.5rem;
      padding-bottom: 2.5rem;
      position: relative;
      @include tab {
        font-size: 2.2rem;
      }
      @include sp {
        font-size: 1.8rem;
      }

      &:after {
        content: '';
        width: 100%;
        height: 0.8rem;
        background-color: $red;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: skew(-15deg,0deg);
        -webkit-transform: skew(-15deg,0deg);
      }
    }

    &_cont {
      h2 {
        font-size: 2.5rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
        padding: 0.7rem 0 0.7rem 2.5rem;
        border-left: 0.5rem solid $red;
        margin: 6rem 0 3rem;
        @include tab {
          font-size: 2.2rem;
        }
        @include sp {
          font-size: 1.8rem;
          padding: 0.5rem 0 0.5rem 1rem;
          margin: 4rem 0 2rem;
          border-left: 0.3rem solid $red;
        }
      }

      h3 {
        font-size: 2.5rem;
        font-weight: bold;
        letter-spacing: 0.1rem;
        padding: 0.7rem 0 0.7rem 2.5rem;
        border-left: 0.5rem solid $gray;
        margin: 4rem 0 2rem;
        @include tab {
          font-size: 2.2rem;
        }
        @include sp {
          font-size: 1.8rem;
          padding: 0.5rem 0 0.5rem 1rem;
          border-left: 0.3rem solid $gray;
        }
      }

      p ,
      li ,
      figcaption {
        font-size: 1.6rem;
        font-weight: 500;
        letter-spacing: 0.1rem;
        @include sp {
          font-size: 1.4rem;
        }

		strong {
			font-weight: 700;
		}
      }

      img {
        margin: 6rem 0;
        @include sp {
          margin: 3rem 0;
        }
      }

      ul {

		&.col-2 {
			display: flex;
			@include sp {
				flex-direction: column;
			}

			li {
				display: flex;
				align-items: center;
				margin-right: 2rem;
				@include sp {
					margin-right: 0;
					margin-bottom: 2rem;
				}

				p {
					img {
						margin: 0;
					}
				}
			}
		}
		&.row-2 {
			display: flex;
			@include sp {
				flex-direction: column;
			}

			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-bottom: 2rem;
				text-align: center;
				p {
					margin-bottom: 1rem;
					img {
						margin: 0;
					}
				}
			}
		}
      }

		dl.row2 {
			dt {
				float: left;
				margin-bottom: 1rem;
				@include sp {
					float: none;
					width: 100%;
					margin-bottom: .5rem;
				}
			}
			dd {
				margin-bottom: 1rem;
				overflow: hidden;
				@include sp {
					margin-bottom: 2rem;
				}
			}
		}
		.figwrap {
			display: flex;
			figure {
				display: flex;
				flex-direction: column;
				margin-right: 1rem;
				align-items: center;

				img {
					margin: 0;
				}
				figcaption {
					text-align: center;
				}
			}
		}

      .pd-l__1 { padding-left: 1em; }
      .pd-l__2 { padding-left: 2em; }
      .pd-l__3 { padding-left: 3em; }
      .pd-l__4 { padding-left: 4em; }
      .pd-l__5 { padding-left: 5em; }
      .pd-l__6 { padding-left: 6em; }

      .youtube {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        iframe {
          position: absolute;
          top: 0;
          right: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }
    }
  }
}