.c-slide {
  &_img {
    padding: 6rem 0;
    position: relative;
    @include sp {
      padding: 3rem 0;
    }
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: $lightgray;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) skew(-15deg, 0deg);
      @include sp {
        width: 115%;
      }
    }

    &--inner {
      max-width: 69.5rem;
      width: 80%;
      margin: 0 auto;
    }

    .slick-arrow {
      width: 5.7rem;
      height: 5.7rem;
      z-index: 1;
      transition: opacity $anime;
      border-radius: 1rem;
      box-shadow: $shadow;
      overflow: hidden;
      @include sp {
        width: 3rem;
        height: 3rem;
        border-radius: 0.3rem;
        box-shadow: $shadowSp;
      }
      &:hover {
        opacity: 0.8;
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        background: url('../img/common/ico_arrow01.svg') no-repeat;
        background-size: contain;
        display: block;
        opacity: 1;
      }
    }

    .slick-prev {
      left: -2.85rem;
      transform: translateY(-50%) rotate(-45deg);
      @include sp {
        left: -1.5rem;
      }
    }

    .slick-next {
      right: -2.85rem;
      transform: translateY(-50%) rotate(135deg);
      @include sp {
        right: -1.5rem;
      }
    }

    .slick-disabled {
      opacity: 0;
      pointer-events: none;
    }

    &--item {
      &Bg {
        width: 100%;
        padding-bottom: 65%;
        background-size: cover;
      }
    }

    &Thumbnail {
      margin-top: 1.5rem;
      display: flex;
      justify-content: center;
      position: relative;
      @include sp {
        width: 23rem;
        margin: 1.5rem auto 0;
        flex-wrap: wrap;
      }

      &--item {
        max-width: 12.4rem;
        width: 11.1%;
        cursor: pointer;
        @include sp {
          max-width: none;
          width: 7rem;
        }
        &:not(:last-child) {
          margin-right: 1.5rem;
          @include sp {
            margin-right: 1rem;
          }
        }
        &:nth-child(3n) {
          @include sp {
            margin-right: 0;
          }
        }
        &:not(:nth-child(-n+3)) {
          @include sp {
            margin-top: 1rem;
          }
        }

        &.is-current {
          position: relative;
          pointer-events: none;
          &:after {
            content: '';
            width: 100%;
            height: 100%;
            background-color: #000;
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        &Bg {
          padding-bottom: 65%;
          background-size: cover;
        }
      }
    }
  }
}