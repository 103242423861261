.p-contributions {
  &Intro {
    &_list {
      @include tab {
        display: block;
      }

      &--item {
        @include tab {
          width: 100%;
          margin: 0 auto;
        }

        &:last-child {
          @include tab02 {
            margin-top: 3rem !important;
          }
        }
      }
    }
  }

  &Grop {
    &_wrap {
      @include tab02 {
        display: block;
      }

      &--left {
        width: 36.5%;
        @include tab {
          width: 42rem;
          margin: 0 auto;
        }
        @include sp {
          width: 100%;
        }
      }

      &--right {
        width: 59.5%;
        @include tab02 {
          width: 100%;
          margin-top: 2rem;
        }
      }
    }

    &_bnr {
      &--box {
        max-width: 48rem;
        width: 75%;
        margin: 1.5rem auto 0;
        @include sp {
          width: 100%;
        }
      }
    }

    &_list {
      &--item {
        &:not(:nth-child(-n+2)) {
          margin-top: 4rem;
        }

        &:not(:first-child) {
          @include sp {
            margin-top: 3rem;
          }
        }

        .c-text_size--25 {
          @include tab {
            font-size: 2rem;
          }
        }

        .c-text_size--18 {
          @include tab {
            font-size: 1.4rem;
          }
          @include sp {
            font-size: 1.2rem;
            line-height: 1.2;
          }
        }

        .c-boxSports_name {
          background-color: rgba( $white, 0.8 );
        }
      }
    }
  }

  &Pearls {
    &_pic {
      max-width: 78rem;
      width: 75%;
      margin: 0 auto;
      @include sp {
        width: 100%;
      }

      &.pic02 {
        max-width: 52rem;
      }
    }

    &_bnr {
      max-width: 48rem;
      width: 75%;
      margin: 1.5rem auto 0;
      @include sp {
        width: 100%;
      }
    }
  }

  &Bmx {
    &_wrap {
      @include sp {
        display: block;
      }
      &--center {
        width: 70%;
        position: relative;
        @include sp {
          width: 100%;
        }
      }
      &--left {
        width: 43.4%;
        position: relative;
        @include sp {
          width: 100%;
        }
      }

      &--right {
        width: 53.2%;
        @include sp {
          width: 100%;
          margin-top: 2rem;
        }
      }
    }

    &_pic {
      @include sp {
        padding-bottom: 1rem;
      }
    }

    &_name {
      font-size: 1.8rem;
      padding: 1rem;
      background-color: rgba( $white, 0.8);
      position: absolute;
      bottom: -2rem;
      right: -2rem;
      @include tab {
        font-size: 1.4rem;
        right: -1rem;
      }
      @include sp {
        font-size: 1.2rem;
        right: -1rem;
        bottom: 0;
      }
      span {
        display: block;
        @include tab {
          font-size: 2rem;
        }
      }
    }

    &_text {
      @include tab05 {
        margin-top: 0;
      }
    }
  }

  &Tryhoop {
    &_pic {
      max-width: 36.5rem;
      width: 75%;
      margin: 4rem auto 0;
      @include sp {
        width: 100%;
        margin-top: 3rem;
      }
    }

    &_bnr {
      max-width: 49.5rem;
      width: 100%;
      margin: 1.5rem auto 0;
    }
  }

  &Vickies {
    &_pic {
      display: flex;
      width: 100%;
      margin: 4rem auto 0;
      @include sp {
		flex-direction: column;
        width: 100%;
        margin-top: 3rem;
      }
	  img {
		width: (1/3)*100%;
        @include sp {
          width: 100%;
          margin-top: 1rem;
        }
	  }
    }

    &_bnr {
      max-width: 49.5rem;
      width: 100%;
      margin: 1.5rem auto 0;
    }
  }

  &Fagiano {
    &_pic {
      display: flex;
	  justify-content: center;
      width: 100%;
      margin: 4rem auto 0;
      @include sp {
		flex-direction: column;
        width: 100%;
        margin-top: 3rem;
      }
	  img {
		width: 100%;
	  	max-width: 28rem;
		height: auto;
        @include sp {
          width: 100%;
          margin-top: 1rem;
        }
	  }
    }

    &_bnr {
      max-width: 42.0rem;
      width: 100%;
      margin: 1.5rem auto 0;
    }
  }

  &Orca {
    &_pic {
      display: flex;
      width: 100%;
      margin: 4rem auto 0;
      @include sp {
        flex-direction: column;
        width: 100%;
        margin-top: 3rem;
      }
      img {
        width: (1/2)*100%;
        @include sp {
          width: 100%;
          margin-top: 1rem;
        }
      }
    }
  }

  .c-ttl_sub--en {
    @include sp {
      font-size: 3.2rem;
    }
  }
}