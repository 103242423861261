.p-top {
  &News {
    &_inner {
      padding: 3rem 0;
      @include sp {
        padding-bottom: 11rem;
        position: relative;
      }
    }

    &_wrap {
      @include sp {
        display: block;
      }
    }

    &_left {
      max-width: 32rem;
      width: 26.66%;
      @include sp {
        max-width: none;
        width: 100%;
      }
    }

    &_right {
      max-width: 80.5rem;
      width: 67%;
      padding: 2rem 3rem;
      border-radius: 2rem;
      @include sp {
        max-width: none;
        width: 100%;
        margin-top: 3rem;
        padding: 2rem;
        border-radius: 1rem;
      }
    }

    &_ttl {
      font-size: 6rem;
      line-height: 1.1;
      letter-spacing: 0.6rem;
      @include tab03 {
        font-size: 4.2rem;
      }
      @include sp {
        font-size: 3.2rem;
      }

      &--sub {
        font-size: 2rem;
        margin-top: 1rem;
        @include tab02 {
          font-size: 1.6rem;
        }
        @include sp {
          margin-top: 0.5rem;
        }
      }
    }

    &_btn {
      max-width: 30rem;
      width: 100%;
      margin-top: 4.5rem;
      @include sp {
        max-width: 25rem;
        margin: 0 auto;
        margin-top: 3rem;
        position: absolute;
        bottom: 3rem;
        right: 0;
        left: 0;
      }
    }
  }

  &Movie {
    &_inner {
      padding: 8rem 0;
      @include sp {
        padding: 6rem 0;
      }
    }

    &_ttl {
      font-size: 6rem;
      letter-spacing: 1.5rem;
      @include sp {
        font-size: 3.2rem;
        letter-spacing: 0.8rem;
      }
    }

    &_list {
      margin-top: 4rem;
      @include sp {
        margin-top: 3rem;
        display: block;
      }
      &--item {
        width: 48%;
        @include sp {
          width: 100%;
        }

        &:last-child {
          @include sp {
            margin-top: 3rem;
          }
        }

        img {
          vertical-align: bottom;
        }
      }

      &--pic {
        margin-top: 2rem;
        border-radius: 3rem;
        overflow: hidden;
        box-shadow: $shadow;
        @include sp {
          margin-top: 1rem;
        }
      }

      &--ttl {
        font-size: 3rem;
        @include tab {
          font-size: 2.2rem;
        }
        @include sp {
          font-size: 2rem;
        }

        span {
          height: 4.5rem;
          display: inline-block;
          @include tab {
            height: 3.2rem;
          }
          @include sp {
            height: 3rem;
          }

          img {
            max-width: none;
            max-height: 100%;
          }
        }
      }
    }
  }

  &Field {
    position: relative;
    @include sp {
      padding: 3rem 0;
    }
    &:before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: $darkgray;
      position: absolute;
      top: 0;
      left: 0;
      transform: skew(-15deg, 0deg);
      -webkit-transform: skew(-15deg, 0deg);
      z-index: -1;
      @include sp {
        display: none;
      }
    }
    &_inner {
      padding: 6rem 0 8.5rem;
      @include sp {
        padding: 4rem 0;
        background-color: $darkgray;
        position: relative;
      }
      &:before,
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        left: 0;
        @include pc {
          display: none;
        }
      }

      &:before {
        border-width: 0 0 3rem 37.5rem;
        border-color: transparent transparent $darkgray transparent;
        bottom: 100%;
      }

      &:after {
        border-width: 3rem 37.5rem 0 0;
        border-color: $darkgray transparent transparent transparent;
        top: 100%;
        z-index: 2;
      }
    }

    &_ttl {
      font-size: 4rem;
      letter-spacing: 3px;
      @include tab {
        font-size: 3.2rem;
      }
      @include sp {
        font-size: 2.2rem;
      }
    }

    &_list {
      width: 83%;
      margin: 3rem auto 0;
      transform: skew(-15deg, 0deg);
      @include sp {
        display: block;
        transform: skew(0, 0);
      }

      &--item {
        width: 48%;
        @include sp {
          width: 100%;
          transform: translateY(50%) skew(-15deg,0deg);
        }

        &:not(:nth-child(-n+2)) {
          margin-top: 2rem;
        }
        &:not(:first-child) {
          @include sp {
            margin-top: 2rem;
          }
        }

        a {
          width: 100%;
          padding-bottom: 55%;
          display: block;
          background-color: $white;
          position: relative;
          overflow: hidden;
          &:before {
            content: '';
            width: 100%;
            height: 100%;
            background: url('../img/field_bg01.jpg') no-repeat center;
            background-size: cover;
            position: absolute;
            top: 0;
            left: 0;
            transform: skew(15deg, 0deg) scale(1.2);
            transition: transform 0.4s;
          }

          &:hover {
            opacity: 1;
            &:before {
              transform: skew(15deg, 0deg) scale(1.3);
            }

            .p-topField_list--boxInner {
              background-color: rgba($white, 1);
            }
          }
        }

        &.sports {
          a {
            &:before {
              background-image: url('../img/field_bg02.jpg');
            }
          }
        }

        &.beauty {
          a {
            &:before {
              background-image: url('../img/field_bg03.jpg');
            }
          }
        }

        &.animals {
          a {
            &:before {
              background-image: url('../img/field_bg04.jpg');
            }
          }
        }
      }

      &--box {
        width: 100%;
        height: 100%;
        padding: 1.5rem;
        position: absolute;
        z-index: 1;

        &Inner {
          width: 100%;
          height: 100%;
          background-color: rgba($white, 0.46);
          transition: background-color 0.4s;
        }
      }

      &--ttl {
        font-size: 3.9rem;
        line-height: 1.3;
        transform: skew(15deg, 0deg);
        line-height: 1;
        letter-spacing: 0.6rem;
        @include tab {
          font-size: 2.8rem;
        }
        @include sp {
          font-size: 2.2rem;
          line-height: 1.1;
        }

        .small {
          font-size: 2.4rem;
          @include tab {
            font-size: 1.8rem;
          }
          @include sp {
            font-size: 1.6rem;
          }
        }
      }

      &.is-animated {
        .c-scroll_fadein {
          @include sp {
            transform: translateY(0) skew(-15deg,0deg);
          }
        }
      }
    }
  }

  &Approach {
    @include sp {
      padding: 10rem 0 6rem;
      position: relative;
    }
    &_wrap {
      @include sp {
        display: block;
      }
    }
    &_bg {
      flex-basis: 46%;

      &--inner {
        width: 200%;
        position: relative;
        left: -93%;
        @include sp {
          width: 100%;
          position: absolute;
          left: -12rem;
          top: -2rem;
        }

        img {
          vertical-align: bottom;
        }
      }
    }
    &_cont {
      flex-basis: 54%;
      position: relative;
      @include sp {
        padding: 3rem 2rem;
        background-color: $white;
        border-radius: 1rem;
        box-shadow: 0.2rem 0.3rem 1rem rgba( $black , 0.26);
      }
    }

    &_box {
      max-width: 48.7rem;
      width: 90%;
      margin: 0 auto;
      @include sp {
        max-width: none;
        width: 100%;
        padding: 0 1rem;
      }
    }

    &_ttl {
      font-size: 4rem;
      line-height: 1.5;
      @include tab {
        font-size: 2.8rem;
      }
      @include sp {
        font-size: 2.4rem;
      }
    }

    &_text {
      line-height: 1.625;
      margin-top: 3rem;
      @include tab {
        font-size: 1.4rem;
        margin-top: 2rem;
      }
      @include sp {
        margin-top: 2rem;
      }

      p {
        &:not(:first-child) {
          margin-top: 0.8rem;
        }
      }
    }

    &_list {
      margin-top: 6rem;
      @include tab {
        margin-top: 3rem;
      }
      @include sp {
        margin-top: 3rem;
        display: block;
      }
      &--item {
        width: 47.6%;
        @include sp {
          width: 100%;
        }
        &:nth-child(even) {
          margin-left: 4.8%;
          @include sp {
            margin-left: 0;
          }
        }
        &:not(:nth-child(-n+2)) {
          margin-top: 3rem;
          @include tab {
            margin-top: 1.5rem;
          }
        }
        &:not(:first-child) {
          @include sp {
            margin-top: 1.5rem;
          }
        }
      }
    }
  }

  &Company {
    &_inner {
      padding: 10rem 0 14rem;
      @include sp {
        padding: 5rem 0 6rem;
      }
    }

    &_btn {
      max-width: 83rem;
      width: 100%;
      margin: 0 auto;
    }
  }

  &Association {
    &_inner {
      padding: 10rem 0 14rem;
      @include sp {
        padding: 5rem 0 6rem;
      }
    }

    &_btn {
      max-width: 83rem;
      width: 100%;
      margin: 0 auto;
	  margin-top: 2rem;
    }
  }

  &Contact {
    padding: 6.5rem 0 6rem;
    position: relative;
    @include sp {
      padding: 4rem 0;
    }

    &_ttl {
      font-size: 6rem;
      margin: 0 auto;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      transform: translateY(-50%);
      letter-spacing: 0.5rem;
      @include sp {
        font-size: 4rem;
      }
    }

    &_text {
      font-size: 2.6rem;
      @include sp {
        font-size: 1.7rem;
      }
    }

    &_list {
      margin-top: 6rem;
      @include sp {
        margin-top: 3rem;
      }
      &--item {
        max-width: 40rem;
        width: 48%;
        @include sp {
          max-width: none;
          width: 100%;
        }
        &:nth-child(even) {
          margin-left: 3rem;
          @include tab {
            margin-left: 4%;
          }
          @include sp {
            margin-left: 0;
          }
        }

        &:not(:last-child) {
          margin-bottom: 3rem;
          @include sp {
            margin-bottom: 1.5rem;
          }
        }
      }
    }

    &_link {
      margin-top: 4rem;
      @include sp {
        margin-top: 2.5rem;
      }
      a {
        font-size: 1.6rem;
        padding-bottom: 0.5rem;
        display: inline-block;
        position: relative;
        @include sp {
          font-size: 1.4rem;
        }
        &:after {
          content: '';
          width: 100%;
          height: 1px;
          margin: 0 auto;
          background-color: $red;
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }
    }
  }

}