.l-footer {
  color: $black;
  font-family: $notoSans;
  font-size: 1.6rem;
  line-height: normal;

  a {
    color: $black;
    font-family: $notoSans;
    font-size: 1.6rem;
    text-decoration: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    @include pc {
      transition: opacity $anime;
    }
    &:hover {
      @include pc {
        opacity: 0.8;
      }
    }
  }

  p {
    line-height: normal;
  }
  
  &Cont {
    padding: 9rem 0 7rem;
    position: relative;
    @include sp {
      padding: 4rem 0;
    }

    &_wrap {
      max-width: 134rem !important;
      @include tab {
        display: block;
      }
    }

    &_left {
      max-width: 31rem;
      width: 27%;
      @include tab {
        max-width: none;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @include sp {
        max-width: none;
        width: 100%;
      }

      &--text {
        font-size: 1.4rem;
        margin-top: 3rem;
        @include tab {
          margin: 0 0 0 5rem;
        }
        @include sp {
          font-size: 1.2rem;
          margin-top: 2rem;
        }
      }

      &--company {
        line-height: 1.85 !important;
        font-weight: 500;
      }

      &--address {
        text-indent: -3em;
        padding-left: 3em;
        line-height: 1.85 !important;
        font-weight: 500;
      }
    }

    &_right {
      max-width: 90rem;
      width: 72%;
      @include tab {
        max-width: 60rem;
        width: 100%;
        margin: 4rem auto 0;
      }
    }
  }

  &_pagetop {
    position: fixed;
    bottom: 3rem;
    right: 12rem;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.4s;
    z-index: 10;
    pointer-events: none;
    @include tab {
      right: 6rem;
    }
    @include sp {
      bottom: 2rem;
      right: 2rem;
    }
    &.is-view {
      opacity: 1;
      pointer-events: auto;
    }
    &.is-absolute {
      position: absolute;
      top: 0;
      bottom: auto;
    }
    &--btn {
      width: 9.7rem;
      height: 9.7rem;
      border-radius: 1.4rem;
      box-shadow: 0.2rem 0.3rem 2rem rgba( $black, 0.26);
      display: block;
      position: relative;
      transform: rotate(-45deg);
      @include tab {
        width: 7rem;
        height: 7rem;
        border-radius: 1rem;
      }
      @include sp {
        width: 6rem;
        height: 6rem;
        border-radius: 1rem;
      }
      &:after {
        content: '';
        width: 2.5rem;
        height: 2.5rem;
        border: 0px;
        border-top: solid 0.3rem $white;
        border-right: solid 0.3rem $white;
        position: absolute;
        top: 55%;
        left: 45%;
        transform: translate(-55%, -45%);
        @include sp {
          width: 1.6rem;
          height: 1.6rem;
          border-width: 0.2rem;
        }
      }
    }
  }

  &_logo {
    width: 92%;
    @include tab {
      width: 29rem;
    }
    @include sp {
      width: 20rem;
    }

    a {
      display: block;
    }
  }

  &Navi {
    @include tab {
      flex-wrap: wrap;
    }
    &_cont {
      @include tab {
        width: 50%;
      }
      @include sp {
        width: 100%;
        margin-top: 3rem;
      }

      &:not(:nth-child(-n+2)) {
        @include tab {
          margin-top: 3rem;
        }
      }
      &:not(:first-child) {
        margin-left: 3rem;
        @include tab {
          margin-left: 0;
        }
        @include sp {
          margin-top: 2rem;
        }
      }
    }
    &_ttl {
      font-size: 1.6rem;
      &:not(:first-of-type) {
        margin-top: 2rem;
        @include sp {
          margin-top: 1rem;
        }
      }
      a {
        font-size: 1.6rem;
        @include sp {
          font-size: 1.4rem;
        }
      }
    }

    &_list {
      padding-left: 1.5rem;
      @include sp {
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
      }
      &--item {
        margin-top: 2rem;
        @include sp {
          width: 50%;
          margin-top: 1rem;
        }
        a {
          font-size: 1.4rem;
          line-height: 1.5;
          font-weight: 500;
          @include sp {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  &_copyright {
    padding: 2rem;
    @include sp {
      padding: 1.5rem;
    }

    &--text {
      font-size: 1.2rem;
      @include sp {
        font-size: 1rem;
      }
    }
  }
}