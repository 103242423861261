.c-kv {
  &_inner {
    padding: 8.5rem 0;
    background-size: cover;
    background-position: center center;
    @include pc03 {
      padding: 6% 0;
    }
    @include sp {
      padding: 5rem 0;
    }
  }

  &Sub {
    &_inner {
      padding: 21rem 0 6rem;
      @include tab {
        padding: 18rem 0 6rem;
      }
      @include sp {
        padding: 12rem 0 5rem;
      }
    }

    &_ttl {
      font-size: 6rem;
      letter-spacing: 0.5rem;
      @include tab {
        font-size: 4.5rem;
      }
      @include sp {
        font-size: 3rem;
        letter-spacing: 0.3rem;
      }

      &--sub {
        font-size: 2rem;
        margin-top: 1rem;
        letter-spacing: 0.2rem;
        @include sp {
          font-size: 1.6rem;
        }
      }
    }

    &.medicell {
      .c-kvSub_inner {
        background: url('../img/medicell/kv_bg01.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &.product {
      .c-kvSub_inner {
        background: url('../img/product/kv_bg01.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &.hybrid {
      .c-kvSub_inner {
        background: url('../img/product/hybrid/kv_bg01.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &.question {
      .c-kvSub_inner {
        background: url('../img/product/question/kv_bg01.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &.career {
      .c-kvSub_inner {
        background: url('../img/product/career/kv_bg01.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &.facility {
      .c-kvSub_inner {
        background: url('../img/facility/kv_bg01.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &.company {
      .c-kvSub_inner {
        background: url('../img/company/kv_bg01.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &.recruit {
      .c-kvSub_inner {
        background: url('../img/recruit/kv_bg01.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &.contributions {
      .c-kvSub_inner {
        background: url('../img/company/contributions/kv_bg01.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &.sdgs {
      .c-kvSub_inner {
        background: url('../img/company/sdgs/kv_bg01.jpg') no-repeat 0%;
        background-size: cover;
      }
    }

    &.news {
      .c-kvSub_inner {
        background: url('../img/news/kv_bg01.jpg') no-repeat center;
        background-size: cover;
      }
    }

    &.mysite {
      .c-kvSub_inner {
        width: 100%;
        height: 39rem;
        padding: 0;
        background: url('../img/mysite-form/kv_bg01.jpg') no-repeat center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        @include tab {
          height: 34rem;
        }
        @include sp {
          height: 24.3rem;
        }
      }

      .c-kvSub_logo {
        max-width: 45rem;
        width: 100%;
        margin: 0 auto;
        transform: translateX(-1rem);
        @include tab {
          max-width: 38rem;
        }
        @include sp {
          max-width: 25rem;
        }
      }
    }

    &.notFound {
      .c-kvSub_inner {
        background: $dullColor;
      }
    }
  }

  &Scene {
    width: 100%;
    height: 60rem;
    background: url('../img/treatment/kv_bg01.jpg') no-repeat;
    background-position: 50% 35%;
    background-size: cover;
    @include tab {
      height: 40rem;
    }
    @include sp {
      height: 30rem;
    }

    &_wrap {
      width: 100%;
      height: 100%;
      position: relative;
    }

    &_ttl {
      width: 86%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-10%);
      @include tab {
        width: 60%; //70%
      }
      @include sp {
        width: 30rem; //35%
      }
    }

    &.sports {
      background-image: url('../img/sports/kv_bg01.jpg');
      background-position: center center;

      .c-kvScene {
        &_ttl {
          width: 80.85%;
          @include tab {
            width: 56.6%;
          }
          @include sp {
            width: 28.3rem;
          }
        }
      }
    }

    &.kinmakubiryo {
      background-image: url('../img/kinmakubiryo/kv_bg01.jpg');
      background-position: center center;
      .c-kvScene {
        &_ttl {
          width: 28.8%;
          transform: translate(-50%,-30%);
          @include tab {
            width: 22.2%;
            transform: translate(-50%,-20%);
          }
          @include sp {
            width: 15.1rem;
          }
        }
      }
    }

    &.relaxation {
      background-image: url('../img/relaxation/kv_bg01.jpg');
      background-position: 75% center;
      .c-kvScene {
        &_ttl {
          width: 91.6%;
          @include tab {
            width: 64.1%;
          }
          @include sp {
            width: 32.1rem;
          }
        }
      }
    }

    &.pets {
      background-image: url('../img/pets/kv_bg01.jpg');
      background-position: center center;
      .c-kvScene {
        &_ttl {
          width: 40.5%;
          @include tab {
            width: 28.4%;
          }
          @include sp {
            width: 18.2rem;
          }
        }
      }
    }

    &.racehorces {
      background-image: url('../img/racehorces/kv_bg01.jpg');
      background-position: center center;
      .c-kvScene {
        &_ttl {
          width: 97.2%;
          @include tab {
            width: 68%;
          }
          @include sp {
            width: 34rem;
          }
        }
      }
    }
  }
}