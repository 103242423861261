.p-kv {
  &_inner {
    padding: 4rem 0;
    @include sp {
      padding: 3rem 0;
    }
  }

  &Slide {
    &_list {
      padding: 0 8.57%;
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      transition: 1s;
      left: -173%;
      @include sp {
        padding: 0;
        left: -200%;
      }
      &--item {
        min-width: 100%;
        @include sp {
          padding: 0 2.2rem;
        }
        &:not(:last-child) {
          @include pc {
            margin-right: 4.3%;
          }
        }

        a {
          display: block;
        }

        img {
          vertical-align: bottom;
          transition: 1s;
        }

        &.is-animation {
          img {
            transform: scale(0.9);
          }
        }
      }

      &.prev {
        left: -87%;
        @include sp {
          left: -100%;
        }
      }

      &.first {
        left: -173%;
        @include sp {
          left: -200%;
        }
      }

      &.second {
        left: -259.5%;
        @include sp {
          left: -300%;
        }
      }

      &.third {
        left: -346%;
        @include sp {
          left: -400%;
        }
      }

      &.forth {
        left: -432%;
        @include sp {
          left: -500%;
        }
      }

      &.next {
        left: -518.69%;
        @include sp {
          left: -600%;
        }
      }

      &.is-nosmooth {
        transition: 0s;
      }
    }

    &_navi {
      max-width: 102rem;
      width: calc( 100% - 4rem);
      margin: 2rem auto 0;

      &List {
        @include sp {
          padding: 0;
          justify-content: center;
        }
        &--item {
          &:not(:first-child) {
            margin-left: 2.3rem;
            @include sp {
              margin-left: 2rem;
            }
          }
          button {
            width: 1.6rem;
            height: 1.6rem;
            border: 0.1rem solid $red;
            border-radius: 0.2rem;
            transform: rotate(45deg);
            display: block;
            transition: background-color 0.4s;
            @include sp {
              width: 1.4rem;
              height: 1.4rem;
            }
          }

          &.is-current {
            button {
              background-color: $red;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}

.p-kv02 {
  &_inner {
    padding: 2rem 0;

    padding: 0 0 2rem;
  }

  &_slide {
    &List {
      font-size:0;
      white-space: nowrap;
      position: relative;
      left: 0;
      transition: 1s;
      &.first {
        left: 0;
      }
      &.second {
        left: -100%;
      }
      &.third {
        left: -200%;
      }
      &.forth {
        left: -300%;
      }
      &.fifth {
        left: -400%;
      }
      &.next {
        left: -500%;
      }

      &.is-nosmooth {
        transition: 0s;
      }
    }
    &Cont {
      width: 100%;
      display: inline-block;

      &_inner {
        width: 100%;
        height: 100vh;
      }

      a {
        max-width: 102rem;
        width: calc( 100% - 4rem);
        margin: 0 auto;
        display: block;

        max-width: none;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        transition: transform 1s;
        position: relative;
        overflow: hidden;

        &:hover {
          opacity: 1;
        }
      }

      &--ttl {
        position: absolute;

        img {
          width: 100%;
        }
      }

      &--btn {
        width: 14.85%;
        position: absolute;
        bottom: 0;
        right: 8.5%;
        @include tab05 {
          max-width: 34rem;
          width: 45.33%;
          bottom: -1.6%;
          right: -1.8%;
        }
        @include sp {
          width: 16rem;
          bottom: -1rem;
          right: -0.7rem;
        }

        img {
          width: 100%;
        }
      }

      .pc01 {
        @include tab05 {
          display: none;
        }
      }

      .sp01 {
        display: none;
        @include tab05 {
          display: block;
        }
      }

      // img {
      //   width: 100%;
      //   height: 100%;
      //   transition: 1s;
      //   vertical-align: bottom;
      //   object-fit: cover;
      // }

      &.is-animation {
        a {
          transform: scale(0.95);


          transform: scale(0.6);
        }
      }

      &.cont01 {
        a {
          background-image: url('../img/kv_slide01.jpg');
          @include tab05 {
            background-image: url('../img/kv_slide01_sp.jpg');
          }
        }

        .p-kv02_slideCont--ttl {
          &.ttl01 {
            width: 26.7%;
            top: 30%;
            left: 8.5%;
            @include tab04 {
              max-width: 51.4rem;
              width: 67%;
              top: 15.3%;
              left: 2.4rem;
            }
            @include sp {
              width: 25rem;
              top: 9.5rem;
              left: 1rem;
            }
          }

          &.ttl02 {
            width: 23.4%;
            top: 33.2%;
            right: 10.35%;
            @include tab04 {
              max-width: 35.6rem;
              width: 46.4%;
              top: auto;
              bottom: 14.8%;
              right: 3.4%;
            }
            @include sp {
              width: 17.3rem;
              top: auto;
              bottom: 8.8rem;
              right: 1.3rem;
            }
          }
        }
      }

      &.cont02 {
        a {
          background-image: url('../img/kv_slide02.jpg');
          @include tab05 {
            background-image: url('../img/kv_slide02_sp.jpg');
          }
        }

        .p-kv02_slideCont--ttl {
          width: 57.35%;
          top: calc( 50% + 25px);
          left: calc( 50% - 13px);
          transform: translate(-50%, -50%);
          @include tab04 {
            width: 70%;
          }
          @include sp {
            width: 33rem;
            top: calc( 50% - 0.6rem); 
            left: calc( 50% - 0.6rem); 
          }
        }
      }

      &.cont03 {
        a {
          background-image: url('../img/kv_slide03.jpg');
          @include tab05 {
            background-image: url('../img/kv_slide03_sp.jpg');
          }
        }

        .p-kv02_slideCont--ttl {
          width: 21.9%;
          min-width: 30rem;
          top: calc( 50% + 2rem);
          left: 50%;
          transform: translate(-50%, -50%);
          @include sp {
            width: 15.4rem;
            min-width: 0;
            top: auto;
            bottom: 11rem;
            transform: translateX(-50%);
          }
        }
      }

      &.cont04 {
        a {
          background-image: url('../img/kv_slide04.jpg');
          @include tab05 {
            background-image: url('../img/kv_slide04_sp.jpg');
          }
        }

        .p-kv02_slideCont--ttl {
          width: 62.85%;
          min-width: 70rem;
          top: calc( 50% + 1.5rem);
          left: 50%;
          transform: translate(-50%, -50%);
          @include sp {
            width: 35.2rem;
            min-width: 0;
            top: calc( 50% + 0.5rem);
          }
        }
      }

      &.cont05 {
        a {
          background-image: url('../img/kv_slide05.jpg');
          @include tab05 {
            background-image: url('../img/kv_slide05_sp.jpg');
          }
        }

        .p-kv02_slideCont--ttl {
          width: 60%;
          min-width: 70rem;
          top: calc( 50% + 1rem);
          left: 50%;
          transform: translate(-50%, -50%);
          @include sp {
            width: 33.6rem;
            min-width: 0;
            top: calc( 50% + 0.5rem);
          }
        }
      }
    }
  }
}

.p-kv03 {
  padding-bottom: 2rem;
  &_slide {
    width: 100%;
    display: flex;
    position: relative;

    &.slide01 {
      left: 0;
    }
    &.slide02 {
      left: -100%;
    }
    &.slide03 {
      left: -200%;
    }
    &.slide04 {
      left: -300%;
    }
    &.slide05 {
      left: -400%;
    }
    &.slide06 {
      left: -500%;
    }

    &.is-slider {
      transition: left 1s;
    }
    &--item {
      min-width: 100%;
      a {
        width: 100%;
      }
    }

    &Cont {
      width: 100%;
      height: 100vh;
      display: inline-block;

      a {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        display: block;
        transform: scale(1);
        transition: transform 1s;
        position: relative;
        overflow: hidden;
        &:hover {
          opacity: 1;
        }

        &.is-animation {
          transform: scale(0.867);
          @include sp {
            transform: scale(0.972);
          }
        }
      }

      &--ttl {
        position: absolute;
        top: 50%;
        @include sp {
          top: auto;
        }
      }

      &--btn {
        width: 14.85%;
        position: absolute;
        bottom: 0;
        right: 8.57%;
        @include tab04 {
          width: 20%;
        }
        @include sp {
          width: 17rem;
          right: -0.7rem;
          bottom: -0.9rem;
        }
      }

      &.cont01 {
        a {
          background-image: url('../img/kv_slide01.jpg');
          @include tab05 {
            background-image: url('../img/kv_slide01_sp.jpg');
          }
        }
        .ttl01 {
          width: 27.78%;
          left: 8.57%;
          @include pc {
            transform: translateY(-98%);
          }
          @include tab04 {
            width: 40%;
          }
          @include sp {
            width: 25.7rem;
            left: 1.4rem;
            top: 9.5rem;
          }
        }

        .ttl02 {
          width: 23.42%;
          right: 9.78%;
          @include pc {
            transform: translateY(-136%);
          }
          @include tab04 {
            width: 30%;
          }
          @include sp {
            width: 17.6rem;
            bottom: 8.5rem;
            right: 1.6rem;
          }
        }
      }

      &.cont02 {
        a {
          background-image: url('../img/kv_slide02.jpg');
          @include tab05 {
            background-image: url('../img/kv_slide02_sp.jpg');
          }
        }

        .ttl01 {
          width: 58.57%;
          left: 21.64%;
          @include pc {
            transform: translateY(-41.17%);
          }
          @include tab04 {
            width: 65%;
            margin: 0 auto;
            left: 0;
            right: 0;
          }
          @include sp {
            width: 33rem;
            top: 50%;
            left: 3rem;
            transform: translateY(-50%);
          }
        }
      }

      &.cont03 {
        a {
          background-image: url('../img/kv_slide03.jpg');
          @include tab05 {
            background-image: url('../img/kv_slide03_sp.jpg');
          }
          @include tab04 {
            background-position: center top;
          }
          @include sp {
            background-position: center bottom;
          }
        }

        .ttl01 {
          width: 22.14%;
          margin: 0 auto;
          left: 0;
          right: 0;
          @include pc {
            transform: translateY(-38.72%);
          }
          @include tab04 {
            width: 30%;
          }
          @include sp {
            width: 17.3rem;
            bottom: 9.7rem;
          }
        }
      }

      &.cont04 {
        a {
          background-image: url('../img/kv_slide04.jpg');
          @include tab05 {
            background-image: url('../img/kv_slide04_sp.jpg');
          }
        }

        .ttl01 {
          width: 65.5%;
          left: 17.85%;
          @include pc {
            transform: translateY(-37%);
          }
          @include tab04 {
            width: 75%;
            margin: 0 auto;
            left: 0;
            right: 0;
          }
          @include sp {
            width: 38.4rem;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

      &.cont05 {
        a {
          background-image: url('../img/kv_slide05.jpg');
          @include tab05 {
            background-image: url('../img/kv_slide05_sp.jpg');
          }
        }

        .ttl01 {
          width: 61.42%;
          margin: 0 auto;
          left: 0;
          right: 0;
          @include pc {
            transform: translateY(-11.97%);
          }
          @include tab04 {
            width: 70%;
          }
          @include sp {
            width: 36.5rem;
            right: auto;
            left: 1rem;
            top: 50%;
            transform: translateY(-2.2rem);
          }
        }
      }
    }
  }
}