.p-minipro8 {
  &Intro {
    &_logo {
      max-width: 56.7rem;
      width: 50%;
      margin: 0 auto;
      @include sp {
        max-width: none;
        width: 20rem;
      }

      img {
        width: 100%;
      }
    }

    &_text {
      line-height: 1.666;
    }

    &_movie {
      &--pic {
        max-width: 78rem;
        width: 67%;
        margin: 0 auto;
        border-radius: 3rem;
        box-shadow: $shadow02;
        overflow: hidden;
        @include sp {
          max-width: none;
          width: 100%;
          border-radius: 1.5rem;
          box-shadow: $shadowSp02;
        }
      }
    }
  }

  &Dtail {
    &_ttl {
      span {
        @include sp {
          padding: 0 0 1.5rem;
        }
      }
    }

    &_cap {
      text-indent: -7.5em;
      padding-left: 7.5em;
      @include sp {
        padding-left: 0;
        text-indent: 0;
      }
    }
  }

  &Effect {
    &_ttl {
      img {
        width: auto;
        height: 5.25rem;
        margin-right: 1rem;
        @include tab {
          height: 4rem;
        }
        @include sp {
          height: 3rem;
        }
      }
    }

    &_link {
      @include sp {
        display: block;
      }
      &--item {
        max-width: 42rem;
        width: 50%;
        @include sp {
          max-width: none;
          width: 100%;
        }
        &:last-child {
          margin-left: 4rem;
          @include tab {
            margin-left: 2rem;
          }
          @include sp {
            margin: 2rem 0 0;
          }
        }
      }

      &--text {
        margin-bottom: 1.5rem;
        @include tab {
          font-size: 1.8rem;
        }
        @include sp {
          font-size: 1.6rem;
          margin-bottom: 0.5rem;
        }
      }

      &--btn {
        max-width: 40rem;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}