.c-ttl {
  &_line {
    >span {
      font-size: 4rem;
      padding: 0 2rem 1.5rem;
      letter-spacing: 0.3rem;
      display: inline-block;
      position: relative;
      @include tab {
        font-size: 2.8rem;
        padding: 0 1.5rem 1.5rem;
      }
      @include sp {
        font-size: 2rem;
        letter-spacing: 0.2rem;
        padding: 0 1rem 1rem;
      }

      &:before,
      &:after {
        content: '';
        height: 0.775rem;
        transform: skew(-15deg, 0deg);
        -webkit-transform: skew(-15deg, 0deg);
        position: absolute;
        bottom: 0;
        left: 0;
        @include sp {
          height: 0.5rem;
        }
      }

      &:before {
        width: 100%;
        background-color: $gray;
      }

      &:after {
        width: 5.3rem;
        background-color: $red;
        border-right: 0.2rem solid $white;
        @include sp {
          width: 3rem;
        }
      }

      br {
        &.sp {
          @include sp {
            display: inline-block;
          }
        }

        &.tab02 {
          @include tab02 {
            display: inline-block;
          }
        }

        &.tab05 {
          @include tab05 {
            display: inline-block;
          }
        }
      }

      .pc {
        @include pc {
          display: inline-block;
        }
      }

      .c-text_size--25 {
        font-size: 2.5rem;
        @include sp {
          font-size: 1.4rem;
        }
      }
    }

    &--32 {
      span {
        font-size: 3.2rem;
        padding-bottom: 1rem;
        @include tab {
          font-size: 2.6rem;
        }
        @include sp {
          font-size: 2rem;
        }
      }
    }

    &--small {
      span {
        font-size: 2.4rem;
        padding-bottom: .5em;
  
        @include tab {
          font-size: 2rem;
        }
        @include sp {
          font-size: 1.6rem;
        }      
      }
    }

    &--en {
      span {
        font-size: 6rem;
        letter-spacing: 1rem;
        line-height: 1;
        @include tab {
          font-size: 4.6rem;
        }
        @include sp {
          font-size: 3rem;
          letter-spacing: 0.5rem;
        }
      }
    }

    &--green {
      span {
        &:after {
          background-color: $green;
        }
      }
    }

    &--greenBlue {
      span {
        &:after {
          background-color: $greenBlue;
        }
      }
    }

    &--rose {
      span {
        &:after {
          background-color: $rose;
        }
      }
    }

    &--orange {
      span {
        &:after {
          background-color: $orange;
        }
      } 
    }

    &--bronze {
      span {
        &:after {
          background-color: $bronze;
        }
      } 
    }

    &--yellow {
      span {
        &:after {
          background-color: $lemon;
        }
      }
    }

    &--purple {
      span {
        &:after {
          background-color: $purple;
        }
      }
    }

    &--pink {
      span {
        &:after {
          background-color: $pink;
        }
      }
    }

    &--azalea {
      span {
        &:after {
          background-color: $azalea;
        }
      }
    }

    &--row {
      line-height: 1.875;
      &:after {
        display: none;
      }
    }
  }

  &_textShadow {
    font-size: 3.2rem;
    text-shadow: $shadow_text;
    letter-spacing: 0.2rem;
    @include tab {
      font-size: 2.4rem;
    }
    @include sp {
      font-size: 1.8rem;
      text-shadow: $shadow_textSp;
    }

    &--40 {
      font-size: 4rem;
      @include tab {
        font-size: 2.8rem;
      }
      @include sp {
        font-size: 2rem;
      }
    }
  }

  &_sub {
    padding-bottom: 2rem;
    position: relative;
    @include sp {
      padding-bottom: 1.2rem;
    }
    &:after {
      content: '';
      width: 5.5rem;
      height: 0.775rem;
      margin: 0 auto;
      background-color: $red;
      transform: skew(-15deg, 0deg);
      -webkit-transform: skew(-15deg, 0deg);
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      @include sp {
        width: 3rem;
        height: 0.5rem;
      }
    }
    &--en {
      font-size: 6rem;
      letter-spacing: 0.5rem;
      @include tab {
        font-size: 4.5rem;
      }
      @include sp {
        font-size: 3rem;
        letter-spacing: 0.3rem;
      }
    }

    &--ja {
      font-size: 2.4rem;
      font-weight: 500;
      letter-spacing: 0.2rem;
      @include tab {
        font-size: 2rem;
      }
      @include sp {
        font-size: 1.6rem;
      }

      &--20 {
        font-size: 2rem;
        @include tab02 {
          font-size: 1.6rem;
        }
      }
    }
  }

  &_marker {
    font-size: 3.2rem;
    letter-spacing: 0.2rem;
    @include tab {
      font-size: 2.4rem;
    }
    @include sp {
      font-size: 2rem;
      letter-spacing: 0;
    }

    > span {
      padding: 0 2rem;
      position: relative;
      display: inline-block;
      @include sp {
        padding: 0 0.5rem;
      }
      &:before {
        content: '';
        width: 100%;
        height: 1.665rem;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: skew(-15deg, 0deg);
        -webkit-transform: skew(-15deg, 0deg);
        @include sp {
          height: 0.8rem;
        }
      }

      span {
        position: relative;
      }
    }

    &--gray {
      &:before {
        background-color: $gray;
      }
    }
  }

  &_underLine {
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 0.4rem;
      background-color: $green;
      position: absolute;
      bottom: -0.2rem;
      left: 0;
      @include sp {
        height: 0.2rem;
      }
    }

    &--greenBlue {
      &:after {
        background-color: $greenBlue;
      }
    }

    &--rose {
      &:after {
        background-color: $rose;
      }
    }

    &--orange {
      &:after {
        background-color: $orange;
      }
    }

    &--bronze {
      &:after {
        background-color: $bronze;
      }
    }

    &--thin {
      &:after {
        height: 0.2rem;
        @include sp {
          height: 0.1rem;
        }
      }
    }
  }

  &_sdgs {
    border-bottom: 0.3rem solid #E83418;
    @include sp {
      position: relative;
      padding-bottom: 1rem;
    }

    &--ico {
      width: 10rem;
      height: 10rem;
      display: block;
      @include sp {
        width: 5rem;
        height: 5rem;
        position: absolute;
        left: 0;
        bottom: -7rem;
      }
    }

    &--text {
      font-size: 2.5rem;
      width: calc( 100% - 13rem);
      @include tab {
        font-size: 2.2rem;
      }
      @include sp {
        font-size: 1.8rem;
        width: 100%;
      }
    }

    &--green {
      border-color: #1B973A;
    }

    &--rose {
      border-color: #C50E28;
    }

    &--madder {
      border-color: #970A31;
    }

    &--blue {
      border-color: #023067;
    }
  }

  &Seminar {
    &_ico {
      width: 10rem;
      height: 10rem;
      @include tab {
        width: 8rem;
        height: 8rem;
      }
      @include sp {
        width: 5rem;
        height: 5rem;
      }
    }

    &_ttl {
      width: calc( 100% - 12.5rem);
      @include tab {
        width: calc( 100% - 10.5rem);
      }
      @include sp {
        width: calc( 100% - 6rem);
      }
      &--text {
        font-size: 2.4rem;
        padding-left: 2.5rem;
        @include tab {
          font-size: 2rem;
          padding-left: 1.5rem;
        }
        @include sp {
          font-size: 1.4rem;
          padding-left: 1rem;
        }
      }

      &--ttl {
        @include sp {
          margin-top: 0.5rem;
        }

        span {
          @include sp {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}