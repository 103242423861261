.p-introduction {
  .c-boxNum_text {
    padding-left: 0;
  }

  .c-text_indent {
    padding-left: 1em;
  }

  &_list {
    &--item {
      &:last-child {
        @include sp {
          margin-top: 2rem;
        }
      }
    }
    &--text {
      @include sp {
        font-size: 1.6rem;
      }
    }
  }
}