@charset "UTF-8";


/* ブラウザのUAスタイルシートのすべてのスタイルを削除します、displayは除く */
*:where(:not(iframe, canvas, img, svg, video):not(svg *)) {
  all: unset;
  display: revert;
}

/* box-sizingの優先値 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* リストのスタイル（箇条書き・番号）を削除します */
ol,
ul {
  list-style: none;
}

/* 画像がコンテナを超えないようにするため */
img {
  max-width: 100%;
}

/* テーブルのセル間のスペースを削除します */
table {
  border-collapse: collapse;
}

html {
  font-size: 62.5%;
}
@media screen and (max-width: 767px) {
  html {
    font-size: 2.6666666667vw;
  }
}

body {
  color: #000;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  margin: 0;
}
body.is-noscroll {
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  body .pc {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  body .pc {
    display: none;
  }
}

main {
  padding-top: 14.7rem;
  display: block;
  padding: 0;
}
@media screen and (max-width: 767px) {
  main {
    padding-top: 8rem;
    padding: 0;
  }
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

a {
  color: #000;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media screen and (min-width: 768px) {
  a {
    transition: opacity 0.25s ease;
  }
}
@media screen and (min-width: 768px) {
  a:hover {
    opacity: 0.8;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

ul {
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 901px) {
  .pc04 {
    display: block;
  }
}
@media screen and (max-width: 900px) {
  .pc04 {
    display: none;
  }
}

.pc05 {
  display: none;
}
@media screen and (min-width: 1025px) {
  .pc05 {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .tab {
    display: none;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .tab {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .tab {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .tab02 {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .tab02 {
    display: block;
  }
}

.tab04 {
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .tab04 {
    display: block;
  }
}

.tab05 {
  display: none;
}
@media screen and (max-width: 1025px) {
  .tab05 {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .sp {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .sp {
    display: block;
  }
}

.sp02 {
  display: none;
}
@media screen and (max-width: 360px) {
  .sp02 {
    display: block;
  }
}

.abrilFatface {
  font-family: "Abril Fatface", cursive;
  font-weight: 400;
}

.josefin {
  font-family: "Josefin Sans", sans-serif;
  font-weight: 700;
}

.displayNone {
  display: none;
}

button {
  background: none;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.displayInlineBlock {
  display: inline-block;
}

.js-modal {
  cursor: pointer;
}

form input[type=text],
form input[type=email],
form select {
  line-height: 1;
}
form input[name=mwform_submitButton-242] {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .signage.mysite-form {
    background-image: url("/wordpress/wp-content/uploads/2018/11/mysite-header.jpg") !important;
  }
}
@media screen and (max-width: 767px) {
  .signage.seminar-form {
    background-image: url("/wordpress/wp-content/uploads/2017/09/0000.jpg") !important;
  }
}

.l-header {
  color: #000;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  line-height: normal;
  width: 100%;
  padding: 0 3.5rem;
  position: fixed;
  top: 6.65%;
  left: 0;
  z-index: 999;
  transition: left 0.25s ease, transform 0.45s cubic-bezier(0.23, 1, 0.58, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.58, 1);
}
@media screen and (max-width: 767px) {
  .l-header {
    padding: 0 1rem;
    top: 1.4%;
  }
}
.l-header--wp {
  top: 0 !important;
  transition: left 0.25s ease, transform 0.45s cubic-bezier(0.23, 1, 0.58, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.58, 1), top 0.25s ease;
}
.l-header--wp.is-animated {
  top: 6rem !important;
}
@media screen and (max-width: 767px) {
  .l-header--wp.is-animated {
    top: 1rem !important;
  }
}
.l-header_top {
  padding-top: 7.8rem;
}
@media screen and (min-width: 1321px) {
  .l-header_top {
    padding-top: 9rem;
  }
}
@media screen and (max-width: 767px) {
  .l-header_top {
    padding-top: 7.1rem;
  }
}
.l-header a {
  color: #000;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  text-decoration: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media screen and (min-width: 768px) {
  .l-header a {
    transition: opacity 0.25s ease;
  }
}
@media screen and (min-width: 768px) {
  .l-header a:hover {
    opacity: 0.8;
  }
}
.l-header.is-fadeout {
  transform: translateY(-16rem);
}
@media screen and (max-width: 767px) {
  .l-header.is-fadeout {
    transform: translateY(-9rem);
  }
}
.l-header.is-fadein {
  transform: translateY(0) !important;
}
.l-header.is-active {
  left: -25rem;
}
.l-header_inner {
  width: 100%;
  padding: 2rem 4rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 4.5rem;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1320px) {
  .l-header_inner {
    padding: 1.4rem 2rem;
  }
}
@media screen and (max-width: 1050px) and (min-width: 768px) {
  .l-header_inner {
    justify-content: flex-end;
  }
}
.l-header_inner.is-active {
  transition: background-color 0.3s;
  background-color: #fff;
  box-shadow: 0.2rem 0.3rem 1.6rem rgba(0, 0, 0, 0.16);
}
.l-header_logo {
  width: 17.2rem;
  display: block;
}
@media screen and (max-width: 1360px) and (min-width: 1051px) {
  .l-header_logo {
    width: 12rem;
  }
}
@media screen and (max-width: 1050px) and (min-width: 768px) {
  .l-header_logo {
    width: 12rem;
    height: 3.14rem;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
@media screen and (max-width: 767px) {
  .l-header_logo {
    width: 10.2rem;
  }
}
.l-header_logo img {
  vertical-align: bottom;
}
@media screen and (max-width: 1050px) {
  .l-headerNavi_cont {
    display: none;
  }
}
.l-headerNavi_list--item {
  height: 5rem;
  line-height: 5rem;
}
.l-headerNavi_list--item:not(:first-child) {
  margin-left: 2.5rem;
}
@media screen and (max-width: 1360px) and (min-width: 1051px) {
  .l-headerNavi_list--item:not(:first-child) {
    margin-left: 1rem;
  }
}
.l-headerNavi_list--item > a {
  font-size: 1.6rem;
}
@media screen and (max-width: 1360px) and (min-width: 1101px) {
  .l-headerNavi_list--item > a {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1100px) and (min-width: 1051px) {
  .l-headerNavi_list--item > a {
    font-size: 1.3rem;
  }
}
.l-headerNavi_list--item > a:hover {
  transition: color 0.4s;
  color: #D30F1B;
}
.l-headerNavi_list--item.js-dropdown > a {
  padding-right: 1.5rem;
  position: relative;
}
.l-headerNavi_list--item.js-dropdown > a:after {
  content: "";
  width: 7px;
  height: 7px;
  border: 0px;
  border-top: solid 2px #1C1C1C;
  border-right: solid 2px #1C1C1C;
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -3px;
  transition: border-color 0.4s;
}
.l-headerNavi_list--item.js-dropdown > a:hover:after {
  border-color: #D30F1B;
}
.l-headerNavi_btn {
  margin-left: 3rem;
}
@media screen and (max-width: 1360px) and (min-width: 1050px) {
  .l-headerNavi_btn {
    margin-left: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .l-headerNavi_btn {
    margin-left: 0;
  }
}
.l-headerNavi_btn--box {
  width: 11.8rem;
}
@media screen and (max-width: 1360px) and (min-width: 1101px) {
  .l-headerNavi_btn--box {
    width: 10rem;
  }
}
@media screen and (max-width: 1100px) and (min-width: 768px) {
  .l-headerNavi_btn--box {
    width: 9rem;
  }
}
@media screen and (max-width: 767px) {
  .l-headerNavi_btn--box {
    width: 7rem;
  }
}
.l-headerNavi_btn--box:first-of-type {
  margin-right: 1.5rem;
}
@media screen and (max-width: 767px) {
  .l-headerNavi_btn--box:first-of-type {
    margin-right: 1rem;
  }
}
.l-headerNavi_btn--box a {
  font-size: 1.6rem;
  width: 100%;
  height: 5rem;
  background-color: #D30F1B;
  border: 0.1rem solid #D30F1B;
  border-radius: 1rem;
  display: block;
  line-height: 4.8rem;
  box-shadow: 0.2rem 0.3rem 1.1rem rgba(0, 0, 0, 0.26);
}
@media screen and (max-width: 1360px) and (min-width: 1050px) {
  .l-headerNavi_btn--box a {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1050px) {
  .l-headerNavi_btn--box a {
    height: 4.3rem;
    line-height: 1.25;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 1100px) and (min-width: 768px) {
  .l-headerNavi_btn--box a {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 768px) {
  .l-headerNavi_btn--box a {
    transition: color 0.25s ease, background-color 0.25s ease;
  }
}
@media screen and (max-width: 767px) {
  .l-headerNavi_btn--box a {
    font-size: 1rem;
  }
}
@media screen and (min-width: 768px) {
  .l-headerNavi_btn--box a:hover {
    color: #D30F1B !important;
    background-color: #fff;
    opacity: 1;
  }
}
.l-headerNavi_btn--box a.mysite {
  background-color: #06009F;
  border-color: #06009F;
}
@media screen and (min-width: 768px) {
  .l-headerNavi_btn--box a.mysite:hover {
    color: #06009F !important;
    background-color: #fff;
  }
}
.l-headerNavi_btn--box a .tab01 {
  display: none;
}
@media screen and (max-width: 1050px) {
  .l-headerNavi_btn--box a .tab01 {
    display: block;
  }
}
.l-headerNavi_global {
  width: 100%;
  position: absolute;
  top: 4.5rem;
  left: 0;
  z-index: -1;
}
.l-headerNavi_global--wrap {
  height: 0;
  overflow: hidden;
  transition: height 0.4s;
}
.l-headerNavi_global--wrap.is-view {
  position: relative;
}
.l-headerNavi_global--wrap.is-view:after {
  content: "";
  width: 100%;
  height: 1.6rem;
  background-color: #fff;
  position: absolute;
  bottom: 100%;
  left: 0;
}
.l-headerNavi_global--inner {
  padding: 9.5rem 4rem 2rem;
  border-radius: 0 0 4.5rem 4.5rem;
  position: relative;
}
.l-headerNavi_global--inner:before {
  content: "";
  width: calc(100% - 8rem);
  height: 0.2rem;
  margin: 0 auto;
  background-color: #D30F1B;
  position: absolute;
  top: 4.5rem;
  right: 0;
  left: 0;
}
.l-headerNavi_global--ttl span {
  padding-right: 4.5rem;
  display: inline-block;
}
.l-headerNavi_global--ttl .en {
  font-size: 4rem;
  letter-spacing: 0.8rem;
  position: relative;
}
.l-headerNavi_global--ttl .ja {
  font-size: 1.4rem;
}
.l-headerNavi_globalList {
  max-width: 104.7rem;
  width: 100%;
  margin: 3rem auto 0;
}
.l-headerNavi_globalList--item {
  width: 29.5%;
  margin: 0 5.75% 6rem 0;
}
.l-headerNavi_globalList--item:nth-child(3n) {
  margin-right: 0;
}
.l-headerNavi_globalList--item a {
  font-size: 1.6rem;
  line-height: 1.5 !important;
  padding: 1.4rem 2rem 1.4rem 0;
  border-bottom: 0.1rem solid #ddd;
  line-height: 1;
  display: block;
  position: relative;
}
@media screen and (min-width: 768px) {
  .l-headerNavi_globalList--item a {
    transition: color 0.25s ease, border-color 0.25s ease;
  }
}
@media screen and (max-width: 1200px) {
  .l-headerNavi_globalList--item a {
    font-size: 1.4rem;
  }
}
.l-headerNavi_globalList--item a:after {
  content: "";
  width: 8px;
  height: 8px;
  border: 0px;
  border-top: solid 2px #D30F1B;
  border-right: solid 2px #D30F1B;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -4px;
}
@media screen and (min-width: 768px) {
  .l-headerNavi_globalList--item a:hover {
    color: #D30F1B;
    border-color: #D30F1B;
  }
}
.l-header_hamburger {
  width: 3rem;
  height: 3rem;
  margin-left: 1.3rem;
  cursor: pointer;
  position: relative;
}
@media screen and (min-width: 1051px) {
  .l-header_hamburger {
    display: none;
  }
}
.l-header_hamburger span {
  width: 2.6rem;
  height: 2px;
  margin: 0 auto;
  background-color: #D30F1B;
  position: absolute;
  right: 0;
  left: 0;
  transition: transform 0.25s;
}
.l-header_hamburger span:first-child {
  top: 0.7rem;
}
.l-header_hamburger span:nth-child(2) {
  top: 1.45rem;
  transition: opacity 0.25s;
}
.l-header_hamburger span:last-child {
  bottom: 0.7rem;
}
.l-header_hamburger.is-active span:first-child {
  top: 50%;
  transform: rotate(45deg) translateY(-50%);
}
.l-header_hamburger.is-active span:nth-child(2) {
  opacity: 0;
}
.l-header_hamburger.is-active span:last-child {
  bottom: 50%;
  transform: rotate(-45deg) translateY(50%);
}
.l-headerSp {
  width: 25rem;
  height: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  right: -25rem;
  transition: right 0.25s;
  z-index: 11;
}
@media screen and (min-width: 1050px) {
  .l-headerSp {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .l-headerSp {
    width: 26rem;
    right: -26rem;
  }
}
.l-headerSp.is-view {
  right: 0;
}
.l-headerSp_inner {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  transition: width 0.25s;
  overflow: hidden;
}
.l-headerSp_inner.is-hidden {
  width: 0;
}
.l-headerSp_heading {
  width: 100%;
  padding-top: 4rem;
  white-space: nowrap;
  overflow: hidden;
}
.l-headerSp_list {
  margin-top: 1.5rem;
}
.l-headerSp_list--item {
  border-bottom: 0.1rem solid #D30F1B;
  cursor: pointer;
}
.l-headerSp_list--item > a, .l-headerSp_list--item span {
  font-size: 1.2rem;
  padding: 1.5rem 3rem 1.5rem 1rem;
  display: block;
  position: relative;
  white-space: nowrap;
}
.l-headerSp_list--item > a:after, .l-headerSp_list--item span:after {
  content: "";
  width: 8px;
  height: 8px;
  border: 0px;
  border-top: solid 2px #D30F1B;
  border-right: solid 2px #D30F1B;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -4px;
}
.l-headerSp_sub {
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  transition: width 0.25s;
  overflow: hidden;
  white-space: nowrap;
}
.l-headerSp_sub.is-view {
  width: 100%;
}
.l-headerSp_sub--inner {
  height: 100%;
  padding: 0 1rem;
}
.l-headerSp_subHeading {
  width: 100%;
  height: 12rem;
}
.l-headerSp_subHeading--en {
  font-size: 2.5rem;
  letter-spacing: 0.2rem;
  line-height: 0.9;
}
.l-headerSp_subHeading--ja {
  font-size: 1.4rem;
  margin-top: 1rem;
}
.l-headerSp_subBack {
  width: 100%;
  height: 5rem;
  line-height: 4rem;
}
.l-headerSp_subBack--btn {
  font-size: 1.2rem;
}
.l-headerSp_sub--scroll {
  width: 100%;
  height: calc(100% - 18.5rem);
  overflow: scroll;
}
.l-headerSp_sub--scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
@media screen and (max-width: 767px) {
  .l-headerSp_sub--scroll::-webkit-scrollbar {
    width: 10px;
  }
}
.l-headerSp_sub--scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 0.5rem;
}
.l-headerSp_btn {
  padding: 3rem 0;
}
.l-headerSp_btn--box {
  width: 100%;
}
.l-headerSp_btn--box:last-child {
  margin-top: 1rem;
}
.l-headerSp_btn--box a {
  font-size: 1.4rem;
  width: 100%;
  height: 4rem;
  background-color: #D30F1B;
  line-height: 4rem;
  border-radius: 1rem;
  display: block;
}
.l-headerSp_btn--box a.mysite {
  background-color: #06009F;
}
.l-headerSp_overlay {
  width: calc(100% - 25rem);
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .l-headerSp_overlay {
    width: calc(100% - 26rem);
  }
}
.l-headerSp_overlay.is-view {
  display: block;
}
.l-headerSp_scroll {
  width: 100%;
  height: calc(100% - 9.65rem);
  overflow: scroll;
}
.l-headerSp_scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
@media screen and (max-width: 767px) {
  .l-headerSp_scroll::-webkit-scrollbar {
    width: 10px;
  }
}
.l-headerSp_scroll::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 0.5rem;
}

.l-footer {
  color: #000;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  line-height: normal;
}
.l-footer a {
  color: #000;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.6rem;
  text-decoration: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@media screen and (min-width: 768px) {
  .l-footer a {
    transition: opacity 0.25s ease;
  }
}
@media screen and (min-width: 768px) {
  .l-footer a:hover {
    opacity: 0.8;
  }
}
.l-footer p {
  line-height: normal;
}
.l-footerCont {
  padding: 9rem 0 7rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .l-footerCont {
    padding: 4rem 0;
  }
}
.l-footerCont_wrap {
  max-width: 134rem !important;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footerCont_wrap {
    display: block;
  }
}
.l-footerCont_left {
  max-width: 31rem;
  width: 27%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footerCont_left {
    max-width: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .l-footerCont_left {
    max-width: none;
    width: 100%;
  }
}
.l-footerCont_left--text {
  font-size: 1.4rem;
  margin-top: 3rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footerCont_left--text {
    margin: 0 0 0 5rem;
  }
}
@media screen and (max-width: 767px) {
  .l-footerCont_left--text {
    font-size: 1.2rem;
    margin-top: 2rem;
  }
}
.l-footerCont_left--company {
  line-height: 1.85 !important;
  font-weight: 500;
}
.l-footerCont_left--address {
  text-indent: -3em;
  padding-left: 3em;
  line-height: 1.85 !important;
  font-weight: 500;
}
.l-footerCont_right {
  max-width: 90rem;
  width: 72%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footerCont_right {
    max-width: 60rem;
    width: 100%;
    margin: 4rem auto 0;
  }
}
.l-footer_pagetop {
  position: fixed;
  bottom: 3rem;
  right: 12rem;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.4s;
  z-index: 10;
  pointer-events: none;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footer_pagetop {
    right: 6rem;
  }
}
@media screen and (max-width: 767px) {
  .l-footer_pagetop {
    bottom: 2rem;
    right: 2rem;
  }
}
.l-footer_pagetop.is-view {
  opacity: 1;
  pointer-events: auto;
}
.l-footer_pagetop.is-absolute {
  position: absolute;
  top: 0;
  bottom: auto;
}
.l-footer_pagetop--btn {
  width: 9.7rem;
  height: 9.7rem;
  border-radius: 1.4rem;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.26);
  display: block;
  position: relative;
  transform: rotate(-45deg);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footer_pagetop--btn {
    width: 7rem;
    height: 7rem;
    border-radius: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .l-footer_pagetop--btn {
    width: 6rem;
    height: 6rem;
    border-radius: 1rem;
  }
}
.l-footer_pagetop--btn:after {
  content: "";
  width: 2.5rem;
  height: 2.5rem;
  border: 0px;
  border-top: solid 0.3rem #fff;
  border-right: solid 0.3rem #fff;
  position: absolute;
  top: 55%;
  left: 45%;
  transform: translate(-55%, -45%);
}
@media screen and (max-width: 767px) {
  .l-footer_pagetop--btn:after {
    width: 1.6rem;
    height: 1.6rem;
    border-width: 0.2rem;
  }
}
.l-footer_logo {
  width: 92%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footer_logo {
    width: 29rem;
  }
}
@media screen and (max-width: 767px) {
  .l-footer_logo {
    width: 20rem;
  }
}
.l-footer_logo a {
  display: block;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footerNavi {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footerNavi_cont {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .l-footerNavi_cont {
    width: 100%;
    margin-top: 3rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footerNavi_cont:not(:nth-child(-n+2)) {
    margin-top: 3rem;
  }
}
.l-footerNavi_cont:not(:first-child) {
  margin-left: 3rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .l-footerNavi_cont:not(:first-child) {
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .l-footerNavi_cont:not(:first-child) {
    margin-top: 2rem;
  }
}
.l-footerNavi_ttl {
  font-size: 1.6rem;
}
.l-footerNavi_ttl:not(:first-of-type) {
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .l-footerNavi_ttl:not(:first-of-type) {
    margin-top: 1rem;
  }
}
.l-footerNavi_ttl a {
  font-size: 1.6rem;
}
@media screen and (max-width: 767px) {
  .l-footerNavi_ttl a {
    font-size: 1.4rem;
  }
}
.l-footerNavi_list {
  padding-left: 1.5rem;
}
@media screen and (max-width: 767px) {
  .l-footerNavi_list {
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
  }
}
.l-footerNavi_list--item {
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .l-footerNavi_list--item {
    width: 50%;
    margin-top: 1rem;
  }
}
.l-footerNavi_list--item a {
  font-size: 1.4rem;
  line-height: 1.5;
  font-weight: 500;
}
@media screen and (max-width: 767px) {
  .l-footerNavi_list--item a {
    font-size: 1.2rem;
  }
}
.l-footer_copyright {
  padding: 2rem;
}
@media screen and (max-width: 767px) {
  .l-footer_copyright {
    padding: 1.5rem;
  }
}
.l-footer_copyright--text {
  font-size: 1.2rem;
}
@media screen and (max-width: 767px) {
  .l-footer_copyright--text {
    font-size: 1rem;
  }
}

.l-wrap {
  max-width: 1220px;
  width: 100%;
  padding: 0 3rem;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .l-wrap {
    padding: 0 2rem;
  }
}
.l-wrap_over {
  min-height: 100%;
  position: relative;
  overflow: hidden;
  left: 0;
  transition: left 0.25s;
}
.l-wrap_over.is-active {
  left: -25rem;
}
@media screen and (min-width: 768px) {
  .l-wrap_over.is-active {
    left: -26rem;
  }
}
.l-wrap_area {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
.l-wrap_cont {
  max-width: 100rem;
  width: 100%;
  margin: 0 auto;
}
.l-wrap_990 {
  max-width: 99rem;
  width: 100%;
  margin: 0 auto;
}
.l-wrap_980 {
  max-width: 98rem;
  width: 100%;
  margin: 0 auto;
}
.l-wrap_960 {
  max-width: 96rem;
  width: 100%;
  margin: 0 auto;
}
.l-wrap_920 {
  max-width: 92rem;
  width: 100%;
  margin: 0 auto;
}
.l-wrap_850 {
  max-width: 85rem;
  width: 100%;
  margin: 0 auto;
}
.l-wrap_840 {
  max-width: 84rem;
  width: 100%;
  margin: 0 auto;
}
.c-color_text--white {
  color: #fff !important;
}
.c-color_text--red {
  color: #D30F1B !important;
}
.c-color_text--summerGreen {
  color: #3A6940;
}
.c-color_text--woodBrown {
  color: #69442D;
}
.c-color_text--darkBlue {
  color: #384194;
}
.c-color_text--rose {
  color: #CA5175;
}
.c-color_text--orange {
  color: #E8903F;
}
.c-color_bg--white {
  background-color: #fff;
}
.c-color_bg--offwhite {
  background-color: #F6F6F6;
}
.c-color_bg--perlWhite {
  background-color: #F7F7F7;
}
.c-color_bg--red {
  background-color: #D30F1B !important;
}
.c-color_bg--gray {
  background-color: #DDD;
}
.c-color_bg--darkgray {
  background-color: #404040;
}
.c-color_bg--lightgray {
  background-color: #F8F8F8;
}
.c-color_bg--brown {
  background-color: #3B0709;
}
.c-color_bg--green {
  background-color: #43964F;
}
.c-color_bg--greenBlue {
  background-color: #2E66AD;
}
.c-color_bg--silver {
  background-color: #E6E6E6;
}
.c-color_bg--quietRed {
  background-color: #CF7A87;
}
.c-color_bg--orange {
  background-color: #E8903F;
}
.c-color_bg--bronze {
  background-color: #A06834;
}

.c-flex {
  display: flex;
}
.c-flex_wrap {
  flex-wrap: wrap;
}
.c-flex_justify--bet {
  justify-content: space-between;
}
.c-flex_justify--center {
  justify-content: center;
}
.c-flex_justify--end {
  justify-content: flex-end;
}
.c-flex_justify--around {
  justify-content: space-around;
}
.c-flex_items--center {
  align-items: center;
}
.c-flex_items--start {
  align-items: flex-start;
}
.c-flex_items--end {
  align-items: flex-end;
}
.c-flex_direction--reverse {
  flex-direction: row-reverse;
}

.c-text_weight--normal {
  font-weight: 400;
}
.c-text_weight--medium {
  font-weight: 500;
}
.c-text_weight--bold {
  font-weight: 700;
}
.c-text_weight-black {
  font-weight: 900;
}
.c-text_size--default {
  font-size: 1.6rem;
  line-height: 1.875;
  letter-spacing: 0.1rem;
}
@media screen and (max-width: 767px) {
  .c-text_size--default {
    font-size: 1.4rem;
  }
}
.c-text_size--large {
  font-size: 2.5rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-text_size--large {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-text_size--large {
    font-size: 1.8rem;
  }
}
.c-text_size--40 {
  font-size: 4rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-text_size--40 {
    font-size: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-text_size--40 {
    font-size: 2rem;
  }
}
.c-text_size--32 {
  font-size: 3.2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-text_size--32 {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-text_size--32 {
    font-size: 2rem;
  }
}
.c-text_size--25 {
  font-size: 2.5rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-text_size--25 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-text_size--25 {
    font-size: 1.8rem;
  }
}
.c-text_size--24 {
  font-size: 2.4rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-text_size--24 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-text_size--24 {
    font-size: 1.8rem;
  }
}
.c-text_size--22 {
  font-size: 2.2rem;
}
@media screen and (max-width: 767px) {
  .c-text_size--22 {
    font-size: 1.8rem;
  }
}
.c-text_size--20 {
  font-size: 2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-text_size--20 {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .c-text_size--20 {
    font-size: 1.6rem;
  }
}
.c-text_size--18 {
  font-size: 1.8rem;
}
@media screen and (max-width: 767px) {
  .c-text_size--18 {
    font-size: 1.6rem;
  }
}
.c-text_size--16 {
  font-size: 1.6rem;
}
@media screen and (max-width: 767px) {
  .c-text_size--16 {
    font-size: 1.4rem;
  }
}
.c-text_size--14 {
  font-size: 1.4rem;
}
@media screen and (max-width: 767px) {
  .c-text_size--14 {
    font-size: 1.2rem;
  }
}
.c-text_align--center {
  text-align: center;
}
.c-text_align--right {
  text-align: right;
}
.c-text_align--left {
  text-align: left;
}
@media screen and (max-width: 767px) {
  .c-text_align--leftSp {
    text-align: left;
  }
}
.c-text_feature {
  font-feature-settings: "palt";
}
.c-text_space--1 {
  letter-spacing: 0.1rem;
}
.c-text_space--2 {
  letter-spacing: 0.2rem;
}
.c-text_space--3 {
  letter-spacing: 0.3rem;
}
.c-text_indent {
  text-indent: -1em;
  padding-left: 1em;
}

.c-btn_header {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
  width: 100%;
  height: 5rem;
  background-color: #D30F1B;
  border: 0.2rem solid #D30F1B;
  border-radius: 1rem;
  line-height: 4.6rem;
  text-align: center;
  display: block;
  box-shadow: 0.2rem 0.3rem 1.1rem rgba(0, 0, 0, 0.26);
}
@media screen and (min-width: 768px) {
  .c-btn_header {
    transition: 0.4s;
  }
}
@media screen and (max-width: 767px) {
  .c-btn_header {
    color: #D30F1B;
    font-size: 1.2rem;
    height: 100%;
    line-height: 1.4;
    background-color: #fff;
    border: 1px solid #D30F1B;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 767px) {
  .c-btn_header span {
    display: block;
  }
}
.c-btn_header.blue {
  background-color: #06009F;
  border-color: #06009F;
}
@media screen and (max-width: 767px) {
  .c-btn_header.blue {
    color: #fff;
    border-color: #06009F;
  }
}
@media screen and (max-width: 767px) {
  .c-btn_header.contact {
    padding-top: 2.7rem;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .c-btn_header.contact:before {
    content: "";
    width: 2.6rem;
    height: 1.8rem;
    margin: 0 auto;
    background: url("../img/common/ico_contact01.svg") no-repeat;
    background-size: contain;
    position: absolute;
    top: 1.4rem;
    right: 0;
    left: 0;
  }
}
@media screen and (min-width: 768px) {
  .c-btn_header:hover {
    opacity: 1;
    color: #D30F1B;
    background-color: #fff;
  }
}
@media screen and (min-width: 768px) {
  .c-btn_header:hover.blue {
    color: #06009F;
  }
}
.c-btn_contact {
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  width: 100%;
  height: 7rem;
  background-color: #D30F1B;
  line-height: 7rem;
  text-align: center;
  border-radius: 3.5rem;
  box-shadow: 2px 3px 20px rgba(0, 0, 0, 0.26);
  display: block;
}
@media screen and (max-width: 767px) {
  .c-btn_contact {
    font-size: 1.4rem;
    height: 5rem;
    line-height: 5rem;
  }
}
.c-btn_contact--tel {
  line-height: 1 !important;
  background-color: #fff;
  text-align: left;
  padding-left: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.c-btn_contact--tel:before {
  content: "";
  width: 12.5%;
  padding-bottom: 7.5%;
  background: url("../img/common/ico_customer_tel01.gif");
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 19%;
  transform: translateY(-50%);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-btn_contact--tel:before {
    left: 15%;
  }
}
.c-btn_contact--tel.pc {
  display: flex;
}
@media screen and (max-width: 767px) {
  .c-btn_contact--tel.pc {
    display: none;
  }
}
.c-btn_contact--tel.sp {
  display: flex;
}
@media screen and (min-width: 768px) {
  .c-btn_contact--tel.sp {
    display: none;
  }
}
.c-btn_contact--telWrap {
  text-align: center;
}
.c-btn_contact--telNum {
  color: #000;
  font-size: 2.4rem;
  letter-spacing: 0.2;
}
@media screen and (max-width: 767px) {
  .c-btn_contact--telNum {
    font-size: 1.8rem;
  }
}
.c-btn_contact--telTime {
  color: #000;
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 0.5rem;
  display: block;
}
@media screen and (max-width: 767px) {
  .c-btn_contact--telTime {
    font-size: 1rem;
    margin-top: 0.3rem;
  }
}
.c-btn_contact--tel.customer .c-btn_contact--telNum {
  color: #1C1C1C;
}
.c-btn_contact--inquiry {
  border: 0.4rem solid #D30F1B;
  line-height: 6.2rem;
  transition: 0.4s;
}
@media screen and (max-width: 767px) {
  .c-btn_contact--inquiry {
    border-width: 0.2rem;
    line-height: 4.6rem;
  }
}
@media screen and (min-width: 768px) {
  .c-btn_contact--inquiry:hover {
    color: #D30F1B;
    background-color: #fff;
    opacity: 1;
  }
}
.c-btn_hamburger {
  width: 100%;
  height: 100%;
  background-color: #D30F1B;
  border: 0.1rem solid #D30F1B;
  border-radius: 1rem;
  box-shadow: 0.2rem 0.3rem 1.1rem rgba(0, 0, 0, 0.26);
  position: relative;
  transition: background-color 0.4s;
}
.c-btn_hamburger span {
  width: 2.6rem;
  height: 0.1rem;
  margin: auto;
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: 0.4s;
}
.c-btn_hamburger span:first-child {
  transform: translateY(-0.8rem);
}
.c-btn_hamburger span:last-child {
  transform: translateY(0.8rem);
}
.c-btn_hamburger.is-active {
  background-color: #fff;
}
.c-btn_hamburger.is-active span {
  background-color: #D30F1B;
}
.c-btn_hamburger.is-active span:first-child {
  transform: translateY(0) rotate(-45deg);
}
.c-btn_hamburger.is-active span:nth-child(2) {
  opacity: 0;
}
.c-btn_hamburger.is-active span:last-child {
  transform: translateY(0) rotate(45deg);
}
.c-btn_cmn {
  color: #fff;
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 7rem;
  background-color: #D30F1B;
  border: 0.4rem solid #D30F1B;
  border-radius: 3.5rem;
  line-height: 6.2rem;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.26);
  display: block;
  position: relative;
}
@media screen and (min-width: 768px) {
  .c-btn_cmn {
    transition: color 0.25s ease, background-color 0.25s ease;
  }
}
@media screen and (max-width: 767px) {
  .c-btn_cmn {
    font-size: 1.4rem;
    height: 5rem;
    border-width: 0.2rem;
    line-height: 4.4rem;
    border-radius: 3rem;
    box-shadow: 0.1rem 0.15rem 1rem rgba(0, 0, 0, 0.26);
  }
}
.c-btn_cmn:after {
  content: "";
  width: 8px;
  height: 8px;
  border: 0px;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 50%;
  right: 10%;
  margin-top: -5px;
}
@media screen and (min-width: 768px) {
  .c-btn_cmn:after {
    transition: 0.4s;
  }
}
@media screen and (min-width: 768px) {
  .c-btn_cmn:hover {
    color: #D30F1B;
    background-color: #fff;
    opacity: 1;
  }
  .c-btn_cmn:hover:after {
    border-color: #D30F1B;
  }
}
.c-btn_cmn--accordion:after {
  transform: rotate(135deg);
}
.c-btn_cmn--accordion.is-active:after {
  transform: rotate(-45deg);
}
.c-btn_cmn--pdf:after {
  width: 1.7rem;
  height: 1.8rem;
  margin: auto 0;
  border: none;
  background: url("../img/common/ico_btn01.svg") no-repeat;
  background-size: contain;
  transform: rotate(0);
  right: 7.5%;
  top: 0;
  bottom: 0;
  transition: background-image 0.25s ease;
}
.c-btn_cmn--pdf:hover:after {
  background-image: url("../img/common/ico_btn01_r.svg");
}
.c-btn_cmn--16 {
  font-size: 1.6rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-btn_cmn--16 {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-btn_cmn--16 {
    font-size: 1.2rem;
  }
}
.c-btn_cmn.c-flex {
  display: flex;
  line-height: 1.375;
}
.c-btn_white {
  width: 100%;
  background-color: #fff;
  border-radius: 3.5rem;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.26);
  overflow: hidden;
  display: block;
  position: relative;
  transition: background-color 0.25s ease;
  cursor: pointer;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-btn_white {
    border-radius: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-btn_white {
    box-shadow: 0.1rem 0.15rem 1rem rgba(0, 0, 0, 0.26);
    border-radius: 2.5rem;
  }
}
.c-btn_white:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 3.5rem;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .c-btn_white:before {
    transition: transform 0.25s ease;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-btn_white:before {
    border-radius: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-btn_white:before {
    border-radius: 2.5rem;
  }
}
.c-btn_white:after {
  content: "";
  width: 1.3rem;
  height: 0.8rem;
  margin: auto 0;
  background: url("../img/common/arrow_index01.svg") no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8.7%;
}
@media screen and (min-width: 768px) {
  .c-btn_white:after {
    transition: background-image 0.25s ease;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-btn_white:after {
    width: 0.9rem;
    height: 0.6rem;
    right: 5.5%;
  }
}
@media screen and (max-width: 767px) {
  .c-btn_white:after {
    width: 0.7rem;
    height: 0.4rem;
    right: 1rem;
  }
}
.c-btn_white--wrap {
  width: 100%;
  height: 7rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-btn_white--wrap {
    height: 6rem;
    padding-right: 2%;
  }
}
@media screen and (max-width: 767px) {
  .c-btn_white--wrap {
    height: 5rem;
  }
}
.c-btn_white--text {
  font-size: 1.6rem;
  line-height: 1.375;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .c-btn_white--text {
    transition: color 0.25s ease;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-btn_white--text {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-btn_white--text {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 768px) {
  .c-btn_white:hover {
    background-color: #D30F1B;
    opacity: 1;
  }
}
@media screen and (min-width: 768px) {
  .c-btn_white:hover:before {
    transform: scale(0);
  }
}
@media screen and (min-width: 768px) {
  .c-btn_white:hover:after {
    background-image: url("../img/common/arrow_index01_w.svg");
  }
}
@media screen and (min-width: 768px) {
  .c-btn_white:hover .c-btn_white--text {
    color: #fff;
  }
}
.c-btn_white--noArrow:after {
  display: none;
}
.c-btn_white--noArrow .c-btn_white--wrap {
  padding: 0;
}
.c-btn_white--link:after {
  transform: rotate(-90deg);
}
.c-btn_search {
  width: 100%;
  height: 7rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .c-btn_search {
    font-size: 1.4rem;
    height: 5rem;
    padding-right: 0.5rem;
  }
}
.c-btn_search:after {
  content: "";
  width: 1.3rem;
  height: 0.8rem;
  margin: auto 0;
  background: url("../img/common/arrow_index01.svg") no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 7%;
  transition: transform 0.25s ease;
}
@media screen and (max-width: 767px) {
  .c-btn_search:after {
    width: 0.9rem;
    height: 0.6rem;
  }
}
.c-btn_search.is-active:after {
  transform: rotate(-180deg);
}
.c-btn_search--sub {
  font-size: 1.6rem;
  width: 100%;
  height: 6rem;
  transition: background-color 0.25s ease;
  border-bottom: 0.2rem solid #DDD;
}
@media screen and (max-width: 767px) {
  .c-btn_search--sub {
    font-size: 1.3rem;
    height: 4rem;
  }
}
.c-btn_search--sub:hover {
  background-color: #E6E6E6;
}
.c-btn_search--sub.is-close {
  background-color: #F7F7F7;
}
.c-btn_search--sub.is-active {
  background-color: #E6E6E6;
}
.c-btn_search--sub.js-facility-accordion-trigger {
  position: relative;
}
.c-btn_search--sub.js-facility-accordion-trigger:after {
  content: "";
  width: 1.3rem;
  height: 0.8rem;
  margin: auto 0;
  background: url(../img/common/arrow_index01.svg) no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  right: 7%;
  top: 0;
  transition: transform 0.25s ease;
}
@media screen and (max-width: 767px) {
  .c-btn_search--sub.js-facility-accordion-trigger:after {
    height: 0.6rem;
    width: 0.9rem;
  }
}
.c-btn_search--sub.js-facility-accordion-trigger.is-active:after {
  transform: rotate(-180deg);
}

.c-modal_overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  z-index: 1000;
  cursor: pointer;
}
.c-modal_overlay.is-view {
  display: block;
}
.c-modalCont {
  max-width: 83rem;
  width: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  display: none;
}
@media screen and (max-width: 767px) {
  .c-modalCont {
    width: 100%;
    padding: 0 2rem;
  }
}
.c-modalCont.is-view {
  display: block;
}
.c-modal_movie {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.c-modal_movie iframe, .c-modal_movie video {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  cursor: pointer;
}
.c-modal_close {
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: -7rem;
  right: 0;
}
@media screen and (max-width: 767px) {
  .c-modal_close {
    width: 4rem;
    height: 4rem;
    top: -5rem;
    right: 2rem;
  }
}
.c-modal_close span {
  width: 100%;
  height: 0.2rem;
  background-color: #404040;
  display: block;
}
.c-modal_close span:first-child {
  transform: rotate(-45deg);
}
.c-modal_close span:last-child {
  transform: rotate(45deg);
}

.c-scroll_fadein {
  opacity: 0;
  transform: translateY(5rem);
  transition: 0.4s;
}
.c-scroll_fadein.is-animated {
  opacity: 1;
  transform: translateY(0);
}
.c-scroll_delay--200 {
  transition-delay: 200ms;
}
.c-scroll_delay--400 {
  transition-delay: 400ms;
}
.c-scroll_delay--600 {
  transition-delay: 600ms;
}
.c-scroll_enter.is-animated .c-scroll_fadein {
  opacity: 1;
  transform: translateY(0);
}

.c-kv_inner {
  padding: 8.5rem 0;
  background-size: cover;
  background-position: center center;
}
@media screen and (min-width: 1401px) {
  .c-kv_inner {
    padding: 6% 0;
  }
}
@media screen and (max-width: 767px) {
  .c-kv_inner {
    padding: 5rem 0;
  }
}
.c-kvSub_inner {
  padding: 21rem 0 6rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-kvSub_inner {
    padding: 18rem 0 6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-kvSub_inner {
    padding: 12rem 0 5rem;
  }
}
.c-kvSub_ttl {
  font-size: 6rem;
  letter-spacing: 0.5rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-kvSub_ttl {
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-kvSub_ttl {
    font-size: 3rem;
    letter-spacing: 0.3rem;
  }
}
.c-kvSub_ttl--sub {
  font-size: 2rem;
  margin-top: 1rem;
  letter-spacing: 0.2rem;
}
@media screen and (max-width: 767px) {
  .c-kvSub_ttl--sub {
    font-size: 1.6rem;
  }
}
.c-kvSub.medicell .c-kvSub_inner {
  background: url("../img/medicell/kv_bg01.jpg") no-repeat center;
  background-size: cover;
}
.c-kvSub.product .c-kvSub_inner {
  background: url("../img/product/kv_bg01.jpg") no-repeat center;
  background-size: cover;
}
.c-kvSub.hybrid .c-kvSub_inner {
  background: url("../img/product/hybrid/kv_bg01.jpg") no-repeat center;
  background-size: cover;
}
.c-kvSub.question .c-kvSub_inner {
  background: url("../img/product/question/kv_bg01.jpg") no-repeat center;
  background-size: cover;
}
.c-kvSub.career .c-kvSub_inner {
  background: url("../img/product/career/kv_bg01.jpg") no-repeat center;
  background-size: cover;
}
.c-kvSub.facility .c-kvSub_inner {
  background: url("../img/facility/kv_bg01.jpg") no-repeat center;
  background-size: cover;
}
.c-kvSub.company .c-kvSub_inner {
  background: url("../img/company/kv_bg01.jpg") no-repeat center;
  background-size: cover;
}
.c-kvSub.recruit .c-kvSub_inner {
  background: url("../img/recruit/kv_bg01.jpg") no-repeat center;
  background-size: cover;
}
.c-kvSub.contributions .c-kvSub_inner {
  background: url("../img/company/contributions/kv_bg01.jpg") no-repeat center;
  background-size: cover;
}
.c-kvSub.sdgs .c-kvSub_inner {
  background: url("../img/company/sdgs/kv_bg01.jpg") no-repeat 0%;
  background-size: cover;
}
.c-kvSub.news .c-kvSub_inner {
  background: url("../img/news/kv_bg01.jpg") no-repeat center;
  background-size: cover;
}
.c-kvSub.mysite .c-kvSub_inner {
  width: 100%;
  height: 39rem;
  padding: 0;
  background: url("../img/mysite-form/kv_bg01.jpg") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-kvSub.mysite .c-kvSub_inner {
    height: 34rem;
  }
}
@media screen and (max-width: 767px) {
  .c-kvSub.mysite .c-kvSub_inner {
    height: 24.3rem;
  }
}
.c-kvSub.mysite .c-kvSub_logo {
  max-width: 45rem;
  width: 100%;
  margin: 0 auto;
  transform: translateX(-1rem);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-kvSub.mysite .c-kvSub_logo {
    max-width: 38rem;
  }
}
@media screen and (max-width: 767px) {
  .c-kvSub.mysite .c-kvSub_logo {
    max-width: 25rem;
  }
}
.c-kvSub.notFound .c-kvSub_inner {
  background: #c0c0c0;
}
.c-kvScene {
  width: 100%;
  height: 60rem;
  background: url("../img/treatment/kv_bg01.jpg") no-repeat;
  background-position: 50% 35%;
  background-size: cover;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-kvScene {
    height: 40rem;
  }
}
@media screen and (max-width: 767px) {
  .c-kvScene {
    height: 30rem;
  }
}
.c-kvScene_wrap {
  width: 100%;
  height: 100%;
  position: relative;
}
.c-kvScene_ttl {
  width: 86%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -10%);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-kvScene_ttl {
    width: 60%;
  }
}
@media screen and (max-width: 767px) {
  .c-kvScene_ttl {
    width: 30rem;
  }
}
.c-kvScene.sports {
  background-image: url("../img/sports/kv_bg01.jpg");
  background-position: center center;
}
.c-kvScene.sports .c-kvScene_ttl {
  width: 80.85%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-kvScene.sports .c-kvScene_ttl {
    width: 56.6%;
  }
}
@media screen and (max-width: 767px) {
  .c-kvScene.sports .c-kvScene_ttl {
    width: 28.3rem;
  }
}
.c-kvScene.kinmakubiryo {
  background-image: url("../img/kinmakubiryo/kv_bg01.jpg");
  background-position: center center;
}
.c-kvScene.kinmakubiryo .c-kvScene_ttl {
  width: 28.8%;
  transform: translate(-50%, -30%);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-kvScene.kinmakubiryo .c-kvScene_ttl {
    width: 22.2%;
    transform: translate(-50%, -20%);
  }
}
@media screen and (max-width: 767px) {
  .c-kvScene.kinmakubiryo .c-kvScene_ttl {
    width: 15.1rem;
  }
}
.c-kvScene.relaxation {
  background-image: url("../img/relaxation/kv_bg01.jpg");
  background-position: 75% center;
}
.c-kvScene.relaxation .c-kvScene_ttl {
  width: 91.6%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-kvScene.relaxation .c-kvScene_ttl {
    width: 64.1%;
  }
}
@media screen and (max-width: 767px) {
  .c-kvScene.relaxation .c-kvScene_ttl {
    width: 32.1rem;
  }
}
.c-kvScene.pets {
  background-image: url("../img/pets/kv_bg01.jpg");
  background-position: center center;
}
.c-kvScene.pets .c-kvScene_ttl {
  width: 40.5%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-kvScene.pets .c-kvScene_ttl {
    width: 28.4%;
  }
}
@media screen and (max-width: 767px) {
  .c-kvScene.pets .c-kvScene_ttl {
    width: 18.2rem;
  }
}
.c-kvScene.racehorces {
  background-image: url("../img/racehorces/kv_bg01.jpg");
  background-position: center center;
}
.c-kvScene.racehorces .c-kvScene_ttl {
  width: 97.2%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-kvScene.racehorces .c-kvScene_ttl {
    width: 68%;
  }
}
@media screen and (max-width: 767px) {
  .c-kvScene.racehorces .c-kvScene_ttl {
    width: 34rem;
  }
}

.c-listNews_item {
  border-bottom: 0.1rem solid #D30F1B;
}
.c-listNews_item a {
  padding: 1.5rem 0;
  display: block;
}
@media screen and (max-width: 767px) {
  .c-listNews_item a {
    padding: 1rem 0;
  }
}
@media screen and (max-width: 900px) {
  .c-listNews_item--wrap {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.c-listNews_item--date {
  vertical-align: middle;
}
@media screen and (max-width: 900px) {
  .c-listNews_item--date {
    margin-right: 2rem;
  }
}
.c-listNews_item--date span {
  font-size: 1.4rem;
  margin-left: 2rem;
}
@media screen and (max-width: 767px) {
  .c-listNews_item--date span {
    margin-left: 1rem;
  }
}
.c-listNews_item--cate {
  font-size: 1.2rem;
  width: 12rem;
  height: 2.6rem;
  line-height: 2.6rem;
  display: block;
}
@media screen and (max-width: 767px) {
  .c-listNews_item--cate {
    font-size: 1rem;
    width: 10rem;
    height: 2rem;
    line-height: 2rem;
  }
}
.c-listNews_item--cate.news {
  background-color: #ED1B24;
}
.c-listNews_item--cate.seminar {
  background-color: #FFA719;
}
.c-listNews_item--cate.release {
  background-color: #389BFF;
}
.c-listNews_item--ttl {
  width: calc(100% - 26rem);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@media screen and (max-width: 900px) {
  .c-listNews_item--ttl {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-listNews_item--ttl {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .c-listNews_item--ttl {
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-list_column02 {
    display: block;
  }
}
.c-list_column02--item {
  max-width: 47.8rem;
  width: 49%;
}
@media screen and (max-width: 767px) {
  .c-list_column02--item {
    width: 100%;
  }
}
.c-list_column02--item:not(:nth-child(-n+2)) {
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .c-list_column02--item:not(:first-child) {
    margin-top: 1rem;
  }
}
.c-list_column03 {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .c-list_column03 {
    display: block;
  }
}
.c-list_column03--item {
  max-width: 30.7rem;
  width: 30.6666%;
}
@media screen and (max-width: 767px) {
  .c-list_column03--item {
    max-width: none;
    width: 100%;
  }
}
.c-list_column03--item:not(:nth-child(3n)) {
  margin-right: 4%;
}
@media screen and (max-width: 767px) {
  .c-list_column03--item:not(:nth-child(3n)) {
    margin-right: 0;
  }
}
.c-list_column03--item:not(:nth-child(-n+3)) {
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .c-list_column03--item:not(:first-child) {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .c-list_research--item {
    display: block;
  }
}
.c-list_research--item:not(:first-child) {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .c-list_research--item:not(:first-child) {
    margin-top: 2rem;
  }
}
.c-list_research--ttl {
  font-size: 2rem;
  width: 15rem;
  display: block;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-list_research--ttl {
    font-size: 1.7rem;
    width: 12rem;
  }
}
@media screen and (max-width: 767px) {
  .c-list_research--ttl {
    font-size: 1.5rem;
    width: auto;
  }
}
.c-list_research--box {
  width: calc(100% - 15rem);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-list_research--box {
    width: calc(100% - 12rem);
  }
}
@media screen and (max-width: 767px) {
  .c-list_research--box {
    width: auto;
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-list_localBtn {
    justify-content: space-between;
  }
}
.c-list_localBtn--item {
  width: 23%;
}
@media screen and (max-width: 767px) {
  .c-list_localBtn--item {
    width: 48%;
  }
}
.c-list_localBtn--item:not(:nth-child(4n)) {
  margin-right: 2.5%;
}
@media screen and (max-width: 767px) {
  .c-list_localBtn--item:not(:nth-child(4n)) {
    margin-right: 0;
  }
}
.c-list_localBtn--item:not(:nth-child(-n+4)) {
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .c-list_localBtn--item:not(:nth-child(-n+2)) {
    margin-top: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-list_option {
    display: block;
  }
}
.c-list_option--item {
  margin-right: 3.2%;
}
.c-list_option--item:not(:nth-child(-n+3)) {
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .c-list_option--item:not(:first-child) {
    margin: 2rem 0 0;
  }
}
.c-list_option--itemSmall {
  width: 31.2%;
}
@media screen and (max-width: 767px) {
  .c-list_option--itemSmall {
    width: 100%;
  }
}
@media screen and (min-width: 901px) {
  .c-list_option--itemSmall .c-boxOption {
    min-height: 58rem;
  }
}
.c-list_option--itemLarge {
  width: 48.4%;
}
@media screen and (max-width: 767px) {
  .c-list_option--itemLarge {
    width: 100%;
  }
}
.c-list_option--itemRight {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .c-listVoice_item {
    display: block;
  }
}
.c-listVoice_person {
  width: 18%;
}
@media screen and (max-width: 767px) {
  .c-listVoice_person {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(2rem);
  }
}
.c-listVoice_person--pic {
  border-radius: 50%;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .c-listVoice_person--pic {
    width: 8rem;
    margin-right: 2rem;
  }
}
.c-listVoice_person--name {
  font-size: 2rem;
  margin-top: 2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-listVoice_person--name {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-listVoice_person--name {
    font-size: 1.6rem;
    margin-top: 0;
  }
}
.c-listVoice_comment {
  width: 73%;
  margin-top: 2.5rem;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-listVoice_comment {
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-listVoice_comment {
    width: 100%;
  }
}
.c-listVoice_comment:before {
  content: "";
  width: 4.5rem;
  height: 4.5rem;
  background-color: #fff;
  box-shadow: 0 0.3rem 2rem rgba(0, 0, 0, 0.26);
  margin: auto 0;
  transform: rotate(-45deg) skew(30deg, 30deg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: -2rem;
  z-index: 1;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-listVoice_comment:before {
    width: 3rem;
    height: 3rem;
    left: -1rem;
  }
}
@media screen and (max-width: 767px) {
  .c-listVoice_comment:before {
    width: 4rem;
    height: 4rem;
    box-shadow: 0 0.15rem 1rem rgba(0, 0, 0, 0.26);
    transform: rotate(-5deg) skew(30deg, 30deg);
    top: auto;
    left: 2rem;
    bottom: -1.1rem;
  }
}
.c-listVoice_comment--inner {
  min-height: 13.5rem;
  padding: 4rem 5.3%;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-listVoice_comment--inner {
    min-height: 12rem;
    padding: 2rem 5.3%;
  }
}
@media screen and (max-width: 767px) {
  .c-listVoice_comment--inner {
    min-height: 0;
    padding: 2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-listVoice_comment--text {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-listVoice_comment--text {
    font-size: 1.3rem;
  }
}
.c-listVoice_item:not(:first-child) {
  margin-top: 3rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-listVoice_item:nth-child(even) .c-listVoice_comment {
    margin-top: 0;
  }
}
.c-listVoice_item:nth-child(even) .c-listVoice_comment:before {
  left: auto;
  right: -2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-listVoice_item:nth-child(even) .c-listVoice_comment:before {
    right: -1rem;
  }
}
@media screen and (max-width: 767px) {
  .c-listVoice_item:nth-child(even) .c-listVoice_comment:before {
    left: auto;
    right: 2rem;
    transform: rotate(95deg) skew(30deg, 30deg);
  }
}
@media screen and (max-width: 767px) {
  .c-listVoice_item:nth-child(even) .c-listVoice_person {
    transform: translateX(-2rem);
  }
}
@media screen and (max-width: 767px) {
  .c-listTool {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-listTool_item .c-text_size--default {
    font-size: 1.4rem;
  }
}
.c-listTool_ttl {
  position: relative;
}
.c-listTool_ttl .ico-new {
  display: inline-block;
  background-color: #D30F1B;
  margin-right: 0.75em;
  padding: 0.15em 0.75em;
  font-size: 0.8em;
  line-height: 1;
  color: #fff;
}
.c-listTool_column02--item {
  max-width: 41.3rem;
  width: 46%;
}
@media screen and (max-width: 767px) {
  .c-listTool_column02--item {
    max-width: none;
    width: 100%;
  }
}
.c-listTool_column02--item:not(:nth-child(-n+2)) {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .c-listTool_column02--item:not(:first-child) {
    margin-top: 3rem;
  }
}
.c-listTool_column02--pic {
  width: 87.4%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .c-listTool_column02--pic {
    width: 100%;
  }
}
.c-listTool_column03--item {
  max-width: 28rem;
  width: 30.4%;
}
@media screen and (max-width: 767px) {
  .c-listTool_column03--item {
    max-width: none;
    width: 100%;
  }
}
.c-listTool_column03--item:not(:nth-child(-n+3)) {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .c-listTool_column03--item:not(:first-child) {
    margin-top: 3rem;
  }
}
.c-listTool_price span {
  font-size: 2rem;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .c-listTool_price span {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .c-list_btn--item {
    width: 100%;
  }
}
.c-list_btn--btn {
  max-width: 40rem;
  width: 100%;
  margin: 1.5rem auto 0;
}
@media screen and (max-width: 767px) {
  .c-list_btn--btn {
    max-width: none;
  }
}
@media screen and (max-width: 767px) {
  .c-list_facility {
    justify-content: space-between;
  }
}
.c-list_facility--item {
  width: 31.2%;
}
@media screen and (max-width: 767px) {
  .c-list_facility--item {
    width: 100%;
  }
}
.c-list_facility--item:not(:nth-child(3n)) {
  margin-right: 3.2%;
}
@media screen and (max-width: 767px) {
  .c-list_facility--item:not(:nth-child(3n)) {
    margin-right: 0;
  }
}
.c-list_facility--item:not(:nth-child(-n+3)) {
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .c-list_facility--item:not(:first-child) {
    margin-top: 2rem;
  }
}
.c-list_num--item {
  padding-left: 2.5rem;
  line-height: 1.666;
  position: relative;
}
@media screen and (max-width: 767px) {
  .c-list_num--item {
    padding-left: 2rem;
  }
}
.c-list_num--item:not(:first-child) {
  margin-top: 2rem;
}
.c-list_num--item span {
  position: absolute;
  top: 0.35rem;
  left: 0;
  line-height: 1;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-list_num--item span {
    top: 0.3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-list_num--item span {
    font-size: 2rem;
    top: 0.5rem;
  }
}
.c-list_num--item a {
  text-decoration: underline;
}
@media screen and (max-width: 767px) {
  .c-list_num--item h4 {
    font-size: 1.8rem;
  }
}
.c-list_seminar {
  margin-top: 2rem;
}
.c-list_seminar--item:not(:first-child) {
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .c-list_seminar--item:not(:first-child) {
    margin-top: 1rem;
  }
}
.c-list_seminar--item span {
  width: 6rem;
  display: block;
}
@media screen and (max-width: 767px) {
  .c-list_seminar--item span {
    width: 5rem;
  }
}
.c-list_seminar--item span.long {
  width: 9rem;
}
.c-list_seminar--text {
  width: calc(100% - 6rem);
}
.c-list_seminar--text.long {
  width: calc(100% - 9rem);
}
.c-list_seminar--item.full {
  flex-direction: column;
}
.c-list_seminar--item.full span {
  width: 100%;
}
.c-list_seminar--item.full p {
  width: 100%;
}

.c-box_white {
  padding: 8rem 5% 8rem;
  background-color: #fff;
  box-shadow: 0 0.3rem 2rem rgba(0, 0, 0, 0.26);
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .c-box_white {
    padding: 4rem 2rem 4rem;
    box-shadow: 0 0.15rem 1rem rgba(0, 0, 0, 0.26);
  }
}
.c-box_white:not(:first-child) {
  margin-top: 9rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-box_white:not(:first-child) {
    margin-top: 6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-box_white:not(:first-child) {
    margin-top: 4rem;
  }
}
.c-box_shadow {
  box-shadow: 0 0.3rem 2rem rgba(0, 0, 0, 0.26);
}
@media screen and (max-width: 767px) {
  .c-box_shadow {
    box-shadow: 0 0.15rem 1rem rgba(0, 0, 0, 0.26);
  }
}
.c-boxNum {
  padding: 4rem;
  background-color: #fff;
  box-shadow: 0 0.3rem 2rem rgba(0, 0, 0, 0.26);
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxNum {
    padding: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxNum {
    padding: 2rem;
    box-shadow: 0 0.15rem 1rem rgba(0, 0, 0, 0.26);
  }
}
.c-boxNum:not(:first-child) {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .c-boxNum:not(:first-child) {
    margin-top: 2rem;
  }
}
.c-boxNum:before {
  content: "";
  width: 6.5rem;
  height: 6.5rem;
  background: url("../img/common/box_num_bg01.svg") no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 767px) {
  .c-boxNum:before {
    width: 4rem;
    height: 4rem;
  }
}
.c-boxNum_num {
  font-size: 7.8rem;
  position: absolute;
  top: 2.5rem;
  left: 3rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxNum_num {
    font-size: 6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxNum_num {
    font-size: 3.4rem;
    top: 2rem;
    left: 2rem;
  }
}
.c-boxNum_ttl {
  padding-left: 6rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxNum_ttl {
    padding-left: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxNum_ttl {
    padding-left: 3rem;
  }
}
.c-boxNum_ttl span {
  font-size: 2.5rem !important;
  padding: 0 1rem 1.5rem !important;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxNum_ttl span {
    font-size: 2.2rem !important;
  }
}
@media screen and (max-width: 767px) {
  .c-boxNum_ttl span {
    font-size: 1.6rem !important;
    font-feature-settings: "palt";
    padding: 0 0.5rem 1rem !important;
  }
}
.c-boxNum_text {
  padding-left: 6rem;
  margin-top: 2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxNum_text {
    padding-left: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxNum_text {
    margin-top: 1.5rem;
    padding: 0;
  }
}
.c-boxNum_curriculum {
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxNum_curriculum {
    margin-top: 1.5rem;
    padding: 0;
  }
}
.c-boxNum_curriculum--box:not(:first-child) {
  margin-top: 3.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxNum_curriculum--box:not(:first-child) {
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 900px) {
  .c-boxNum_curriculumHeading {
    display: block;
  }
}
.c-boxNum_curriculumHeading--ico {
  font-size: 1.8rem;
  height: 3.5rem;
  padding: 0 1rem;
  margin-right: 3.5rem;
  line-height: 3.5rem;
  letter-spacing: 0.2rem;
  display: inline-block;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxNum_curriculumHeading--ico {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxNum_curriculumHeading--ico {
    font-size: 1.2rem;
    height: 2.5rem;
    line-height: 2.5rem;
  }
}
.c-boxNum_curriculumHeading--ico:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 17.5px 0 17.5px 18px;
  border-color: transparent transparent transparent #D30F1B;
  position: absolute;
  top: 0;
  left: 100%;
}
@media screen and (max-width: 767px) {
  .c-boxNum_curriculumHeading--ico:after {
    border-width: 12.5px 0 12.5px 9px;
  }
}
.c-boxNum_curriculumHeading--ttl {
  font-size: 2rem;
  letter-spacing: 0.1rem;
  margin-top: 0.2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxNum_curriculumHeading--ttl {
    font-size: 1.8rem;
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxNum_curriculumHeading--ttl {
    font-size: 1.4rem;
    margin-top: 0.5rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxNum_curriculumHeading.teacher {
    display: flex;
  }
}
.c-boxNum_curriculumHeading.teacher .c-boxNum_curriculumHeading--ico {
  font-size: 1.7rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxNum_curriculumHeading.teacher .c-boxNum_curriculumHeading--ico {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxNum_curriculumHeading.teacher .c-boxNum_curriculumHeading--ico {
    font-size: 1.2rem;
  }
}
.c-boxNum_curriculum--text {
  margin-top: 1rem;
}
@media screen and (max-width: 767px) {
  .c-boxNum_curriculum--text {
    font-size: 1.3rem;
  }
}
.c-boxSociety {
  padding: 3rem 4rem 4rem;
}
@media screen and (max-width: 767px) {
  .c-boxSociety {
    padding: 2rem 2.5rem 2.5rem;
  }
}
.c-boxSociety_date {
  font-size: 2rem;
  letter-spacing: 0.2rem;
  line-height: 1.6;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxSociety_date {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSociety_date {
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
  }
}
.c-boxSociety_box:not(:first-of-type) {
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .c-boxSociety_box:not(:first-of-type) {
    margin-top: 1rem;
  }
}
.c-boxSociety_ttl {
  margin-top: 1rem;
}
@media screen and (max-width: 767px) {
  .c-boxSociety_ttl {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSociety_text {
    font-size: 1.3rem;
  }
}
.c-boxSpec {
  padding: 4rem 6%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxSpec {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSpec {
    font-size: 1.2rem;
    padding: 2.5rem 2rem;
    display: block;
  }
}
.c-boxSpec_wrap {
  width: 47%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxSpec_wrap {
    width: 48%;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSpec_wrap {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSpec_wrap:not(:first-child) {
    margin-top: 1rem;
  }
}
.c-boxSpec_box:not(:first-child) {
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .c-boxSpec_box:not(:first-child) {
    margin-top: 1rem;
  }
}
.c-boxSpec_list {
  text-indent: -2em;
  padding-left: 2em;
}
@media screen and (max-width: 767px) {
  .c-boxSpec_list {
    text-indent: -1em;
    padding-left: 1em;
  }
}
.c-boxAttention {
  padding: 4rem 6%;
  border: 2px solid #404040;
}
@media screen and (max-width: 767px) {
  .c-boxAttention {
    font-size: 1.2rem;
  }
}
.c-boxAttention_title span {
  font-size: 2.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxAttention_title span {
    font-size: 1.5rem;
  }
}
.c-boxAttention_stitle {
  font-size: 2.2rem;
}
@media screen and (max-width: 767px) {
  .c-boxAttention_stitle {
    font-size: 1.5rem;
  }
}
.c-boxAttention_box {
  padding: 3rem 4%;
  border: 1px solid #404040;
}
.c-boxAttention_list {
  text-indent: -1em;
  padding-left: 1em;
}
.c-boxOption_inner {
  padding: 4rem 0 3.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxOption_inner {
    padding: 2rem 0;
  }
}
.c-boxOption_ttl {
  font-size: 2.4rem;
  position: relative;
}
@media screen and (max-width: 900px) {
  .c-boxOption_ttl {
    font-size: 1.8rem;
  }
}
.c-boxOption_ttl span {
  font-size: 1.6rem;
  width: 100%;
  text-align: center;
  position: absolute;
  top: -2.5rem;
  display: block;
}
@media screen and (max-width: 900px) {
  .c-boxOption_ttl span {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOption_ttl span {
    position: static;
  }
}
.c-boxOption_pic {
  margin-top: 1.5rem;
  padding: 0 4rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOption_pic {
    padding: 0 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOption_pic {
    padding: 0 3rem;
  }
}
.c-boxOption_patent {
  margin-top: 1.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxOption_patent {
    margin-top: 1rem;
  }
}
.c-boxOption_patent--inner {
  padding: 1rem 1.5rem;
  border: 0.1rem solid #D30F1B;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .c-boxOption_patent--inner {
    padding: 0.5rem 1rem;
  }
}
.c-boxOption_patent--text {
  font-size: 1.4rem;
  line-height: 1.7;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOption_patent--text {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOption_patent--text {
    font-size: 1.2rem;
  }
}
.c-boxOption_price {
  font-size: 1.6rem;
  letter-spacing: 0.2rem;
  margin-top: 1.5rem;
  line-height: 1.8;
}
@media screen and (max-width: 900px) {
  .c-boxOption_price {
    font-size: 1.4rem;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOption_price {
    letter-spacing: 0rem;
  }
}
.c-boxOption_price span {
  font-size: 2rem;
}
@media screen and (max-width: 900px) {
  .c-boxOption_price span {
    font-size: 1.8rem;
  }
}
.c-boxOption_capacity {
  font-size: 1.4rem;
  margin-top: 1.5rem;
  line-height: 1.85;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOption_capacity {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOption_capacity {
    font-size: 1rem;
  }
}
.c-boxOption_text {
  margin-top: 1.5rem;
  padding: 0 4rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOption_text {
    font-size: 1.4rem;
    padding: 0 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOption_text {
    font-size: 1.2rem;
    padding: 0 3rem;
    margin-top: 1rem;
  }
}
.c-boxOption_text span {
  font-size: 2rem;
  font-weight: bold;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOption_text span {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOption_text span {
    font-size: 1.6rem;
  }
}
.c-boxOptionBag {
  padding: 4rem 3.5rem 6rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOptionBag {
    padding: 3rem 2.5rem 4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag {
    padding: 3rem 2rem;
  }
}
.c-boxOptionBag_ttl {
  font-size: 2.4rem;
  letter-spacing: 0.1rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOptionBag_ttl {
    font-size: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_ttl {
    font-size: 1.8rem;
  }
}
.c-boxOptionBag_wrap {
  margin-top: 1.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_wrap {
    display: block;
  }
}
.c-boxOptionBag_left {
  width: 50%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOptionBag_left {
    width: 52%;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_left {
    width: 100%;
  }
}
.c-boxOptionBag_right {
  width: 45%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOptionBag_right {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_right {
    font-size: 1.4rem;
    width: 100%;
    margin-top: 2.5rem;
  }
}
.c-boxOptionBag_list {
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_list {
    margin-top: 1.5rem;
    flex-wrap: wrap;
  }
}
.c-boxOptionBag_list--item {
  width: 30%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOptionBag_list--item {
    width: 32%;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_list--item {
    width: 48%;
  }
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_list--item:not(:nth-child(-n+2)) {
    margin-top: 1rem;
  }
}
.c-boxOptionBag_list--text {
  font-size: 1.4rem;
  line-height: 1.85;
  margin-top: 0.5rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOptionBag_list--text {
    font-size: 1.1rem;
    font-feature-settings: "palt";
  }
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_list--text {
    font-size: 1rem;
    font-feature-settings: "palt";
  }
}
.c-boxOptionBag_limited {
  font-weight: bold;
  color: #D30F1B;
}
.c-boxOptionBag_price {
  margin-top: 1.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_price {
    margin-top: 1rem;
  }
}
.c-boxOptionBag_price span {
  font-size: 2rem;
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_price span {
    font-size: 1.8rem;
  }
}
.c-boxOptionBag_text {
  margin-top: 1.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_text {
    margin-top: 1rem;
  }
}
.c-boxOptionBag_text a {
  color: #ED1B24;
  text-decoration: underline;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxOptionBag_text a {
    font-size: 1.4rem;
  }
}
.c-boxOptionBag_spec {
  margin-top: 1.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxOptionBag_spec {
    margin-top: 1rem;
  }
}
.c-box_default {
  padding: 4rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-box_default {
    padding: 4rem 3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-box_default {
    padding: 3rem 2rem;
  }
}
.c-boxFaq {
  padding: 0 3rem;
}
@media screen and (max-width: 767px) {
  .c-boxFaq {
    padding: 0 2rem;
  }
}
.c-boxFaq:not(:first-child) {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .c-boxFaq:not(:first-child) {
    margin-top: 3rem;
  }
}
.c-boxFaq_question {
  padding: 4.5rem 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .c-boxFaq_question {
    padding: 2rem 0;
  }
}
.c-boxFaq_question--ttl {
  font-size: 2rem;
  padding-left: 8rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxFaq_question--ttl {
    font-size: 1.8rem;
    padding-left: 6.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxFaq_question--ttl {
    font-size: 1.6rem;
    font-feature-settings: "palt";
    padding-left: 0;
  }
}
.c-boxFaq_ico {
  font-size: 7rem;
  position: absolute;
  top: 3rem;
  left: 0rem;
  display: block;
  line-height: 1;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxFaq_ico {
    font-size: 4rem;
    top: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxFaq_ico {
    font-size: 3.6rem;
    position: static;
  }
}
.c-boxFaq_answer {
  padding: 4rem 0;
  background-image: linear-gradient(to right, #D30F1B, #D30F1B 12px, transparent 12px, transparent 24px);
  background-size: 24px 2px;
  background-repeat: repeat-x;
  background-position: left top;
  position: relative;
}
@media screen and (max-width: 767px) {
  .c-boxFaq_answer {
    padding: 2rem 0;
    background-image: linear-gradient(to right, #D30F1B, #D30F1B 6px, transparent 6px, transparent 12px);
    background-size: 12px 1px;
  }
}
.c-boxFaq_answer--text {
  padding-left: 8rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxFaq_answer--text {
    font-size: 1.4rem;
    padding-left: 6.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxFaq_answer--text {
    font-size: 1.4rem;
    padding-left: 0;
  }
}
.c-boxFaq_answer--text a {
  color: #D30F1B;
  font-weight: bold;
  text-decoration: underline;
}
@media screen and (max-width: 900px) {
  .c-boxFaq_answer--text a {
    font-size: 1.4rem;
  }
}
.c-boxFaq_accordion {
  padding: 0;
}
@media screen and (max-width: 767px) {
  .c-boxFaq_accordion {
    padding: 0;
  }
}
.c-boxFaq_accordion:not(:first-child) {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .c-boxFaq_accordion:not(:first-child) {
    margin-top: 2rem;
  }
}
.c-boxFaq_accordion .c-boxFaq_ico {
  font-size: 4rem;
  top: 4rem;
  left: 4rem;
}
@media screen and (max-width: 767px) {
  .c-boxFaq_accordion .c-boxFaq_ico {
    font-size: 3rem;
  }
}
.c-boxFaq_accordion .c-boxFaq_question {
  padding: 4rem 9.5rem 4rem 4rem;
  position: relative;
  cursor: pointer;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxFaq_accordion .c-boxFaq_question {
    padding: 4rem 6rem 4rem 3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxFaq_accordion .c-boxFaq_question {
    padding: 2rem 4rem 2rem 2rem;
  }
}
.c-boxFaq_accordion .c-boxFaq_question:after {
  content: "";
  width: 1.7rem;
  height: 1rem;
  margin: auto 0;
  background: url("../img/common/arrow_index01.svg") no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4rem;
  transition: transform 0.25s ease;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxFaq_accordion .c-boxFaq_question:after {
    right: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxFaq_accordion .c-boxFaq_question:after {
    width: 1.2rem;
    height: 0.7rem;
    right: 1.5rem;
  }
}
.c-boxFaq_accordion .c-boxFaq_question--ttl {
  padding-left: 6rem;
}
@media screen and (max-width: 767px) {
  .c-boxFaq_accordion .c-boxFaq_question--ttl {
    padding-left: 0;
  }
}
.c-boxFaq_accordion .c-boxFaq_question.is-active:after {
  transform: rotate(180deg);
}
.c-boxFaq_accordion .c-boxFaq_answer--text {
  padding-left: 6rem;
}
@media screen and (max-width: 767px) {
  .c-boxFaq_accordion .c-boxFaq_answer--text {
    padding-left: 0;
  }
}
.c-boxFaq_accordion .c-boxFaq_answer .c-text_indent {
  padding-left: calc(6rem + 1em);
}
@media screen and (max-width: 767px) {
  .c-boxFaq_accordion .c-boxFaq_answer .c-text_indent {
    padding-left: 1em;
  }
}
.c-boxFaq_accordion .c-boxFaq_answer .c-boxFaq_ico {
  left: 0;
}
.c-boxFaq_accordion .js-accordion-triggerPanel {
  padding: 0 9.5rem 0 4rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxFaq_accordion .js-accordion-triggerPanel {
    padding: 0 6rem 0 3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxFaq_accordion .js-accordion-triggerPanel {
    padding: 0 4rem 0 2rem;
  }
}
.c-boxScene_read {
  line-height: 1.5;
  vertical-align: middle;
}
@media screen and (max-width: 767px) {
  .c-boxScene_read {
    letter-spacing: 0.1rem;
  }
}
.c-boxScene_read .cap {
  font-size: 2rem;
}
@media screen and (max-width: 767px) {
  .c-boxScene_read .cap {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxScene_caption {
    font-size: 1.2rem;
  }
}
.c-boxBraces {
  text-align: center;
}
.c-boxBraces_inner {
  min-width: 64.5rem;
  padding: 0.5rem 2.5rem;
  border: 0.4rem solid #43964F;
  display: inline-block;
  position: relative;
}
@media screen and (max-width: 900px) {
  .c-boxBraces_inner {
    border-width: 0.2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxBraces_inner {
    min-width: 40rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxBraces_inner {
    min-width: 0;
    width: 100%;
    padding: 0.5rem 2.5rem;
  }
}
.c-boxBraces_inner:before, .c-boxBraces_inner:after {
  content: "";
  width: calc(100% - 3rem);
  height: 0.6rem;
  margin: 0 auto;
  background-color: #F8F8F8;
  position: absolute;
  right: 0;
  left: 0;
}
@media screen and (max-width: 767px) {
  .c-boxBraces_inner:before, .c-boxBraces_inner:after {
    width: calc(100% - 2rem);
    height: 0.4rem;
  }
}
.c-boxBraces_inner:before {
  top: -0.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxBraces_inner:before {
    top: -0.3rem;
  }
}
.c-boxBraces_inner:after {
  bottom: -0.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxBraces_inner:after {
    bottom: -0.3rem;
  }
}
.c-boxBraces_text {
  line-height: 1.52;
}
@media screen and (max-width: 767px) {
  .c-boxBraces_text {
    font-size: 1.6rem;
  }
}
.c-boxBraces--greenBlue .c-boxBraces_inner {
  border-color: #2E66AD;
}
.c-boxBraces--rose .c-boxBraces_inner {
  border-color: #CA5175;
}
.c-boxBraces--orange .c-boxBraces_inner {
  border-color: #E8903F;
}
.c-boxBraces--bronze .c-boxBraces_inner {
  border-color: #A06834;
}
.c-boxExercise_inner {
  padding: 4rem 7.3%;
}
@media screen and (max-width: 767px) {
  .c-boxExercise_inner {
    padding: 3rem 2rem;
  }
}
.c-box_link {
  padding: 4rem 2rem;
}
@media screen and (max-width: 767px) {
  .c-box_link {
    padding: 3rem 2rem;
  }
}
.c-box_link--text {
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .c-box_link--text {
    height: auto;
  }
}
.c-boxColumn {
  padding: 6rem 6%;
}
@media screen and (max-width: 767px) {
  .c-boxColumn {
    padding: 3rem 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxColumn_heading--ttl {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxColumn_profile {
    display: block;
  }
}
.c-boxColumn_profile--pic {
  width: 36%;
}
@media screen and (max-width: 767px) {
  .c-boxColumn_profile--pic {
    width: 100%;
  }
}
.c-boxColumn_profile--box {
  width: 57%;
}
@media screen and (max-width: 767px) {
  .c-boxColumn_profile--box {
    width: 100%;
    margin-top: 2rem;
  }
}
.c-boxColumn_btn {
  width: 40rem;
  margin: 6rem auto 0;
}
@media screen and (max-width: 767px) {
  .c-boxColumn_btn {
    width: 100%;
    margin-top: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxColumn_btn .c-btn_cmn {
    padding-right: 5%;
  }
}
@media screen and (max-width: 767px) {
  .c-boxColumn_btn .c-btn_cmn:after {
    right: 8%;
  }
}
@media screen and (max-width: 900px) {
  .c-boxCase_wrap {
    display: block;
  }
}
.c-boxCase_wrap--left {
  width: 45.6%;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxCase_wrap--left {
    width: 80%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 900px) {
  .c-boxCase_wrap--left {
    text-align: right;
  }
}
@media screen and (max-width: 767px) {
  .c-boxCase_wrap--left {
    width: 100%;
  }
}
.c-boxCase_wrap--right {
  width: 53.2%;
}
@media screen and (max-width: 900px) {
  .c-boxCase_wrap--right {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .c-boxCase_wrap--right {
    margin-top: -1rem;
  }
}
.c-boxCase_pic {
  padding-right: 2rem;
}
@media screen and (max-width: 767px) {
  .c-boxCase_pic {
    padding-right: 1rem;
  }
}
.c-boxCase_heading {
  padding: 1rem 2rem;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
  display: inline-block;
  position: absolute;
  right: 0;
  top: 76%;
}
@media screen and (max-width: 900px) {
  .c-boxCase_heading {
    position: static;
    transform: translateY(-3rem);
  }
}
@media screen and (max-width: 767px) {
  .c-boxCase_heading {
    padding: 1rem;
  }
}
@media screen and (min-width: 900px) and (max-width: 1025px) {
  .c-boxCase_heading--name {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxCase_heading--name {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 900px) and (max-width: 1025px) {
  .c-boxCase_heading--name span {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 900px) and (max-width: 1025px) {
  .c-boxCase_heading--group {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxCase_heading--group {
    font-size: 1.4rem;
  }
}
.c-boxCase_text {
  padding: 0 9%;
}
@media screen and (max-width: 900px) {
  .c-boxCase_text {
    padding: 0;
  }
}
@media screen and (max-width: 900px) {
  .c-boxPetsVoice_wrap {
    display: block;
  }
}
.c-boxPetsVoice_wrap--left {
  width: 43%;
}
@media screen and (max-width: 900px) {
  .c-boxPetsVoice_wrap--left {
    width: 100%;
  }
}
.c-boxPetsVoice_wrap--right {
  width: 52%;
}
@media screen and (max-width: 900px) {
  .c-boxPetsVoice_wrap--right {
    width: 100%;
    margin-top: 2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxPetsVoice_pic {
    width: 75%;
    margin: 0 auto;
  }
}
.c-boxMedicell_top {
  width: 100%;
  height: 10.2rem;
  padding-left: 27%;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .c-boxMedicell_top {
    height: 7.5rem;
    padding-left: 7.5rem;
  }
}
.c-boxMedicell_top:after {
  content: "";
  width: 1.3rem;
  height: 0.8rem;
  margin: auto 0;
  background: url("../img/common/arrow_index01.svg") no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 7%;
  transition: transform 0.25s ease;
}
.c-boxMedicell_top--ico {
  width: 17.3%;
  display: block;
  position: absolute;
  top: 50%;
  left: 7.7%;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .c-boxMedicell_top--ico {
    width: 4rem;
  }
}
.c-boxMedicell_top--ttl {
  line-height: 1.4;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxMedicell_top--ttl {
    font-size: 1.6rem;
  }
}
.c-boxMedicell_top.is-active:after {
  transform: rotate(-180deg);
}
.c-boxMedicell_bottom {
  padding: 0 10% 3rem;
  min-height: 18rem;
}
@media screen and (max-width: 767px) {
  .c-boxMedicell_bottom {
    min-height: 0;
  }
}
.c-boxFacility {
  border-top: 0.2rem solid #DDD;
}
@media screen and (max-width: 767px) {
  .c-boxFacility {
    padding: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxFacility_wrap {
    display: block;
  }
}
.c-boxFacility_wrap--left {
  width: 35%;
  padding-right: 4rem;
}
@media screen and (max-width: 767px) {
  .c-boxFacility_wrap--left {
    width: 100%;
  }
}
.c-boxFacility_wrap--right {
  width: 65%;
}
@media screen and (max-width: 767px) {
  .c-boxFacility_wrap--right {
    width: 100%;
  }
}
.c-boxFacility_wrap--rightInner {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .c-boxFacility_wrap--rightInner {
    display: block;
    margin-top: 1rem;
  }
}
.c-boxFacility_category--item {
  font-size: 1.6rem;
  padding: 0 1rem;
  margin: 0.5rem 0;
  height: 2.4rem;
  line-height: 2.4rem;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .c-boxFacility_category--item {
    font-size: 1.2rem;
    height: 2rem;
    line-height: 2rem;
  }
}
.c-boxFacility_category--item:not(:last-child) {
  margin-right: 1.5rem;
}
@media screen and (max-width: 767px) {
  .c-boxFacility_category--item:not(:last-child) {
    margin-right: 0.5rem;
  }
}
.c-boxFacility_category--item.treatment {
  background-color: #1BAF5A;
}
.c-boxFacility_category--item.beauty {
  background-color: #D64B4B;
}
.c-boxFacility_category--item.pets {
  background-color: #F2B24A;
}
.c-boxFacility_category--item.sport {
  background-color: #446DCB;
}
.c-boxFacility_detail {
  width: 74.2%;
  padding-right: 2rem;
}
@media screen and (max-width: 767px) {
  .c-boxFacility_detail {
    width: 100%;
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-boxFacility_detail--tel {
    margin-top: 0 !important;
  }
}
.c-boxFacility_detail--link a {
  word-break: break-all;
}
.c-boxFacility_medicell {
  width: 25.8%;
}
@media screen and (max-width: 767px) {
  .c-boxFacility_medicell {
    width: 100%;
    margin-top: 1rem;
  }
}
.c-boxFacility_medicell--item {
  width: 25%;
}
@media screen and (max-width: 767px) {
  .c-boxFacility_medicell--item {
    width: 3rem;
  }
}
.c-boxFacility_medicell--item:not(:nth-child(-n+3)) {
  margin-top: 1rem;
}
@media screen and (max-width: 767px) {
  .c-boxFacility_medicell--item:not(:nth-child(-n+3)) {
    margin-top: 0;
  }
}
.c-boxFacility_medicell--item:not(:nth-child(3n)) {
  margin-right: 12.5%;
}
@media screen and (max-width: 767px) {
  .c-boxFacility_medicell--item:not(:last-child) {
    margin-right: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxProduct_ttl {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxProduct_wrap {
    display: block;
  }
}
.c-boxProduct_wrap--left {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .c-boxProduct_wrap--left {
    width: 100%;
  }
}
.c-boxProduct_wrap--right {
  width: 45%;
}
@media screen and (max-width: 767px) {
  .c-boxProduct_wrap--right {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .c-boxProduct_picList {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.c-boxProduct_picList--item {
  width: 30.4%;
}
@media screen and (max-width: 767px) {
  .c-boxProduct_picList--item {
    width: 48%;
  }
}
@media screen and (max-width: 767px) {
  .c-boxProduct_picList--item:nth-child(2n) {
    margin-left: 4%;
  }
}
@media screen and (max-width: 767px) {
  .c-boxProduct_picList--item:not(:nth-child(-n+2)) {
    margin-top: 1rem;
  }
}
.c-boxProduct_picList--column3 .c-boxProduct_picList--item {
  width: 23.5%;
}
@media screen and (max-width: 767px) {
  .c-boxProduct_picList--column3 .c-boxProduct_picList--item {
    width: 48%;
  }
}
.c-boxProduct_picList--column2 .c-boxProduct_picList--item:nth-child(2n) {
  margin-left: 4%;
}
@media screen and (max-width: 767px) {
  .c-boxProduct_detail {
    margin-top: 2rem !important;
  }
}
.c-boxProduct_bnr {
  width: 65.9%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .c-boxProduct_bnr {
    width: 100%;
    margin-top: 3rem;
  }
}
.c-boxSns {
  padding: 6rem 2rem;
}
@media screen and (max-width: 767px) {
  .c-boxSns {
    padding: 3rem 2rem;
  }
}
.c-boxSns_heading {
  text-align: center;
}
.c-boxSns_heading--inner {
  padding-left: 9.5rem;
  display: inline-block;
  position: relative;
}
@media screen and (max-width: 767px) {
  .c-boxSns_heading--inner {
    padding-left: 5rem;
  }
}
.c-boxSns_heading--inner:before {
  content: "";
  width: 8.5rem;
  height: 8.5rem;
  background: url("../img/pets/ico_instagram01.png") no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .c-boxSns_heading--inner:before {
    width: 4rem;
    height: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSns_heading .c-ttl_line span {
    font-size: 1.6rem;
  }
}
.c-boxSns_code {
  max-width: 20rem;
  width: 100%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .c-boxSns_code {
    max-width: none;
    width: 15rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSns_caption {
    text-indent: -1em;
    text-align: left;
    padding-left: 1em;
  }
}
.c-box_work {
  border-radius: 1rem;
  overflow: hidden;
}
.c-box_work--inner {
  padding: 4rem 7.8%;
}
@media screen and (max-width: 767px) {
  .c-box_work--inner {
    padding: 3rem 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-box_work .c-text_size--24 {
    font-size: 1.8rem;
  }
}
.c-boxSports_pic {
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .c-boxSports .c-text_size--20 {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSports .c-text_size--20 {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .c-boxSports .c-text_size--25 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSports .c-text_size--25 {
    font-size: 1.6rem;
  }
}
.c-boxSports_name {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.5);
  line-height: 1.55;
  position: absolute;
  right: -2rem;
  bottom: -4rem;
}
@media screen and (max-width: 767px) {
  .c-boxSports_name {
    right: -1rem;
    bottom: -2rem;
  }
}
.c-boxSdgs {
  padding: 7rem 6%;
}
@media screen and (max-width: 767px) {
  .c-boxSdgs {
    padding: 3rem 2rem;
  }
}
.c-boxSdgs:not(:first-child) {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .c-boxSdgs:not(:first-child) {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSdgs_ttl span {
    font-size: 1.8rem !important;
    letter-spacing: 0.1rem !important;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSdgs_list {
    display: block;
  }
}
.c-boxSdgs_list--item {
  max-width: 38rem;
  width: 43%;
}
@media screen and (max-width: 767px) {
  .c-boxSdgs_list--item {
    width: 100%;
  }
}
.c-boxSdgs_list li + li {
  margin-left: 4.3%;
}
@media screen and (max-width: 767px) {
  .c-boxSdgs_list li + li {
    margin: 2rem 0 0;
  }
}
.c-boxSdgs_accordion {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .c-boxSdgs_accordion {
    margin-top: 9rem;
  }
}
.c-boxSdgs_accordion--panel {
  height: 6.5rem;
  transition: height 0.25s ease;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .c-boxSdgs_accordion--panel {
    height: 5rem;
  }
}
.c-boxSdgs_accordion--panel.is-view {
  height: auto;
}
.c-boxSdgs_accordion--panel.is-view02 {
  overflow: visible;
}
.c-boxSdgs_accordion--btn {
  max-width: 40rem;
  width: 45.45%;
  margin: 6rem auto 0;
}
@media screen and (max-width: 767px) {
  .c-boxSdgs_accordion--btn {
    width: 100%;
    margin-top: 4rem;
  }
}
.c-boxSdgs.box04 .c-boxSdgs_accordion--panel {
  height: auto;
}
@media screen and (max-width: 767px) {
  .c-boxSdgs.box04 .c-boxSdgs_accordion--panel {
    height: 5rem;
  }
}
.c-boxSdgs.box05 .c-ttl_line span {
  padding: 0 2rem 2rem;
}
.c-boxSeminar {
  padding: 4rem 6%;
}
.c-boxSeminar_btn {
  max-width: 40rem;
  width: 100%;
  margin: 6rem auto 0;
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_btn {
    margin-top: 4rem;
  }
}
.c-boxSeminar_cont--ttl {
  font-size: 2.5rem;
  line-height: 1.48;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxSeminar_cont--ttl {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_cont--ttl {
    font-size: 1.6rem;
  }
}
.c-boxSeminar_date--ttl {
  font-size: 3rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxSeminar_date--ttl {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_date--ttl {
    font-size: 2rem;
  }
}
@media screen and (max-width: 900px) {
  .c-boxSeminar_dateList {
    display: block;
  }
}
.c-boxSeminar_dateList--item {
  max-width: 42rem;
  width: 47.7%;
}
@media screen and (max-width: 900px) {
  .c-boxSeminar_dateList--item {
    width: 100%;
    margin: 0 auto;
  }
}
.c-boxSeminar_dateList--item:not(:nth-child(-n+2)) {
  margin-top: 4rem;
}
@media screen and (max-width: 900px) {
  .c-boxSeminar_dateList--item:not(:first-child) {
    margin-top: 3rem;
  }
}
.c-boxSeminar_dateList--item--100 {
  max-width: none;
  width: 100%;
}
.c-boxSeminar_detail {
  position: relative;
  padding: 4rem 7.15%;
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_detail {
    padding: 3rem 2rem;
  }
}
.c-boxSeminar_detail--ticker {
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #D30F1B;
  min-width: 6em;
  padding: 0.5em 1em;
  font-size: 1.8rem;
  color: #fff;
  line-height: 1;
  text-align: center;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_detail--ticker {
    font-size: 1.4rem;
  }
}
.c-boxSeminar_detail--ticker.full {
  background-color: #D30F1B;
}
.c-boxSeminar_detail--ticker.afew {
  background-color: #E8903F;
}
.c-boxSeminar_detail--ticker.vacant {
  background-color: #446DCB;
}
.c-boxSeminar_detail--ttl {
  font-size: 2.4rem;
  text-shadow: 0.3rem 0.3rem 0.6rem rgba(0, 0, 0, 0.2);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxSeminar_detail--ttl {
    font-size: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_detail--ttl {
    font-size: 1.6rem;
    text-shadow: 0.15rem 0.15rem 0.3rem rgba(0, 0, 0, 0.2);
  }
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_detail--wrap {
    display: block;
  }
}
.c-boxSeminar_detail--wrap--item {
  max-width: 35.5rem;
  width: 47.333%;
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_detail--wrap--item {
    max-width: none;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_detail--wrap ul + ul {
    margin-top: 1rem;
  }
}
.c-boxSeminar_season--ttl {
  font-size: 2.4rem;
  line-height: 1.5;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxSeminar_season--ttl {
    font-size: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_season--ttl {
    font-size: 1.6rem;
  }
}
.c-boxSeminar_season--ttl span {
  font-size: 3.2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-boxSeminar_season--ttl span {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_season--ttl span {
    font-size: 2rem;
  }
}
.c-boxSeminar_season--pic {
  max-width: 69.5rem;
  width: 79%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .c-boxSeminar_season--pic {
    width: 100%;
    margin-top: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-boxSeminar.kinmakubiryo .c-ttlSeminar_ttl--text {
    font-feature-settings: "palt";
  }
}
.c-box_category {
  font-size: 1.2rem;
  width: 12rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 2rem;
  display: block;
}
.c-box_category--release {
  background-color: #389BFF;
}
.c-box_category--news {
  background-color: #ED1B24;
}

.c-ttl_line > span {
  font-size: 4rem;
  padding: 0 2rem 1.5rem;
  letter-spacing: 0.3rem;
  display: inline-block;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttl_line > span {
    font-size: 2.8rem;
    padding: 0 1.5rem 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttl_line > span {
    font-size: 2rem;
    letter-spacing: 0.2rem;
    padding: 0 1rem 1rem;
  }
}
.c-ttl_line > span:before, .c-ttl_line > span:after {
  content: "";
  height: 0.775rem;
  transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  position: absolute;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 767px) {
  .c-ttl_line > span:before, .c-ttl_line > span:after {
    height: 0.5rem;
  }
}
.c-ttl_line > span:before {
  width: 100%;
  background-color: #DDD;
}
.c-ttl_line > span:after {
  width: 5.3rem;
  background-color: #D30F1B;
  border-right: 0.2rem solid #fff;
}
@media screen and (max-width: 767px) {
  .c-ttl_line > span:after {
    width: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttl_line > span br.sp {
    display: inline-block;
  }
}
@media screen and (max-width: 900px) {
  .c-ttl_line > span br.tab02 {
    display: inline-block;
  }
}
@media screen and (max-width: 1025px) {
  .c-ttl_line > span br.tab05 {
    display: inline-block;
  }
}
@media screen and (min-width: 768px) {
  .c-ttl_line > span .pc {
    display: inline-block;
  }
}
.c-ttl_line > span .c-text_size--25 {
  font-size: 2.5rem;
}
@media screen and (max-width: 767px) {
  .c-ttl_line > span .c-text_size--25 {
    font-size: 1.4rem;
  }
}
.c-ttl_line--32 span {
  font-size: 3.2rem;
  padding-bottom: 1rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttl_line--32 span {
    font-size: 2.6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttl_line--32 span {
    font-size: 2rem;
  }
}
.c-ttl_line--small span {
  font-size: 2.4rem;
  padding-bottom: 0.5em;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttl_line--small span {
    font-size: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttl_line--small span {
    font-size: 1.6rem;
  }
}
.c-ttl_line--en span {
  font-size: 6rem;
  letter-spacing: 1rem;
  line-height: 1;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttl_line--en span {
    font-size: 4.6rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttl_line--en span {
    font-size: 3rem;
    letter-spacing: 0.5rem;
  }
}
.c-ttl_line--green span:after {
  background-color: #43964F;
}
.c-ttl_line--greenBlue span:after {
  background-color: #2E66AD;
}
.c-ttl_line--rose span:after {
  background-color: #CA5175;
}
.c-ttl_line--orange span:after {
  background-color: #E8903F;
}
.c-ttl_line--bronze span:after {
  background-color: #A06834;
}
.c-ttl_line--yellow span:after {
  background-color: #FFFE01;
}
.c-ttl_line--purple span:after {
  background-color: #910886;
}
.c-ttl_line--pink span:after {
  background-color: #FD9CB6;
}
.c-ttl_line--azalea span:after {
  background-color: #EA5795;
}
.c-ttl_line--row {
  line-height: 1.875;
}
.c-ttl_line--row:after {
  display: none;
}
.c-ttl_textShadow {
  font-size: 3.2rem;
  text-shadow: 0.4rem 0.4rem 0.3rem rgba(0, 0, 0, 0.2);
  letter-spacing: 0.2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttl_textShadow {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttl_textShadow {
    font-size: 1.8rem;
    text-shadow: 0.2rem 0.2rem 0.15rem rgba(0, 0, 0, 0.2);
  }
}
.c-ttl_textShadow--40 {
  font-size: 4rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttl_textShadow--40 {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttl_textShadow--40 {
    font-size: 2rem;
  }
}
.c-ttl_sub {
  padding-bottom: 2rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .c-ttl_sub {
    padding-bottom: 1.2rem;
  }
}
.c-ttl_sub:after {
  content: "";
  width: 5.5rem;
  height: 0.775rem;
  margin: 0 auto;
  background-color: #D30F1B;
  transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
@media screen and (max-width: 767px) {
  .c-ttl_sub:after {
    width: 3rem;
    height: 0.5rem;
  }
}
.c-ttl_sub--en {
  font-size: 6rem;
  letter-spacing: 0.5rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttl_sub--en {
    font-size: 4.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttl_sub--en {
    font-size: 3rem;
    letter-spacing: 0.3rem;
  }
}
.c-ttl_sub--ja {
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttl_sub--ja {
    font-size: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttl_sub--ja {
    font-size: 1.6rem;
  }
}
.c-ttl_sub--ja--20 {
  font-size: 2rem;
}
@media screen and (max-width: 900px) {
  .c-ttl_sub--ja--20 {
    font-size: 1.6rem;
  }
}
.c-ttl_marker {
  font-size: 3.2rem;
  letter-spacing: 0.2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttl_marker {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttl_marker {
    font-size: 2rem;
    letter-spacing: 0;
  }
}
.c-ttl_marker > span {
  padding: 0 2rem;
  position: relative;
  display: inline-block;
}
@media screen and (max-width: 767px) {
  .c-ttl_marker > span {
    padding: 0 0.5rem;
  }
}
.c-ttl_marker > span:before {
  content: "";
  width: 100%;
  height: 1.665rem;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
}
@media screen and (max-width: 767px) {
  .c-ttl_marker > span:before {
    height: 0.8rem;
  }
}
.c-ttl_marker > span span {
  position: relative;
}
.c-ttl_marker--gray:before {
  background-color: #DDD;
}
.c-ttl_underLine {
  position: relative;
}
.c-ttl_underLine:after {
  content: "";
  width: 100%;
  height: 0.4rem;
  background-color: #43964F;
  position: absolute;
  bottom: -0.2rem;
  left: 0;
}
@media screen and (max-width: 767px) {
  .c-ttl_underLine:after {
    height: 0.2rem;
  }
}
.c-ttl_underLine--greenBlue:after {
  background-color: #2E66AD;
}
.c-ttl_underLine--rose:after {
  background-color: #CA5175;
}
.c-ttl_underLine--orange:after {
  background-color: #E8903F;
}
.c-ttl_underLine--bronze:after {
  background-color: #A06834;
}
.c-ttl_underLine--thin:after {
  height: 0.2rem;
}
@media screen and (max-width: 767px) {
  .c-ttl_underLine--thin:after {
    height: 0.1rem;
  }
}
.c-ttl_sdgs {
  border-bottom: 0.3rem solid #E83418;
}
@media screen and (max-width: 767px) {
  .c-ttl_sdgs {
    position: relative;
    padding-bottom: 1rem;
  }
}
.c-ttl_sdgs--ico {
  width: 10rem;
  height: 10rem;
  display: block;
}
@media screen and (max-width: 767px) {
  .c-ttl_sdgs--ico {
    width: 5rem;
    height: 5rem;
    position: absolute;
    left: 0;
    bottom: -7rem;
  }
}
.c-ttl_sdgs--text {
  font-size: 2.5rem;
  width: calc(100% - 13rem);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttl_sdgs--text {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttl_sdgs--text {
    font-size: 1.8rem;
    width: 100%;
  }
}
.c-ttl_sdgs--green {
  border-color: #1B973A;
}
.c-ttl_sdgs--rose {
  border-color: #C50E28;
}
.c-ttl_sdgs--madder {
  border-color: #970A31;
}
.c-ttl_sdgs--blue {
  border-color: #023067;
}
.c-ttlSeminar_ico {
  width: 10rem;
  height: 10rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttlSeminar_ico {
    width: 8rem;
    height: 8rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttlSeminar_ico {
    width: 5rem;
    height: 5rem;
  }
}
.c-ttlSeminar_ttl {
  width: calc(100% - 12.5rem);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttlSeminar_ttl {
    width: calc(100% - 10.5rem);
  }
}
@media screen and (max-width: 767px) {
  .c-ttlSeminar_ttl {
    width: calc(100% - 6rem);
  }
}
.c-ttlSeminar_ttl--text {
  font-size: 2.4rem;
  padding-left: 2.5rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-ttlSeminar_ttl--text {
    font-size: 2rem;
    padding-left: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttlSeminar_ttl--text {
    font-size: 1.4rem;
    padding-left: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttlSeminar_ttl--ttl {
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-ttlSeminar_ttl--ttl span {
    font-size: 1.8rem;
  }
}

.c-slide_img {
  padding: 6rem 0;
  position: relative;
}
@media screen and (max-width: 767px) {
  .c-slide_img {
    padding: 3rem 0;
  }
}
.c-slide_img:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #F8F8F8;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) skew(-15deg, 0deg);
}
@media screen and (max-width: 767px) {
  .c-slide_img:before {
    width: 115%;
  }
}
.c-slide_img--inner {
  max-width: 69.5rem;
  width: 80%;
  margin: 0 auto;
}
.c-slide_img .slick-arrow {
  width: 5.7rem;
  height: 5.7rem;
  z-index: 1;
  transition: opacity 0.25s ease;
  border-radius: 1rem;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.26);
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .c-slide_img .slick-arrow {
    width: 3rem;
    height: 3rem;
    border-radius: 0.3rem;
    box-shadow: 0.1rem 0.15rem 1rem rgba(0, 0, 0, 0.26);
  }
}
.c-slide_img .slick-arrow:hover {
  opacity: 0.8;
}
.c-slide_img .slick-arrow:before {
  content: "";
  width: 100%;
  height: 100%;
  background: url("../img/common/ico_arrow01.svg") no-repeat;
  background-size: contain;
  display: block;
  opacity: 1;
}
.c-slide_img .slick-prev {
  left: -2.85rem;
  transform: translateY(-50%) rotate(-45deg);
}
@media screen and (max-width: 767px) {
  .c-slide_img .slick-prev {
    left: -1.5rem;
  }
}
.c-slide_img .slick-next {
  right: -2.85rem;
  transform: translateY(-50%) rotate(135deg);
}
@media screen and (max-width: 767px) {
  .c-slide_img .slick-next {
    right: -1.5rem;
  }
}
.c-slide_img .slick-disabled {
  opacity: 0;
  pointer-events: none;
}
.c-slide_img--itemBg {
  width: 100%;
  padding-bottom: 65%;
  background-size: cover;
}
.c-slide_imgThumbnail {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 767px) {
  .c-slide_imgThumbnail {
    width: 23rem;
    margin: 1.5rem auto 0;
    flex-wrap: wrap;
  }
}
.c-slide_imgThumbnail--item {
  max-width: 12.4rem;
  width: 11.1%;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .c-slide_imgThumbnail--item {
    max-width: none;
    width: 7rem;
  }
}
.c-slide_imgThumbnail--item:not(:last-child) {
  margin-right: 1.5rem;
}
@media screen and (max-width: 767px) {
  .c-slide_imgThumbnail--item:not(:last-child) {
    margin-right: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .c-slide_imgThumbnail--item:nth-child(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .c-slide_imgThumbnail--item:not(:nth-child(-n+3)) {
    margin-top: 1rem;
  }
}
.c-slide_imgThumbnail--item.is-current {
  position: relative;
  pointer-events: none;
}
.c-slide_imgThumbnail--item.is-current:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}
.c-slide_imgThumbnail--itemBg {
  padding-bottom: 65%;
  background-size: cover;
}

.c-bnrProduct {
  display: block;
}
.c-bnrProduct:hover {
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .c-anime_small--box {
    transition: transform 0.45s cubic-bezier(0.23, 1, 0.58, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.58, 1);
    position: relative;
  }
}
@media screen and (min-width: 768px) {
  .c-anime_small--box:after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.45s cubic-bezier(0.23, 1, 0.58, 1), -webkit-transform 0.45s cubic-bezier(0.23, 1, 0.58, 1);
  }
}
@media screen and (min-width: 768px) {
  .c-anime_small:hover .c-anime_small--box {
    transform: scale(0.8);
  }
}
@media screen and (min-width: 768px) {
  .c-anime_small:hover .c-anime_small--box:after {
    opacity: 0.4;
  }
}

.c-sub {
  position: relative;
}
.c-sub:before {
  content: "";
  width: 100%;
  height: calc(100% - 10rem);
  background: url("../img/common/sub_bg01.png") repeat-y;
  background-size: 100% auto;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .c-sub:before {
    height: calc(100% - 5rem);
  }
}
@media screen and (max-width: 767px) {
  .c-sub .c-ttl_textShadow {
    font-size: 2rem;
  }
}
.c-sub_inner {
  position: relative;
}
.c-sub_topIco {
  width: 10.7%;
  margin-top: -5.2%;
  border-radius: 9.3%;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.26);
  overflow: hidden;
  transform: rotate(-45deg);
  position: absolute;
  top: 0;
  right: 8.5%;
  z-index: 100;
}
@media screen and (min-width: 1401px) {
  .c-sub_topIco {
    width: 15rem;
    margin-top: 0;
    border-radius: 1.4rem;
    right: 12rem;
    top: -7.5rem;
  }
}
@media screen and (max-width: 767px) {
  .c-sub_topIco {
    margin-top: 0;
    width: 8rem;
    right: 2rem;
    top: -4rem;
    box-shadow: 0.1rem 0.15rem 1rem rgba(0, 0, 0, 0.26);
  }
}
.c-subScene:before {
  height: 100%;
  background-image: url("../img/common/sub_bg02.png");
  z-index: auto;
}
.c-subScene_inner {
  padding-top: 8rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .c-subScene_inner {
    padding: 4rem 2rem 0;
  }
}
.c-subScene_ico {
  max-width: 25.5rem;
  width: 20.9%;
  margin-top: -12.3%;
  position: absolute;
  top: 0;
  right: 0;
}
@media screen and (max-width: 767px) {
  .c-subScene_ico {
    max-width: none;
    width: 10rem;
    margin-top: 0;
    top: -6rem;
    right: 2rem;
  }
}
.c-subScene_exercise--item:not(:nth-child(-n+2)) {
  margin-top: 3rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-subScene_exercise--item:not(:nth-child(-n+2)) {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .c-subScene_exercise--item:not(:first-child) {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-subScene_linkList {
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-subScene_linkList--item {
    width: 49%;
    margin: 0 !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .c-subScene_linkList--item:not(:nth-child(-n+2)) {
    margin-top: 2rem !important;
  }
}
@media screen and (max-width: 767px) {
  .c-subScene_linkList--item:not(:first-child) {
    margin-top: 2rem;
  }
}
.c-subSceneIntro_text {
  line-height: 1.5;
}
.c-sub_404--inner {
  width: 100%;
  padding: 10rem 0;
  background-color: #fff;
}
.c-sub_404--btn {
  max-width: 40rem;
  width: 100%;
  margin: 8rem auto 0;
}
@media screen and (max-width: 767px) {
  .c-sub_404--btn {
    margin-top: 6rem;
  }
}

.c-mt--100 {
  margin-top: 10rem;
}
@media screen and (max-width: 767px) {
  .c-mt--100 {
    margin-top: 6rem;
  }
}
.c-mt--80 {
  margin-top: 8rem;
}
@media screen and (max-width: 767px) {
  .c-mt--80 {
    margin-top: 6rem;
  }
}
.c-mt--70 {
  margin-top: 7rem;
}
@media screen and (max-width: 767px) {
  .c-mt--70 {
    margin-top: 4rem;
  }
}
.c-mt--60 {
  margin-top: 6rem;
}
@media screen and (max-width: 767px) {
  .c-mt--60 {
    margin-top: 4rem;
  }
}
.c-mt--55 {
  margin-top: 5.5rem;
}
@media screen and (max-width: 767px) {
  .c-mt--55 {
    margin-top: 3.5rem;
  }
}
.c-mt--50 {
  margin-top: 5rem;
}
@media screen and (max-width: 767px) {
  .c-mt--50 {
    margin-top: 3rem;
  }
}
.c-mt--45 {
  margin-top: 4.5rem;
}
@media screen and (max-width: 767px) {
  .c-mt--45 {
    margin-top: 3rem;
  }
}
.c-mt--40 {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .c-mt--40 {
    margin-top: 3rem;
  }
}
.c-mt--35 {
  margin-top: 3.5rem;
}
@media screen and (max-width: 767px) {
  .c-mt--35 {
    margin-top: 2.5rem;
  }
}
.c-mt--30 {
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .c-mt--30 {
    margin-top: 2rem;
  }
}
.c-mt--25 {
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .c-mt--25 {
    margin-top: 1.5rem;
  }
}
.c-mt--20 {
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .c-mt--20 {
    margin-top: 1rem;
  }
}
.c-mt--15 {
  margin-top: 1.5rem;
}
@media screen and (max-width: 767px) {
  .c-mt--15 {
    margin-top: 1rem;
  }
}
.c-mt--10 {
  margin-top: 1rem;
}
@media screen and (max-width: 767px) {
  .c-mt--10 {
    margin-top: 0.5rem;
  }
}
.c-mt--5 {
  margin-top: 0.5rem;
}
.c-mt--0 {
  margin-top: 0 !important;
}

.c-table_company {
  width: 100%;
  border-top: 0.1rem solid #D3D3D3;
  border-left: 0.1rem solid #D3D3D3;
}
.c-table_company th, .c-table_company td {
  padding: 3rem 4rem;
  border-bottom: 0.1rem solid #D3D3D3;
  border-right: 0.1rem solid #D3D3D3;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .c-table_company th, .c-table_company td {
    display: block;
    padding: 1rem 2rem;
  }
}
.c-table_company th {
  width: 18rem;
  background-color: #DDD;
  letter-spacing: 0.2rem;
}
@media screen and (max-width: 767px) {
  .c-table_company th {
    width: 100%;
  }
}
.c-table_company td {
  width: calc(100% - 18rem);
  letter-spacing: 0.1rem;
}
@media screen and (max-width: 767px) {
  .c-table_company td {
    width: 100%;
  }
}
.c-table_company td .pc {
  display: none;
}
@media screen and (min-width: 768px) {
  .c-table_company td .pc {
    display: inline-block;
  }
}
.c-table_company td .sp {
  display: none;
}
@media screen and (max-width: 767px) {
  .c-table_company td .sp {
    display: inline-block;
  }
}
.c-table_company td .map {
  font-size: 1.4rem;
  width: 15rem;
  height: 2.8rem;
  padding: 0 1rem;
  margin-left: 2rem;
  border-radius: 0.4rem;
  line-height: 2.8rem;
  display: inline-block;
  position: relative;
}
@media screen and (max-width: 900px) {
  .c-table_company td .map {
    font-size: 1.2rem;
    width: 12.5rem;
    height: 2rem;
    line-height: 2rem;
    margin: 0.5rem 0 0;
    display: block;
  }
}
.c-table_company td .map:after {
  content: "";
  width: 1.4rem;
  height: 1.4rem;
  background: url("../img/common/ico_link01_w.svg") no-repeat;
  background-size: contain;
  position: absolute;
  right: 1.3rem;
  top: 0.6rem;
}
@media screen and (max-width: 900px) {
  .c-table_company td .map:after {
    width: 1.2rem;
    height: 1.2rem;
    top: 0.3rem;
    right: 0.8rem;
  }
}
.c-table_history {
  width: 100%;
}
.c-table_history tr:not(:last-child) {
  border-bottom: 0.1rem solid #D3D3D3;
}
.c-table_history tr:first-child th:before {
  content: "";
  width: 0.4rem;
  height: 3.4rem;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: -0.2rem;
}
@media screen and (max-width: 767px) {
  .c-table_history tr:first-child th:before {
    height: 2.1rem;
  }
}
.c-table_history tr:last-child th:before {
  content: "";
  width: 0.2rem;
  height: 3.2rem;
  background: url("../img/common/line_table01.svg") no-repeat;
  background-size: contain;
  position: absolute;
  bottom: -4.3rem;
  right: -0.2rem;
}
.c-table_history th {
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  line-height: 1;
  width: 12rem;
  padding: 3rem 0;
  border-right: 0.2rem solid #D30F1B;
  position: relative;
}
@media screen and (max-width: 767px) {
  .c-table_history th {
    font-size: 1.6rem;
    letter-spacing: 0.1rem;
    width: 7.5rem;
    padding: 1.5rem 0;
  }
}
.c-table_history th:after {
  content: "";
  width: 2.2rem;
  height: 2.2rem;
  background: url("../img/common/ico_table01.svg") no-repeat;
  background-size: contain;
  position: absolute;
  top: 3.3rem;
  right: -1.2rem;
}
@media screen and (max-width: 767px) {
  .c-table_history th:after {
    width: 1.6rem;
    height: 1.6rem;
    right: -0.9rem;
    top: 1.7rem;
  }
}
.c-table_history td {
  width: calc(100% - 12rem);
  padding: 3rem 0 3rem 5.5rem;
}
@media screen and (max-width: 767px) {
  .c-table_history td {
    font-size: 1.2rem;
    width: calc(100% - 7.5rem);
    padding: 1.5rem 0 1.5rem 2rem;
  }
}

.p-topNews_inner {
  padding: 3rem 0;
}
@media screen and (max-width: 767px) {
  .p-topNews_inner {
    padding-bottom: 11rem;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .p-topNews_wrap {
    display: block;
  }
}
.p-topNews_left {
  max-width: 32rem;
  width: 26.66%;
}
@media screen and (max-width: 767px) {
  .p-topNews_left {
    max-width: none;
    width: 100%;
  }
}
.p-topNews_right {
  max-width: 80.5rem;
  width: 67%;
  padding: 2rem 3rem;
  border-radius: 2rem;
}
@media screen and (max-width: 767px) {
  .p-topNews_right {
    max-width: none;
    width: 100%;
    margin-top: 3rem;
    padding: 2rem;
    border-radius: 1rem;
  }
}
.p-topNews_ttl {
  font-size: 6rem;
  line-height: 1.1;
  letter-spacing: 0.6rem;
}
@media screen and (min-width: 768px) and (max-width: 1250px) {
  .p-topNews_ttl {
    font-size: 4.2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topNews_ttl {
    font-size: 3.2rem;
  }
}
.p-topNews_ttl--sub {
  font-size: 2rem;
  margin-top: 1rem;
}
@media screen and (max-width: 900px) {
  .p-topNews_ttl--sub {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topNews_ttl--sub {
    margin-top: 0.5rem;
  }
}
.p-topNews_btn {
  max-width: 30rem;
  width: 100%;
  margin-top: 4.5rem;
}
@media screen and (max-width: 767px) {
  .p-topNews_btn {
    max-width: 25rem;
    margin: 0 auto;
    margin-top: 3rem;
    position: absolute;
    bottom: 3rem;
    right: 0;
    left: 0;
  }
}
.p-topMovie_inner {
  padding: 8rem 0;
}
@media screen and (max-width: 767px) {
  .p-topMovie_inner {
    padding: 6rem 0;
  }
}
.p-topMovie_ttl {
  font-size: 6rem;
  letter-spacing: 1.5rem;
}
@media screen and (max-width: 767px) {
  .p-topMovie_ttl {
    font-size: 3.2rem;
    letter-spacing: 0.8rem;
  }
}
.p-topMovie_list {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .p-topMovie_list {
    margin-top: 3rem;
    display: block;
  }
}
.p-topMovie_list--item {
  width: 48%;
}
@media screen and (max-width: 767px) {
  .p-topMovie_list--item {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .p-topMovie_list--item:last-child {
    margin-top: 3rem;
  }
}
.p-topMovie_list--item img {
  vertical-align: bottom;
}
.p-topMovie_list--pic {
  margin-top: 2rem;
  border-radius: 3rem;
  overflow: hidden;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.26);
}
@media screen and (max-width: 767px) {
  .p-topMovie_list--pic {
    margin-top: 1rem;
  }
}
.p-topMovie_list--ttl {
  font-size: 3rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-topMovie_list--ttl {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topMovie_list--ttl {
    font-size: 2rem;
  }
}
.p-topMovie_list--ttl span {
  height: 4.5rem;
  display: inline-block;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-topMovie_list--ttl span {
    height: 3.2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topMovie_list--ttl span {
    height: 3rem;
  }
}
.p-topMovie_list--ttl span img {
  max-width: none;
  max-height: 100%;
}
.p-topField {
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-topField {
    padding: 3rem 0;
  }
}
.p-topField:before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #404040;
  position: absolute;
  top: 0;
  left: 0;
  transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .p-topField:before {
    display: none;
  }
}
.p-topField_inner {
  padding: 6rem 0 8.5rem;
}
@media screen and (max-width: 767px) {
  .p-topField_inner {
    padding: 4rem 0;
    background-color: #404040;
    position: relative;
  }
}
.p-topField_inner:before, .p-topField_inner:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  left: 0;
}
@media screen and (min-width: 768px) {
  .p-topField_inner:before, .p-topField_inner:after {
    display: none;
  }
}
.p-topField_inner:before {
  border-width: 0 0 3rem 37.5rem;
  border-color: transparent transparent #404040 transparent;
  bottom: 100%;
}
.p-topField_inner:after {
  border-width: 3rem 37.5rem 0 0;
  border-color: #404040 transparent transparent transparent;
  top: 100%;
  z-index: 2;
}
.p-topField_ttl {
  font-size: 4rem;
  letter-spacing: 3px;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-topField_ttl {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topField_ttl {
    font-size: 2.2rem;
  }
}
.p-topField_list {
  width: 83%;
  margin: 3rem auto 0;
  transform: skew(-15deg, 0deg);
}
@media screen and (max-width: 767px) {
  .p-topField_list {
    display: block;
    transform: skew(0, 0);
  }
}
.p-topField_list--item {
  width: 48%;
}
@media screen and (max-width: 767px) {
  .p-topField_list--item {
    width: 100%;
    transform: translateY(50%) skew(-15deg, 0deg);
  }
}
.p-topField_list--item:not(:nth-child(-n+2)) {
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .p-topField_list--item:not(:first-child) {
    margin-top: 2rem;
  }
}
.p-topField_list--item a {
  width: 100%;
  padding-bottom: 55%;
  display: block;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}
.p-topField_list--item a:before {
  content: "";
  width: 100%;
  height: 100%;
  background: url("../img/field_bg01.jpg") no-repeat center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: skew(15deg, 0deg) scale(1.2);
  transition: transform 0.4s;
}
.p-topField_list--item a:hover {
  opacity: 1;
}
.p-topField_list--item a:hover:before {
  transform: skew(15deg, 0deg) scale(1.3);
}
.p-topField_list--item a:hover .p-topField_list--boxInner {
  background-color: white;
}
.p-topField_list--item.sports a:before {
  background-image: url("../img/field_bg02.jpg");
}
.p-topField_list--item.beauty a:before {
  background-image: url("../img/field_bg03.jpg");
}
.p-topField_list--item.animals a:before {
  background-image: url("../img/field_bg04.jpg");
}
.p-topField_list--box {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  position: absolute;
  z-index: 1;
}
.p-topField_list--boxInner {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.46);
  transition: background-color 0.4s;
}
.p-topField_list--ttl {
  font-size: 3.9rem;
  line-height: 1.3;
  transform: skew(15deg, 0deg);
  line-height: 1;
  letter-spacing: 0.6rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-topField_list--ttl {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topField_list--ttl {
    font-size: 2.2rem;
    line-height: 1.1;
  }
}
.p-topField_list--ttl .small {
  font-size: 2.4rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-topField_list--ttl .small {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topField_list--ttl .small {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topField_list.is-animated .c-scroll_fadein {
    transform: translateY(0) skew(-15deg, 0deg);
  }
}
@media screen and (max-width: 767px) {
  .p-topApproach {
    padding: 10rem 0 6rem;
    position: relative;
  }
}
@media screen and (max-width: 767px) {
  .p-topApproach_wrap {
    display: block;
  }
}
.p-topApproach_bg {
  flex-basis: 46%;
}
.p-topApproach_bg--inner {
  width: 200%;
  position: relative;
  left: -93%;
}
@media screen and (max-width: 767px) {
  .p-topApproach_bg--inner {
    width: 100%;
    position: absolute;
    left: -12rem;
    top: -2rem;
  }
}
.p-topApproach_bg--inner img {
  vertical-align: bottom;
}
.p-topApproach_cont {
  flex-basis: 54%;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-topApproach_cont {
    padding: 3rem 2rem;
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0.2rem 0.3rem 1rem rgba(0, 0, 0, 0.26);
  }
}
.p-topApproach_box {
  max-width: 48.7rem;
  width: 90%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-topApproach_box {
    max-width: none;
    width: 100%;
    padding: 0 1rem;
  }
}
.p-topApproach_ttl {
  font-size: 4rem;
  line-height: 1.5;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-topApproach_ttl {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topApproach_ttl {
    font-size: 2.4rem;
  }
}
.p-topApproach_text {
  line-height: 1.625;
  margin-top: 3rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-topApproach_text {
    font-size: 1.4rem;
    margin-top: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topApproach_text {
    margin-top: 2rem;
  }
}
.p-topApproach_text p:not(:first-child) {
  margin-top: 0.8rem;
}
.p-topApproach_list {
  margin-top: 6rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-topApproach_list {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topApproach_list {
    margin-top: 3rem;
    display: block;
  }
}
.p-topApproach_list--item {
  width: 47.6%;
}
@media screen and (max-width: 767px) {
  .p-topApproach_list--item {
    width: 100%;
  }
}
.p-topApproach_list--item:nth-child(even) {
  margin-left: 4.8%;
}
@media screen and (max-width: 767px) {
  .p-topApproach_list--item:nth-child(even) {
    margin-left: 0;
  }
}
.p-topApproach_list--item:not(:nth-child(-n+2)) {
  margin-top: 3rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-topApproach_list--item:not(:nth-child(-n+2)) {
    margin-top: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-topApproach_list--item:not(:first-child) {
    margin-top: 1.5rem;
  }
}
.p-topCompany_inner {
  padding: 10rem 0 14rem;
}
@media screen and (max-width: 767px) {
  .p-topCompany_inner {
    padding: 5rem 0 6rem;
  }
}
.p-topCompany_btn {
  max-width: 83rem;
  width: 100%;
  margin: 0 auto;
}
.p-topAssociation_inner {
  padding: 10rem 0 14rem;
}
@media screen and (max-width: 767px) {
  .p-topAssociation_inner {
    padding: 5rem 0 6rem;
  }
}
.p-topAssociation_btn {
  max-width: 83rem;
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
}
.p-topContact {
  padding: 6.5rem 0 6rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-topContact {
    padding: 4rem 0;
  }
}
.p-topContact_ttl {
  font-size: 6rem;
  margin: 0 auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform: translateY(-50%);
  letter-spacing: 0.5rem;
}
@media screen and (max-width: 767px) {
  .p-topContact_ttl {
    font-size: 4rem;
  }
}
.p-topContact_text {
  font-size: 2.6rem;
}
@media screen and (max-width: 767px) {
  .p-topContact_text {
    font-size: 1.7rem;
  }
}
.p-topContact_list {
  margin-top: 6rem;
}
@media screen and (max-width: 767px) {
  .p-topContact_list {
    margin-top: 3rem;
  }
}
.p-topContact_list--item {
  max-width: 40rem;
  width: 48%;
}
@media screen and (max-width: 767px) {
  .p-topContact_list--item {
    max-width: none;
    width: 100%;
  }
}
.p-topContact_list--item:nth-child(even) {
  margin-left: 3rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-topContact_list--item:nth-child(even) {
    margin-left: 4%;
  }
}
@media screen and (max-width: 767px) {
  .p-topContact_list--item:nth-child(even) {
    margin-left: 0;
  }
}
.p-topContact_list--item:not(:last-child) {
  margin-bottom: 3rem;
}
@media screen and (max-width: 767px) {
  .p-topContact_list--item:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
.p-topContact_link {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .p-topContact_link {
    margin-top: 2.5rem;
  }
}
.p-topContact_link a {
  font-size: 1.6rem;
  padding-bottom: 0.5rem;
  display: inline-block;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-topContact_link a {
    font-size: 1.4rem;
  }
}
.p-topContact_link a:after {
  content: "";
  width: 100%;
  height: 1px;
  margin: 0 auto;
  background-color: #D30F1B;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.p-kv_inner {
  padding: 4rem 0;
}
@media screen and (max-width: 767px) {
  .p-kv_inner {
    padding: 3rem 0;
  }
}
.p-kvSlide_list {
  padding: 0 8.57%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  transition: 1s;
  left: -173%;
}
@media screen and (max-width: 767px) {
  .p-kvSlide_list {
    padding: 0;
    left: -200%;
  }
}
.p-kvSlide_list--item {
  min-width: 100%;
}
@media screen and (max-width: 767px) {
  .p-kvSlide_list--item {
    padding: 0 2.2rem;
  }
}
@media screen and (min-width: 768px) {
  .p-kvSlide_list--item:not(:last-child) {
    margin-right: 4.3%;
  }
}
.p-kvSlide_list--item a {
  display: block;
}
.p-kvSlide_list--item img {
  vertical-align: bottom;
  transition: 1s;
}
.p-kvSlide_list--item.is-animation img {
  transform: scale(0.9);
}
.p-kvSlide_list.prev {
  left: -87%;
}
@media screen and (max-width: 767px) {
  .p-kvSlide_list.prev {
    left: -100%;
  }
}
.p-kvSlide_list.first {
  left: -173%;
}
@media screen and (max-width: 767px) {
  .p-kvSlide_list.first {
    left: -200%;
  }
}
.p-kvSlide_list.second {
  left: -259.5%;
}
@media screen and (max-width: 767px) {
  .p-kvSlide_list.second {
    left: -300%;
  }
}
.p-kvSlide_list.third {
  left: -346%;
}
@media screen and (max-width: 767px) {
  .p-kvSlide_list.third {
    left: -400%;
  }
}
.p-kvSlide_list.forth {
  left: -432%;
}
@media screen and (max-width: 767px) {
  .p-kvSlide_list.forth {
    left: -500%;
  }
}
.p-kvSlide_list.next {
  left: -518.69%;
}
@media screen and (max-width: 767px) {
  .p-kvSlide_list.next {
    left: -600%;
  }
}
.p-kvSlide_list.is-nosmooth {
  transition: 0s;
}
.p-kvSlide_navi {
  max-width: 102rem;
  width: calc(100% - 4rem);
  margin: 2rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-kvSlide_naviList {
    padding: 0;
    justify-content: center;
  }
}
.p-kvSlide_naviList--item:not(:first-child) {
  margin-left: 2.3rem;
}
@media screen and (max-width: 767px) {
  .p-kvSlide_naviList--item:not(:first-child) {
    margin-left: 2rem;
  }
}
.p-kvSlide_naviList--item button {
  width: 1.6rem;
  height: 1.6rem;
  border: 0.1rem solid #D30F1B;
  border-radius: 0.2rem;
  transform: rotate(45deg);
  display: block;
  transition: background-color 0.4s;
}
@media screen and (max-width: 767px) {
  .p-kvSlide_naviList--item button {
    width: 1.4rem;
    height: 1.4rem;
  }
}
.p-kvSlide_naviList--item.is-current button {
  background-color: #D30F1B;
  pointer-events: none;
}

.p-kv02_inner {
  padding: 2rem 0;
  padding: 0 0 2rem;
}
.p-kv02_slideList {
  font-size: 0;
  white-space: nowrap;
  position: relative;
  left: 0;
  transition: 1s;
}
.p-kv02_slideList.first {
  left: 0;
}
.p-kv02_slideList.second {
  left: -100%;
}
.p-kv02_slideList.third {
  left: -200%;
}
.p-kv02_slideList.forth {
  left: -300%;
}
.p-kv02_slideList.fifth {
  left: -400%;
}
.p-kv02_slideList.next {
  left: -500%;
}
.p-kv02_slideList.is-nosmooth {
  transition: 0s;
}
.p-kv02_slideCont {
  width: 100%;
  display: inline-block;
}
.p-kv02_slideCont_inner {
  width: 100%;
  height: 100vh;
}
.p-kv02_slideCont a {
  max-width: 102rem;
  width: calc(100% - 4rem);
  margin: 0 auto;
  display: block;
  max-width: none;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  transition: transform 1s;
  position: relative;
  overflow: hidden;
}
.p-kv02_slideCont a:hover {
  opacity: 1;
}
.p-kv02_slideCont--ttl {
  position: absolute;
}
.p-kv02_slideCont--ttl img {
  width: 100%;
}
.p-kv02_slideCont--btn {
  width: 14.85%;
  position: absolute;
  bottom: 0;
  right: 8.5%;
}
@media screen and (max-width: 1025px) {
  .p-kv02_slideCont--btn {
    max-width: 34rem;
    width: 45.33%;
    bottom: -1.6%;
    right: -1.8%;
  }
}
@media screen and (max-width: 767px) {
  .p-kv02_slideCont--btn {
    width: 16rem;
    bottom: -1rem;
    right: -0.7rem;
  }
}
.p-kv02_slideCont--btn img {
  width: 100%;
}
@media screen and (max-width: 1025px) {
  .p-kv02_slideCont .pc01 {
    display: none;
  }
}
.p-kv02_slideCont .sp01 {
  display: none;
}
@media screen and (max-width: 1025px) {
  .p-kv02_slideCont .sp01 {
    display: block;
  }
}
.p-kv02_slideCont.is-animation a {
  transform: scale(0.95);
  transform: scale(0.6);
}
.p-kv02_slideCont.cont01 a {
  background-image: url("../img/kv_slide01.jpg");
}
@media screen and (max-width: 1025px) {
  .p-kv02_slideCont.cont01 a {
    background-image: url("../img/kv_slide01_sp.jpg");
  }
}
.p-kv02_slideCont.cont01 .p-kv02_slideCont--ttl.ttl01 {
  width: 26.7%;
  top: 30%;
  left: 8.5%;
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-kv02_slideCont.cont01 .p-kv02_slideCont--ttl.ttl01 {
    max-width: 51.4rem;
    width: 67%;
    top: 15.3%;
    left: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .p-kv02_slideCont.cont01 .p-kv02_slideCont--ttl.ttl01 {
    width: 25rem;
    top: 9.5rem;
    left: 1rem;
  }
}
.p-kv02_slideCont.cont01 .p-kv02_slideCont--ttl.ttl02 {
  width: 23.4%;
  top: 33.2%;
  right: 10.35%;
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-kv02_slideCont.cont01 .p-kv02_slideCont--ttl.ttl02 {
    max-width: 35.6rem;
    width: 46.4%;
    top: auto;
    bottom: 14.8%;
    right: 3.4%;
  }
}
@media screen and (max-width: 767px) {
  .p-kv02_slideCont.cont01 .p-kv02_slideCont--ttl.ttl02 {
    width: 17.3rem;
    top: auto;
    bottom: 8.8rem;
    right: 1.3rem;
  }
}
.p-kv02_slideCont.cont02 a {
  background-image: url("../img/kv_slide02.jpg");
}
@media screen and (max-width: 1025px) {
  .p-kv02_slideCont.cont02 a {
    background-image: url("../img/kv_slide02_sp.jpg");
  }
}
.p-kv02_slideCont.cont02 .p-kv02_slideCont--ttl {
  width: 57.35%;
  top: calc(50% + 25px);
  left: calc(50% - 13px);
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-kv02_slideCont.cont02 .p-kv02_slideCont--ttl {
    width: 70%;
  }
}
@media screen and (max-width: 767px) {
  .p-kv02_slideCont.cont02 .p-kv02_slideCont--ttl {
    width: 33rem;
    top: calc(50% - 0.6rem);
    left: calc(50% - 0.6rem);
  }
}
.p-kv02_slideCont.cont03 a {
  background-image: url("../img/kv_slide03.jpg");
}
@media screen and (max-width: 1025px) {
  .p-kv02_slideCont.cont03 a {
    background-image: url("../img/kv_slide03_sp.jpg");
  }
}
.p-kv02_slideCont.cont03 .p-kv02_slideCont--ttl {
  width: 21.9%;
  min-width: 30rem;
  top: calc(50% + 2rem);
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .p-kv02_slideCont.cont03 .p-kv02_slideCont--ttl {
    width: 15.4rem;
    min-width: 0;
    top: auto;
    bottom: 11rem;
    transform: translateX(-50%);
  }
}
.p-kv02_slideCont.cont04 a {
  background-image: url("../img/kv_slide04.jpg");
}
@media screen and (max-width: 1025px) {
  .p-kv02_slideCont.cont04 a {
    background-image: url("../img/kv_slide04_sp.jpg");
  }
}
.p-kv02_slideCont.cont04 .p-kv02_slideCont--ttl {
  width: 62.85%;
  min-width: 70rem;
  top: calc(50% + 1.5rem);
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .p-kv02_slideCont.cont04 .p-kv02_slideCont--ttl {
    width: 35.2rem;
    min-width: 0;
    top: calc(50% + 0.5rem);
  }
}
.p-kv02_slideCont.cont05 a {
  background-image: url("../img/kv_slide05.jpg");
}
@media screen and (max-width: 1025px) {
  .p-kv02_slideCont.cont05 a {
    background-image: url("../img/kv_slide05_sp.jpg");
  }
}
.p-kv02_slideCont.cont05 .p-kv02_slideCont--ttl {
  width: 60%;
  min-width: 70rem;
  top: calc(50% + 1rem);
  left: 50%;
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 767px) {
  .p-kv02_slideCont.cont05 .p-kv02_slideCont--ttl {
    width: 33.6rem;
    min-width: 0;
    top: calc(50% + 0.5rem);
  }
}

.p-kv03 {
  padding-bottom: 2rem;
}
.p-kv03_slide {
  width: 100%;
  display: flex;
  position: relative;
}
.p-kv03_slide.slide01 {
  left: 0;
}
.p-kv03_slide.slide02 {
  left: -100%;
}
.p-kv03_slide.slide03 {
  left: -200%;
}
.p-kv03_slide.slide04 {
  left: -300%;
}
.p-kv03_slide.slide05 {
  left: -400%;
}
.p-kv03_slide.slide06 {
  left: -500%;
}
.p-kv03_slide.is-slider {
  transition: left 1s;
}
.p-kv03_slide--item {
  min-width: 100%;
}
.p-kv03_slide--item a {
  width: 100%;
}
.p-kv03_slideCont {
  width: 100%;
  height: 100vh;
  display: inline-block;
}
.p-kv03_slideCont a {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: block;
  transform: scale(1);
  transition: transform 1s;
  position: relative;
  overflow: hidden;
}
.p-kv03_slideCont a:hover {
  opacity: 1;
}
.p-kv03_slideCont a.is-animation {
  transform: scale(0.867);
}
@media screen and (max-width: 767px) {
  .p-kv03_slideCont a.is-animation {
    transform: scale(0.972);
  }
}
.p-kv03_slideCont--ttl {
  position: absolute;
  top: 50%;
}
@media screen and (max-width: 767px) {
  .p-kv03_slideCont--ttl {
    top: auto;
  }
}
.p-kv03_slideCont--btn {
  width: 14.85%;
  position: absolute;
  bottom: 0;
  right: 8.57%;
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-kv03_slideCont--btn {
    width: 20%;
  }
}
@media screen and (max-width: 767px) {
  .p-kv03_slideCont--btn {
    width: 17rem;
    right: -0.7rem;
    bottom: -0.9rem;
  }
}
.p-kv03_slideCont.cont01 a {
  background-image: url("../img/kv_slide01.jpg");
}
@media screen and (max-width: 1025px) {
  .p-kv03_slideCont.cont01 a {
    background-image: url("../img/kv_slide01_sp.jpg");
  }
}
.p-kv03_slideCont.cont01 .ttl01 {
  width: 27.78%;
  left: 8.57%;
}
@media screen and (min-width: 768px) {
  .p-kv03_slideCont.cont01 .ttl01 {
    transform: translateY(-98%);
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-kv03_slideCont.cont01 .ttl01 {
    width: 40%;
  }
}
@media screen and (max-width: 767px) {
  .p-kv03_slideCont.cont01 .ttl01 {
    width: 25.7rem;
    left: 1.4rem;
    top: 9.5rem;
  }
}
.p-kv03_slideCont.cont01 .ttl02 {
  width: 23.42%;
  right: 9.78%;
}
@media screen and (min-width: 768px) {
  .p-kv03_slideCont.cont01 .ttl02 {
    transform: translateY(-136%);
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-kv03_slideCont.cont01 .ttl02 {
    width: 30%;
  }
}
@media screen and (max-width: 767px) {
  .p-kv03_slideCont.cont01 .ttl02 {
    width: 17.6rem;
    bottom: 8.5rem;
    right: 1.6rem;
  }
}
.p-kv03_slideCont.cont02 a {
  background-image: url("../img/kv_slide02.jpg");
}
@media screen and (max-width: 1025px) {
  .p-kv03_slideCont.cont02 a {
    background-image: url("../img/kv_slide02_sp.jpg");
  }
}
.p-kv03_slideCont.cont02 .ttl01 {
  width: 58.57%;
  left: 21.64%;
}
@media screen and (min-width: 768px) {
  .p-kv03_slideCont.cont02 .ttl01 {
    transform: translateY(-41.17%);
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-kv03_slideCont.cont02 .ttl01 {
    width: 65%;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  .p-kv03_slideCont.cont02 .ttl01 {
    width: 33rem;
    top: 50%;
    left: 3rem;
    transform: translateY(-50%);
  }
}
.p-kv03_slideCont.cont03 a {
  background-image: url("../img/kv_slide03.jpg");
}
@media screen and (max-width: 1025px) {
  .p-kv03_slideCont.cont03 a {
    background-image: url("../img/kv_slide03_sp.jpg");
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-kv03_slideCont.cont03 a {
    background-position: center top;
  }
}
@media screen and (max-width: 767px) {
  .p-kv03_slideCont.cont03 a {
    background-position: center bottom;
  }
}
.p-kv03_slideCont.cont03 .ttl01 {
  width: 22.14%;
  margin: 0 auto;
  left: 0;
  right: 0;
}
@media screen and (min-width: 768px) {
  .p-kv03_slideCont.cont03 .ttl01 {
    transform: translateY(-38.72%);
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-kv03_slideCont.cont03 .ttl01 {
    width: 30%;
  }
}
@media screen and (max-width: 767px) {
  .p-kv03_slideCont.cont03 .ttl01 {
    width: 17.3rem;
    bottom: 9.7rem;
  }
}
.p-kv03_slideCont.cont04 a {
  background-image: url("../img/kv_slide04.jpg");
}
@media screen and (max-width: 1025px) {
  .p-kv03_slideCont.cont04 a {
    background-image: url("../img/kv_slide04_sp.jpg");
  }
}
.p-kv03_slideCont.cont04 .ttl01 {
  width: 65.5%;
  left: 17.85%;
}
@media screen and (min-width: 768px) {
  .p-kv03_slideCont.cont04 .ttl01 {
    transform: translateY(-37%);
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-kv03_slideCont.cont04 .ttl01 {
    width: 75%;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  .p-kv03_slideCont.cont04 .ttl01 {
    width: 38.4rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.p-kv03_slideCont.cont05 a {
  background-image: url("../img/kv_slide05.jpg");
}
@media screen and (max-width: 1025px) {
  .p-kv03_slideCont.cont05 a {
    background-image: url("../img/kv_slide05_sp.jpg");
  }
}
.p-kv03_slideCont.cont05 .ttl01 {
  width: 61.42%;
  margin: 0 auto;
  left: 0;
  right: 0;
}
@media screen and (min-width: 768px) {
  .p-kv03_slideCont.cont05 .ttl01 {
    transform: translateY(-11.97%);
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-kv03_slideCont.cont05 .ttl01 {
    width: 70%;
  }
}
@media screen and (max-width: 767px) {
  .p-kv03_slideCont.cont05 .ttl01 {
    width: 36.5rem;
    right: auto;
    left: 1rem;
    top: 50%;
    transform: translateY(-2.2rem);
  }
}

.p-skinlift_logo {
  display: block;
  margin: auto;
  width: 46rem;
  max-width: 60%;
  height: auto;
}
.p-skinlift_title {
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .p-skinlift_title {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-skinlift_title span {
    letter-spacing: 0.2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-skinlift_title span {
    font-feature-settings: "palt";
    letter-spacing: 0;
    padding: 0 0.5rem 1rem;
  }
}
.p-skinlift_lead {
  margin-top: 5rem;
  line-height: 1.6;
}
@media screen and (max-width: 767px) {
  .p-skinlift_lead {
    font-feature-settings: "palt";
    margin-top: 3rem;
  }
}
.p-skinlift_slide {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .p-skinlift_slide {
    margin-top: 3rem;
  }
}
.p-skinlift_text {
  margin-top: 6rem;
}
@media screen and (max-width: 767px) {
  .p-skinlift_text {
    margin-top: 3rem;
  }
}

.p-kinmaku_block_ttl {
  margin-top: 6rem;
}
@media screen and (max-width: 767px) {
  .p-kinmaku_block_ttl {
    margin-top: 3rem;
  }
}
.p-kinmaku_block_txt {
  margin-top: 4rem;
  letter-spacing: 0.1rem;
}
@media screen and (max-width: 767px) {
  .p-kinmaku_block_txt {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .p-kinmaku_block_cap {
    text-indent: -7em;
    padding-left: 7em;
  }
}
.p-kinmaku_block_column {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .p-kinmaku_block_column {
    margin-top: 2rem;
  }
}
.p-kinmaku_block_img {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .p-kinmaku_block_img {
    margin-top: 2rem;
  }
}
.p-kinmaku_block_subttl {
  display: block;
  font-size: 1.8rem;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .p-kinmaku_block_subttl {
    font-size: 1.6rem;
  }
}
@media screen and (min-width: 768px) {
  .p-kinmaku .pc.pc--i {
    display: inline;
  }
}

.p-effect_img {
  display: block;
  height: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.p-research_point {
  font-size: 2rem;
  line-height: 1.75;
  font-weight: bold;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-research_point {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .p-research_point {
    font-size: 1.6rem;
  }
}
.p-research_annotation {
  position: relative;
}
.p-research_annotation::after {
  content: "(Medicell)";
  position: absolute;
  font-size: 1.6rem;
  text-align: center;
  font-weight: bold;
  bottom: -1.5em;
  left: 0;
  right: 0;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-research_annotation::after {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .p-research_annotation::after {
    font-size: 1.2rem;
  }
}
.p-research_box_ttl {
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-research_box_ttl {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 767px) {
  .p-research_box_ttl {
    font-size: 1.8rem;
  }
}
.p-research_box_img {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  height: auto;
  display: block;
}
.p-research_btn {
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.p-research_btn .c-btn_cmn::after {
  transform: rotate(135deg);
}
.p-research_btn .p-research_btn_cmn--open {
  display: block;
}
.p-research_btn .p-research_btn_cmn--close {
  display: none;
}
.p-research_btn.is-active .p-research_btn_cmn--open {
  display: none;
}
.p-research_btn.is-active .p-research_btn_cmn--close {
  display: block;
}
.p-research_btn.is-active .p-research_btn_cmn--close::after {
  transform: rotate(-45deg);
}

.p-career {
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-careerContents_text {
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  .p-careerContents_text p:not(:first-child) {
    margin-top: 1rem;
  }
}
.p-careerContents_img {
  max-width: 56.3rem;
  width: 100%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-careerContents_img {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .p-careerCurriculum_text {
    text-align: left;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-careerCurriculumDetail_list {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-careerCurriculumDetail_list--item {
    max-width: none;
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-careerCurriculumDetail_list--item:not(:first-child) {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .p-careerCurriculumDetail_list--item:not(:first-child) {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .p-careerCurriculumDetail_list--pic {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .p-careerExamination .c-mt--40 {
    margin-top: 2rem;
  }
}
.p-careerSpecial_ttl--sub span {
  display: inline-block;
  position: relative;
}
.p-careerSpecial_ttl--sub span:before, .p-careerSpecial_ttl--sub span:after {
  content: "";
  width: 0.4rem;
  height: 3rem;
  background-color: #D30F1B;
  position: absolute;
  top: 1rem;
}
@media screen and (max-width: 767px) {
  .p-careerSpecial_ttl--sub span:before, .p-careerSpecial_ttl--sub span:after {
    width: 0.2rem;
    height: 2rem;
    top: 0.6rem;
  }
}
.p-careerSpecial_ttl--sub span:before {
  transform: rotate(-25deg);
  left: -2rem;
}
@media screen and (max-width: 767px) {
  .p-careerSpecial_ttl--sub span:before {
    left: -1.5rem;
  }
}
.p-careerSpecial_ttl--sub span:after {
  transform: rotate(25deg);
  right: -2rem;
}
@media screen and (max-width: 767px) {
  .p-careerSpecial_ttl--sub span:after {
    right: -1.5rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-careerPrice_list {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-careerPrice_list--item {
    max-width: 40rem;
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-careerPrice_list--item:last-child {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .p-careerPrice_list--item:last-child {
    margin-top: 2rem;
  }
}
.p-careerPrice_list--btn a:after {
  right: 1.5rem;
}
@media screen and (max-width: 767px) {
  .p-careerPrice_btn--text {
    letter-spacing: 0;
  }
}
.p-careerSession_ttl span {
  font-size: 3.2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-careerSession_ttl span {
    font-size: 2.4rem;
  }
}
.p-careerSession_list--item {
  padding-left: 4rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-careerSession_list--item {
    padding-left: 2.5rem;
  }
}
.p-careerSession_list--item span {
  font-size: 2.4rem;
  line-height: 1;
  position: absolute;
  top: 0.2rem;
  left: 0;
}
@media screen and (max-width: 767px) {
  .p-careerSession_list--item span {
    font-size: 1.8rem;
    top: 0.3rem;
  }
}
.p-careerSession_list--item:not(:first-child) {
  margin-top: 3rem;
}
.p-careerSession_btn {
  max-width: 40rem;
  width: 100%;
  margin: 6rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-careerSession_btn {
    margin-top: 4rem;
  }
}
.p-careerSession_code--img {
  width: 19.5rem;
  margin: 2rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-careerSession_code--img {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 900px) {
  .p-careerFaq_btnList {
    display: block;
  }
}
.p-careerFaq_btnList--item.mail {
  max-width: 51.4rem;
  width: 54%;
}
@media screen and (max-width: 900px) {
  .p-careerFaq_btnList--item.mail {
    width: 100%;
    margin: 0 auto;
  }
}
.p-careerFaq_btnList--item.mail .c-btn_white--text {
  font-size: 1.8rem;
}
@media screen and (max-width: 767px) {
  .p-careerFaq_btnList--item.mail .c-btn_white--text {
    font-size: 1.4rem;
  }
}
.p-careerFaq_btnList--item.tel {
  max-width: 40rem;
  width: 41%;
}
@media screen and (max-width: 900px) {
  .p-careerFaq_btnList--item.tel {
    width: 100%;
    margin: 3rem auto 0;
  }
}
.p-career_ico {
  position: fixed;
  bottom: 25rem;
  right: 9rem;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.25s ease;
  pointer-events: none;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-career_ico {
    bottom: 18rem;
    right: 4.5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-career_ico {
    right: 1.5rem;
    bottom: 14rem;
  }
}
.p-career_ico a {
  width: 15rem;
  height: 15rem;
  border-radius: 1.4rem;
  display: block;
  overflow: hidden;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.26);
  transform: rotate(-45deg);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-career_ico a {
    width: 10rem;
    height: 10rem;
    border-radius: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .p-career_ico a {
    width: 7rem;
    height: 7rem;
    border-radius: 0.7rem;
  }
}
.p-career_ico.is-view {
  opacity: 1;
  pointer-events: auto;
}
.p-career_ico.is-absolute {
  position: absolute;
  bottom: 13rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-career_ico.is-absolute {
    bottom: 8rem;
  }
}
@media screen and (max-width: 767px) {
  .p-career_ico.is-absolute {
    bottom: 6rem;
  }
}

.p-minipro8Intro_logo {
  max-width: 56.7rem;
  width: 50%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-minipro8Intro_logo {
    max-width: none;
    width: 20rem;
  }
}
.p-minipro8Intro_logo img {
  width: 100%;
}
.p-minipro8Intro_text {
  line-height: 1.666;
}
.p-minipro8Intro_movie--pic {
  max-width: 78rem;
  width: 67%;
  margin: 0 auto;
  border-radius: 3rem;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.16);
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .p-minipro8Intro_movie--pic {
    max-width: none;
    width: 100%;
    border-radius: 1.5rem;
    box-shadow: 0.1rem 0.15rem 1rem rgba(0, 0, 0, 0.16);
  }
}
@media screen and (max-width: 767px) {
  .p-minipro8Dtail_ttl span {
    padding: 0 0 1.5rem;
  }
}
.p-minipro8Dtail_cap {
  text-indent: -7.5em;
  padding-left: 7.5em;
}
@media screen and (max-width: 767px) {
  .p-minipro8Dtail_cap {
    padding-left: 0;
    text-indent: 0;
  }
}
.p-minipro8Effect_ttl img {
  width: auto;
  height: 5.25rem;
  margin-right: 1rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-minipro8Effect_ttl img {
    height: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .p-minipro8Effect_ttl img {
    height: 3rem;
  }
}
@media screen and (max-width: 767px) {
  .p-minipro8Effect_link {
    display: block;
  }
}
.p-minipro8Effect_link--item {
  max-width: 42rem;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .p-minipro8Effect_link--item {
    max-width: none;
    width: 100%;
  }
}
.p-minipro8Effect_link--item:last-child {
  margin-left: 4rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-minipro8Effect_link--item:last-child {
    margin-left: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-minipro8Effect_link--item:last-child {
    margin: 2rem 0 0;
  }
}
.p-minipro8Effect_link--text {
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-minipro8Effect_link--text {
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .p-minipro8Effect_link--text {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
  }
}
.p-minipro8Effect_link--btn {
  max-width: 40rem;
  width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .p-hybridIntro_logo {
    width: 25rem;
  }
}
.p-hybridEffect_ttl img {
  width: auto;
  height: 5.72rem;
  vertical-align: baseline;
  margin-right: 1rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-hybridEffect_ttl img {
    height: 4.5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-hybridEffect_ttl img {
    height: 3.5rem;
  }
}

@media screen and (max-width: 767px) {
  .p-optionCap_ttl {
    font-size: 1.6rem;
  }
}
.p-optionCap_ttl span {
  margin-right: 2rem;
}
@media screen and (max-width: 900px) {
  .p-optionTool_flag--ttl .space {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .p-optionContact_ttl {
    font-size: 1.6rem;
  }
}
.p-optionContact_btn {
  max-width: 40rem;
  width: 100%;
  margin: 1.5rem auto 0;
}

.p-introduction .c-boxNum_text {
  padding-left: 0;
}
.p-introduction .c-text_indent {
  padding-left: 1em;
}
@media screen and (max-width: 767px) {
  .p-introduction_list--item:last-child {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-introduction_list--text {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) {
  .p-mechanism_ttl span {
    padding: 0 0.5rem 1rem;
  }
}

.p-treatmentIntro_list--item {
  width: 30.5%;
  margin-right: 4.25%;
}
@media screen and (max-width: 767px) {
  .p-treatmentIntro_list--item {
    width: 48%;
    margin-right: 0;
  }
}
.p-treatmentIntro_list--item:not(:nth-child(-n+3)) {
  margin-top: 3.5rem;
}
@media screen and (max-width: 767px) {
  .p-treatmentIntro_list--item:nth-child(2n) {
    margin-left: 4%;
  }
}
@media screen and (max-width: 767px) {
  .p-treatmentIntro_list--item:not(:nth-child(-n+2)) {
    margin-top: 2rem;
  }
}
.p-treatmentIntro_list--item.right {
  margin-right: 0;
}
.p-treatmentIntro_list--pic {
  box-shadow: 0.3rem 0.6rem 2rem rgba(0, 0, 0, 0.3);
}
@media screen and (max-width: 767px) {
  .p-treatmentIntro_list--pic {
    box-shadow: 0.15rem 0.3rem 1rem rgba(0, 0, 0, 0.3);
  }
}
.p-treatmentExercise_ttl {
  font-size: 4rem;
}

@media screen and (max-width: 767px) {
  .p-facilitySearch_list {
    display: flex;
    justify-content: space-between;
  }
}
.p-facilitySearch_list--item {
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-facilitySearch_list--item {
    width: 48%;
  }
}
.p-facilitySearch_list--item:nth-child(-n+2) {
  margin-top: 0 !important;
}
.p-facilitySearch_list--wrap {
  width: 100%;
  border-top: 0.2rem solid #DDD;
  position: absolute;
  top: 7rem;
  left: 0;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .p-facilitySearch_list--wrap {
    top: 5rem;
  }
}
.p-facilitySearch_list--scroll {
  width: 100%;
  max-height: 30rem;
  overflow: scroll;
  scrollbar-width: thin;
}
@media screen and (max-width: 767px) {
  .p-facilitySearch_list--scroll {
    max-height: 20rem;
  }
}
.p-facilitySearch_list--scroll::-webkit-scrollbar {
  width: 1.1rem;
  height: 0;
}
@media screen and (max-width: 767px) {
  .p-facilitySearch_list--scroll::-webkit-scrollbar {
    width: 0.5rem;
  }
}
.p-facilitySearch_list--scroll::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 0.6rem;
}
@media screen and (max-width: 767px) {
  .p-facilitySearch_list--scroll::-webkit-scrollbar-thumb {
    border-radius: 0.3rem;
  }
}
.p-facilitySearch_district {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: absolute;
  top: 0;
  right: 100%;
  display: none;
  scrollbar-width: thin;
}
@media screen and (max-width: 767px) {
  .p-facilitySearch_district {
    right: auto;
    left: 100%;
  }
}
.p-facilitySearch_district.osaka {
  right: auto;
  left: 100%;
}
@media screen and (min-width: 768px) {
  .p-facilitySearch_district.osaka {
    padding-left: 1.1rem;
    left: calc(100% - 1.1rem);
  }
}
@media screen and (max-width: 767px) {
  .p-facilitySearch_district.osaka {
    right: 100%;
    left: auto;
  }
}
.p-facilitySearch_district.is-view {
  display: block;
}
.p-facilitySearch_district::-webkit-scrollbar {
  width: 1.1rem;
  height: 0;
}
@media screen and (max-width: 767px) {
  .p-facilitySearch_district::-webkit-scrollbar {
    width: 0.5rem;
  }
}
.p-facilitySearch_district::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 0.6rem;
}
@media screen and (max-width: 767px) {
  .p-facilitySearch_district::-webkit-scrollbar-thumb {
    border-radius: 0.3rem;
  }
}
.p-facilitySearch_district .c-btn_search--sub {
  border-bottom: 0.2rem solid #fff;
}
.p-facilitySearch_district .c-btn_search--sub:hover {
  background-color: #c0c0c0;
}
.p-facilitySearch_district .c-btn_search--sub.is-selected {
  background-color: #c0c0c0;
}
.p-facility_box {
  border-bottom: 0.2rem solid #DDD;
}
.p-facility_box--ttl {
  padding: 0 4rem;
}
@media screen and (max-width: 767px) {
  .p-facility_box--ttl {
    padding: 0 2rem;
  }
}
.p-facility_sec {
  padding: 120px 0 0;
}
@media screen and (max-width: 767px) {
  .p-facility_sec {
    padding: 6rem 0 0;
  }
}
.p-facilityCont .p-facilityPets .c-btn_link {
  position: relative;
  width: auto;
  height: 7rem;
  padding: 0 4em 0 2em;
}
@media screen and (max-width: 767px) {
  .p-facilityCont .p-facilityPets .c-btn_link {
    padding-left: 7.5rem;
  }
}
.p-facilityCont .p-facilityPets .c-btn_link:after {
  content: "";
  width: 1.3rem;
  height: 0.8rem;
  margin: auto 0;
  background: url("../img/common/arrow_index01.svg") no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 7%;
  transform: rotate(-90deg);
}
.p-facilityCont .p-facilityPets .c-btn_link .c-boxMedicell_top--ico {
  position: static;
  transform: translateY(0);
}
@media screen and (max-width: 767px) {
  .p-facilityCont .p-facilityPets .c-btn_link .c-boxMedicell_top--ico {
    position: absolute;
    transform: translateY(-50%);
  }
}
.p-facilityCont .p-facilityPets .c-btn_link .c-boxMedicell_top--ico img {
  width: 40px;
}
@media screen and (max-width: 767px) {
  .p-facilityCont .p-facilityPets .c-btn_link .c-boxMedicell_top--ico img {
    width: auto;
  }
}
.p-facilityCont .p-facilityPets .c-btn_link .c-boxMedicell_top--ttl {
  width: 18em;
}
@media screen and (max-width: 767px) {
  .p-facilityCont .p-facilityPets .c-btn_link .c-boxMedicell_top--ttl {
    width: auto;
  }
}
.p-facilityContPets .c-ttl_line {
  justify-content: center;
}
.p-facilityContPets .c-ttl_line img {
  width: 100px;
  height: auto;
}
@media screen and (max-width: 767px) {
  .p-facilityContPets .c-ttl_line img {
    width: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .p-facilityContPets .p-facilityPets .c-btn_link {
    padding-left: 2em;
  }
}
.p-facilityContPets .p-facilityPets .c-btn_link .c-boxMedicell_top--ttl {
  width: auto;
}

.p-sportsCase_movie--pic {
  max-width: 40rem;
  width: 100%;
  margin: 2.5rem auto 0;
}

@media screen and (max-width: 767px) {
  .p-kinmakubiryoIntro_ttl span {
    font-size: 2.8rem;
    letter-spacing: 0.1rem;
    padding: 0 0rem 1.5rem;
  }
}
.p-kinmakubiryoIntro_about--pic {
  max-width: 40.7rem;
  width: 100%;
  margin: 4rem auto 0;
  border-radius: 3rem;
  overflow: hidden;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 767px) {
  .p-kinmakubiryoIntro_about--pic {
    margin-top: 3rem;
    box-shadow: 0.1rem 0.15rem 1rem rgba(0, 0, 0, 0.16);
    border-radius: 1.5rem;
  }
}
.p-kinmakubiryoIntro_about--text {
  line-height: 1.75;
}
.p-kinmakubiryoExercise_text {
  line-height: 1.666;
}

.p-petsIntro_movie--text {
  line-height: 1.7;
}
.p-petsIntro_movie--pic {
  max-width: 40.7rem;
  width: 100%;
  margin: 1.5rem auto 0;
  border-radius: 2rem;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .p-petsIntro_movie--pic {
    border-radius: 1rem;
  }
}

.p-racehorcesHuman_text {
  line-height: 1.666;
}
@media screen and (max-width: 767px) {
  .p-racehorcesHuman_caption {
    text-align: left;
    text-indent: -1em;
    padding-left: 1em;
  }
}
.p-racehorcesReport_cont {
  padding: 8rem 4rem;
}
@media screen and (max-width: 767px) {
  .p-racehorcesReport_cont {
    padding: 3rem 2rem;
  }
}
.p-racehorcesReport_cont--ttl {
  line-height: 1.875;
}
.p-racehorcesReport_cont--detail {
  line-height: 1.5;
}
@media screen and (max-width: 767px) {
  .p-racehorcesReport_cont--detail {
    font-size: 1.4rem;
  }
}
.p-racehorcesReport_movie {
  max-width: 85rem;
  width: 100%;
  margin: 8rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-racehorcesReport_movie {
    margin-top: 4rem;
  }
}
.p-racehorcesReport_movie--item {
  width: 47.8%;
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 767px) {
  .p-racehorcesReport_movie--item {
    border-radius: 1.5rem;
    box-shadow: 0.1rem 0.15rem 1rem rgba(0, 0, 0, 0.16);
  }
}
@media screen and (max-width: 767px) {
  .p-racehorcesReport_movie--item:not(:first-child) {
    margin-top: 2rem;
  }
}
.p-racehorcesReport_movie3 {
  width: 100%;
  margin: 8rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-racehorcesReport_movie3 {
    margin-top: 4rem;
  }
}
.p-racehorcesReport_movie3--item {
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 767px) {
  .p-racehorcesReport_movie3--item {
    border-radius: 1.5rem;
    box-shadow: 0.1rem 0.15rem 1rem rgba(0, 0, 0, 0.16);
  }
}
@media screen and (max-width: 767px) {
  .p-racehorcesReport_movie3--item:not(:first-child) {
    margin-top: 2rem;
  }
}
.p-racehorcesReport_movie3--item p {
  padding: 0.5em;
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-companyLocal .c-btn_cmn {
    padding-right: 3%;
  }
}
@media screen and (max-width: 767px) {
  .p-companyLocal .c-btn_cmn {
    padding-right: 0.5rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-companyLocal .c-btn_cmn:after {
    right: 5%;
  }
}
@media screen and (max-width: 767px) {
  .p-companyLocal .c-btn_cmn:after {
    width: 0.6rem;
    height: 0.6rem;
    margin-top: -0.4rem;
    right: 1rem;
  }
}
.p-companyPhilosophy_cont {
  width: 100%;
  height: 14rem;
}
@media screen and (max-width: 767px) {
  .p-companyPhilosophy_cont {
    height: 10rem;
  }
}
.p-companyPhilosophy_cont--ttl {
  font-size: 6rem;
  line-height: 14rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-companyPhilosophy_cont--ttl {
    font-size: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .p-companyPhilosophy_cont--ttl {
    font-size: 3rem;
    line-height: 10rem;
  }
}
.p-companySlogan_pic {
  max-width: 24.2rem;
  width: 75%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-companySlogan_pic {
    max-width: none;
    width: 18rem;
    margin-top: 3rem;
  }
}
.p-companyMission_text {
  line-height: 1.5;
}
@media screen and (max-width: 900px) {
  .p-companyMessage_wrap {
    display: block;
  }
}
.p-companyMessage_wrap--right {
  width: 37.7%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-companyMessage_wrap--right {
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .p-companyMessage_wrap--right {
    width: 22rem;
    margin: 0 auto;
  }
}
.p-companyMessage_wrap--left {
  width: 57.4%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-companyMessage_wrap--left {
    width: 100%;
    margin-top: 5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-companyMessage_wrap--left {
    width: 100%;
    margin-top: 3rem;
  }
}
.p-companyMessage_pic {
  position: relative;
}
.p-companyMessage_name {
  position: absolute;
  bottom: -2rem;
  left: -3rem;
}
@media screen and (max-width: 767px) {
  .p-companyMessage_name {
    bottom: -1rem;
    left: -2rem;
  }
}
.p-companyMessage_name--box {
  width: 13rem;
  height: 4rem;
}
@media screen and (max-width: 767px) {
  .p-companyMessage_name--box {
    width: 10rem;
    height: 3rem;
  }
}
.p-companyMessage_name--boxText {
  font-size: 1.6rem;
  line-height: 4rem;
}
@media screen and (max-width: 767px) {
  .p-companyMessage_name--boxText {
    font-size: 1.4rem;
    line-height: 3rem;
  }
}
.p-companyMessage_name--position {
  width: 14rem;
}
@media screen and (max-width: 767px) {
  .p-companyMessage_name--position {
    width: 12rem;
  }
}
.p-companyBright_logo {
  width: 60%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-companyBright_logo {
    width: 100%;
    margin-top: 3rem;
  }
}
.p-companyBright_pic {
  width: 36.8%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-companyBright_pic {
    width: 75%;
    margin-top: 3rem;
  }
}
.p-companyHistory {
  padding-bottom: 18.5rem;
}
@media screen and (max-width: 767px) {
  .p-companyHistory {
    padding-bottom: 8rem;
  }
}

.p-recruit_btn {
  width: 23%;
  position: absolute;
  top: 0;
  right: -3.1%;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .p-recruit_btn {
    width: 12rem;
    right: -1.5rem;
  }
}
.p-recruitLocal_list {
  max-width: 74.6rem;
  margin: 0 auto;
}
.p-recruitLocal_list--item {
  width: 31%;
  margin-right: 3.5%;
}
@media screen and (max-width: 767px) {
  .p-recruitLocal_list--item {
    margin-right: 0;
  }
}
.p-recruitLocal_list--item:nth-child(3n) {
  margin-right: 0;
}
.p-recruitLocal_list--item.last {
  margin-right: 0;
}
.p-recruitLocal_list--item:not(:nth-child(-n+3)) {
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .p-recruitLocal_list--item .c-btn_white--text {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 767px) {
  .p-recruitMessage_ttl span {
    letter-spacing: 0.1rem;
  }
}
.p-recruitMessage_wrap {
  position: relative;
}
.p-recruitMessage_box {
  width: 60.5%;
  background-color: rgba(255, 255, 255, 0.6);
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 900px) {
  .p-recruitMessage_box {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-recruitMessage_box {
    margin-top: -4rem;
  }
}
@media screen and (max-width: 767px) {
  .p-recruitMessage_box {
    margin-top: -2rem;
  }
}
.p-recruitMessage_pic {
  width: 54%;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
@media screen and (max-width: 900px) {
  .p-recruitMessage_pic {
    width: 65%;
    margin: 0 auto;
    position: relative;
    right: auto;
    top: auto;
    transform: translateY(0);
  }
}
@media screen and (max-width: 767px) {
  .p-recruitAbout_wrap {
    display: block;
  }
}
.p-recruitAbout_wrap--left {
  width: 42.4%;
}
@media screen and (max-width: 767px) {
  .p-recruitAbout_wrap--left {
    width: 25rem;
    margin: 0 auto;
  }
}
.p-recruitAbout_wrap--right {
  width: 53.4%;
}
@media screen and (max-width: 767px) {
  .p-recruitAbout_wrap--right {
    width: 100%;
    margin-top: 2rem;
  }
}
.p-recruitAbout_pic {
  border-radius: 2rem;
  overflow: hidden;
  box-shadow: 0.2rem 0.3rem 2rem rgba(0, 0, 0, 0.16);
}
@media screen and (max-width: 767px) {
  .p-recruitAbout_pic {
    box-shadow: 0.1rem 0.15rem 1rem rgba(0, 0, 0, 0.16);
  }
}
.p-recruitCandidate_list {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .p-recruitCandidate_list {
    display: block;
  }
}
.p-recruitCandidate_list--item {
  max-width: 40rem;
  width: 41.6%;
}
@media screen and (max-width: 767px) {
  .p-recruitCandidate_list--item {
    width: 100%;
  }
}
.p-recruitCandidate_list--item:first-child {
  margin-right: 3rem;
}
@media screen and (max-width: 767px) {
  .p-recruitCandidate_list--item:first-child {
    margin: 0 0 2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-recruitCandidate_list--bnr {
    width: 20rem;
    margin: 1.5rem auto 0;
  }
}
@media screen and (max-width: 767px) {
  .p-recruitCandidate_contWrap {
    display: block;
  }
}
.p-recruitCandidate_contWrap:first-child {
  padding-bottom: 8rem;
  border-bottom: 0.2rem solid #DDD;
}
@media screen and (max-width: 767px) {
  .p-recruitCandidate_contWrap:first-child {
    padding-bottom: 4rem;
  }
}
.p-recruitCandidate_contWrap:nth-child(2) {
  padding-bottom: 8rem;
  border-bottom: 0.2rem solid #DDD;
}
@media screen and (max-width: 767px) {
  .p-recruitCandidate_contWrap:nth-child(2) {
    padding-bottom: 4rem;
  }
}
.p-recruitCandidate_contWrap--left {
  width: 40%;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-recruitCandidate_contWrap--left {
    width: 25rem;
    margin: 0 auto;
  }
}
.p-recruitCandidate_contWrap--right {
  width: 56.4%;
}
@media screen and (max-width: 767px) {
  .p-recruitCandidate_contWrap--right {
    width: 100%;
    margin-top: 4rem;
  }
}
.p-recruitCandidate_cont--pic {
  padding-left: 2rem;
}
.p-recruitCandidate_cont--box {
  position: absolute;
  bottom: -2rem;
  left: 0;
}
.p-recruitCandidate_cont--position {
  font-size: 1.6rem;
  width: 19.6rem;
  height: 4rem;
  line-height: 4rem;
  display: block;
}
@media screen and (max-width: 767px) {
  .p-recruitCandidate_cont--position {
    font-size: 1.2rem;
    width: 14rem;
    height: 3rem;
    line-height: 3rem;
  }
}
.p-recruitCandidate_cont--name {
  font-size: 1.8rem;
  width: 13rem;
  height: 4rem;
  line-height: 4rem;
  margin-top: 1rem;
  display: block;
}
@media screen and (max-width: 767px) {
  .p-recruitCandidate_cont--name {
    font-size: 1.4rem;
    width: 10rem;
    height: 3rem;
    line-height: 3rem;
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 767px) {
  .p-recruitWork_list {
    display: block;
  }
}
.p-recruitWork_list--item {
  width: 30%;
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-recruitWork_list--item {
    width: 48%;
  }
}
@media screen and (max-width: 767px) {
  .p-recruitWork_list--item {
    width: 100%;
  }
}
.p-recruitWork_list--item:not(:nth-child(3n)) {
  margin-right: 5%;
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-recruitWork_list--item:not(:nth-child(3n)) {
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-recruitWork_list--item:nth-child(2n) {
    margin-left: 4%;
  }
}
@media screen and (max-width: 767px) {
  .p-recruitWork_list--item:nth-child(2n) {
    margin-left: 0;
  }
}
.p-recruitWork_list--item:last-child {
  margin-right: 0;
}
.p-recruitWork_list--item:not(:nth-child(-n+3)) {
  margin-top: 4rem;
}
@media screen and (min-width: 768px) and (max-width: 1025px) {
  .p-recruitWork_list--item:not(:nth-child(-n+2)) {
    margin-top: 4rem;
  }
}
@media screen and (max-width: 767px) {
  .p-recruitWork_list--item:not(:first-child) {
    margin-top: 3rem;
  }
}
.p-recruitEntry {
  position: relative;
}
.p-recruitEntry_inner {
  padding: 10rem 0;
}
@media screen and (max-width: 767px) {
  .p-recruitEntry_inner {
    padding: 5rem 0;
  }
}
@media screen and (max-width: 767px) {
  .p-recruitEntry_list {
    display: block;
  }
}
.p-recruitEntry_list--item {
  transform: translateY(1.7rem);
}
@media screen and (max-width: 767px) {
  .p-recruitEntry_list--item {
    transform: translateY(0);
    margin-bottom: 3rem !important;
  }
}
.p-recruitEntry2 {
  position: relative;
  margin-bottom: 10rem;
}
.p-recruitEntry2_inner {
  padding: 10rem 0;
}
@media screen and (max-width: 767px) {
  .p-recruitEntry2_inner {
    padding: 5rem 0;
  }
}
.p-recruitEntry2 h2 {
  font-size: 5rem;
}
@media screen and (max-width: 767px) {
  .p-recruitEntry2 h2 {
    font-size: 2.4rem;
  }
}
.p-recruitEntry2_list {
  align-items: stretch;
}
@media screen and (max-width: 767px) {
  .p-recruitEntry2_list {
    display: block;
  }
}
.p-recruitEntry2_list--item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100rem;
  margin: 0 3rem;
  padding: 2rem;
}
@media screen and (max-width: 767px) {
  .p-recruitEntry2_list--item {
    width: 100%;
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  .p-recruitEntry2_list--item:first-of-type {
    margin-bottom: 3rem;
  }
}
.p-recruitSlogan_text {
  line-height: 1.44;
}
@media screen and (max-width: 767px) {
  .p-recruit .c-ttl_sub--en {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-contributionsIntro_list {
    display: block;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-contributionsIntro_list--item {
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 900px) {
  .p-contributionsIntro_list--item:last-child {
    margin-top: 3rem !important;
  }
}
@media screen and (max-width: 900px) {
  .p-contributionsGrop_wrap {
    display: block;
  }
}
.p-contributionsGrop_wrap--left {
  width: 36.5%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-contributionsGrop_wrap--left {
    width: 42rem;
    margin: 0 auto;
  }
}
@media screen and (max-width: 767px) {
  .p-contributionsGrop_wrap--left {
    width: 100%;
  }
}
.p-contributionsGrop_wrap--right {
  width: 59.5%;
}
@media screen and (max-width: 900px) {
  .p-contributionsGrop_wrap--right {
    width: 100%;
    margin-top: 2rem;
  }
}
.p-contributionsGrop_bnr--box {
  max-width: 48rem;
  width: 75%;
  margin: 1.5rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-contributionsGrop_bnr--box {
    width: 100%;
  }
}
.p-contributionsGrop_list--item:not(:nth-child(-n+2)) {
  margin-top: 4rem;
}
@media screen and (max-width: 767px) {
  .p-contributionsGrop_list--item:not(:first-child) {
    margin-top: 3rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-contributionsGrop_list--item .c-text_size--25 {
    font-size: 2rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-contributionsGrop_list--item .c-text_size--18 {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .p-contributionsGrop_list--item .c-text_size--18 {
    font-size: 1.2rem;
    line-height: 1.2;
  }
}
.p-contributionsGrop_list--item .c-boxSports_name {
  background-color: rgba(255, 255, 255, 0.8);
}
.p-contributionsPearls_pic {
  max-width: 78rem;
  width: 75%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .p-contributionsPearls_pic {
    width: 100%;
  }
}
.p-contributionsPearls_pic.pic02 {
  max-width: 52rem;
}
.p-contributionsPearls_bnr {
  max-width: 48rem;
  width: 75%;
  margin: 1.5rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-contributionsPearls_bnr {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .p-contributionsBmx_wrap {
    display: block;
  }
}
.p-contributionsBmx_wrap--center {
  width: 70%;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-contributionsBmx_wrap--center {
    width: 100%;
  }
}
.p-contributionsBmx_wrap--left {
  width: 43.4%;
  position: relative;
}
@media screen and (max-width: 767px) {
  .p-contributionsBmx_wrap--left {
    width: 100%;
  }
}
.p-contributionsBmx_wrap--right {
  width: 53.2%;
}
@media screen and (max-width: 767px) {
  .p-contributionsBmx_wrap--right {
    width: 100%;
    margin-top: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-contributionsBmx_pic {
    padding-bottom: 1rem;
  }
}
.p-contributionsBmx_name {
  font-size: 1.8rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  bottom: -2rem;
  right: -2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-contributionsBmx_name {
    font-size: 1.4rem;
    right: -1rem;
  }
}
@media screen and (max-width: 767px) {
  .p-contributionsBmx_name {
    font-size: 1.2rem;
    right: -1rem;
    bottom: 0;
  }
}
.p-contributionsBmx_name span {
  display: block;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-contributionsBmx_name span {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1025px) {
  .p-contributionsBmx_text {
    margin-top: 0;
  }
}
.p-contributionsTryhoop_pic {
  max-width: 36.5rem;
  width: 75%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-contributionsTryhoop_pic {
    width: 100%;
    margin-top: 3rem;
  }
}
.p-contributionsTryhoop_bnr {
  max-width: 49.5rem;
  width: 100%;
  margin: 1.5rem auto 0;
}
.p-contributionsVickies_pic {
  display: flex;
  width: 100%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-contributionsVickies_pic {
    flex-direction: column;
    width: 100%;
    margin-top: 3rem;
  }
}
.p-contributionsVickies_pic img {
  width: 33.3333333333%;
}
@media screen and (max-width: 767px) {
  .p-contributionsVickies_pic img {
    width: 100%;
    margin-top: 1rem;
  }
}
.p-contributionsVickies_bnr {
  max-width: 49.5rem;
  width: 100%;
  margin: 1.5rem auto 0;
}
.p-contributionsFagiano_pic {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-contributionsFagiano_pic {
    flex-direction: column;
    width: 100%;
    margin-top: 3rem;
  }
}
.p-contributionsFagiano_pic img {
  width: 100%;
  max-width: 28rem;
  height: auto;
}
@media screen and (max-width: 767px) {
  .p-contributionsFagiano_pic img {
    width: 100%;
    margin-top: 1rem;
  }
}
.p-contributionsFagiano_bnr {
  max-width: 42rem;
  width: 100%;
  margin: 1.5rem auto 0;
}
.p-contributionsOrca_pic {
  display: flex;
  width: 100%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-contributionsOrca_pic {
    flex-direction: column;
    width: 100%;
    margin-top: 3rem;
  }
}
.p-contributionsOrca_pic img {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .p-contributionsOrca_pic img {
    width: 100%;
    margin-top: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .p-contributions .c-ttl_sub--en {
    font-size: 3.2rem;
  }
}

.p-sdgs_inner:before {
  background-image: url("../img/common/sub_bg03.png");
}
.p-sdgsIntro {
  position: relative;
}
.p-sdgsIntro:before, .p-sdgsIntro:after {
  content: "";
  width: calc(100% - 10rem);
  height: 0.775rem;
  margin: 0 auto;
  background: url("../img/company/sdgs/intro_bg01.png") no-repeat center;
  background-size: auto 0.775rem;
  position: absolute;
  right: 0;
  left: 0;
}
@media screen and (max-width: 767px) {
  .p-sdgsIntro:before, .p-sdgsIntro:after {
    width: calc(100% - 4rem);
    height: 0.38rem;
    background-size: auto 0.38rem;
  }
}
.p-sdgsIntro:before {
  top: 4rem;
}
@media screen and (max-width: 767px) {
  .p-sdgsIntro:before {
    top: 2rem;
  }
}
.p-sdgsIntro:after {
  bottom: 4rem;
}
@media screen and (max-width: 767px) {
  .p-sdgsIntro:after {
    bottom: 2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-sdgsIntro_ttl span {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-sdgsIntro_text {
    margin-top: 2rem;
  }
}
.p-sdgsAbout_logo {
  width: 81.25%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-sdgsAbout_logo {
    width: 100%;
    margin-top: 3rem;
  }
}
.p-sdgsAbout_link {
  text-decoration: underline;
  word-break: break-all;
}
.p-sdgsAbout_link:after {
  content: "";
  width: 1.4rem;
  height: 1.4rem;
  background: url("../img/common/ico_link01.svg") no-repeat;
  background-size: contain;
  display: inline-block;
  position: relative;
  right: -1rem;
}

.p-news_list--item {
  border-color: #DDD;
}
.p-news_list--item .c-listNews_item--date {
  width: 14rem;
}
@media screen and (max-width: 767px) {
  .p-news_list--item .c-listNews_item--date {
    width: auto;
  }
}
.p-news_list--item .c-listNews_item--ttl {
  padding: 0 2.5rem;
}
@media screen and (max-width: 900px) {
  .p-news_list--item .c-listNews_item--ttl {
    padding: 0;
  }
}
.p-newsSeminar_img {
  max-width: 60rem;
  width: 60%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-newsSeminar_img {
    width: 100%;
    margin-top: 3rem;
  }
}
.p-newsSeminar_banner {
  max-width: 75rem;
  width: 75%;
  margin: 4rem auto 0;
}
@media screen and (max-width: 767px) {
  .p-newsSeminar_banner {
    width: 100%;
    margin-top: 3rem;
  }
}
.p-newsSeminar_banner p {
  margin-bottom: 2rem;
  font-size: 2.2rem;
}
.p-newsSeminar_movie--text {
  line-height: 1.7;
}
.p-newsSeminar_movie--pic {
  max-width: 50.7rem;
  width: 100%;
  margin: 1.5rem auto 0;
  border-radius: 2rem;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .p-newsSeminar_movie--pic {
    border-radius: 1rem;
  }
}
.p-newsSeminar .c-boxSeminar.members-only {
  position: relative;
}
.p-newsSeminar .c-boxSeminar.members-only::before {
  content: "協会員限定";
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  background-color: #D30F1B;
  padding: 0.5em 1em;
  font-size: 2.4rem;
  color: #fff;
  transform: translate(2rem, -50%);
}
@media screen and (max-width: 767px) {
  .p-newsSeminar .c-boxSeminar.members-only::before {
    font-size: 1.4rem;
    transform: translate(1rem, -50%);
  }
}
@media screen and (max-width: 767px) {
  .p-newsSeminarLink_list {
    justify-content: space-between;
  }
}
.p-newsSeminarLink_list--item {
  width: 47.5%;
}
@media screen and (max-width: 767px) {
  .p-newsSeminarLink_list--item {
    width: 100%;
  }
}
.p-newsSeminarLink_list--item:not(:nth-child(even)) {
  margin-right: 5%;
}
@media screen and (max-width: 767px) {
  .p-newsSeminarLink_list--item:not(:nth-child(even)) {
    margin-right: 0;
  }
}
.p-newsSeminarLink_list--item:not(:nth-child(-n+2)) {
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .p-newsSeminarLink_list--item:not(:first-child) {
    margin-top: 2rem;
  }
}
.p-newsDetail {
  padding: 8rem 0;
}
@media screen and (max-width: 767px) {
  .p-newsDetail {
    padding: 4rem 2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-newsDetail_wrap {
    display: block;
  }
}
.p-newsDetail_wrap--left {
  width: calc(100% - 20rem);
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-newsDetail_wrap--left {
    width: calc(100% - 15rem);
  }
}
@media screen and (max-width: 767px) {
  .p-newsDetail_wrap--left {
    width: 100%;
  }
}
.p-newsDetail_wrap--right {
  width: 20rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-newsDetail_wrap--right {
    width: 15rem;
  }
}
@media screen and (max-width: 767px) {
  .p-newsDetail_wrap--right {
    width: 100%;
    margin-top: 4rem;
  }
}
.p-newsArchive_ttl {
  font-size: 2rem;
  width: 100%;
  height: 5.5rem;
  line-height: 5.5rem;
  position: relative;
}
@media screen and (max-width: 900px) {
  .p-newsArchive_ttl {
    font-size: 1.6rem;
    height: 4rem;
    line-height: 4rem;
  }
}
.p-newsArchive_ttl:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 55px 16px 0 0;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 900px) {
  .p-newsArchive_ttl:before {
    border-width: 40px 10px 0 0;
  }
}
.p-newsArchive_list--item {
  border-bottom: 0.1rem solid #DDD;
}
.p-newsArchive_list--item a {
  display: block;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-newsArchive_list--item a {
    font-size: 1.4rem;
  }
}
.p-newsPost {
  padding: 0 8.333%;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-newsPost {
    padding: 0 4rem;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost {
    padding: 0;
  }
}
.p-newsPost_ttl {
  font-size: 2.5rem;
  padding-bottom: 2.5rem;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-newsPost_ttl {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost_ttl {
    font-size: 1.8rem;
  }
}
.p-newsPost_ttl:after {
  content: "";
  width: 100%;
  height: 0.8rem;
  background-color: #D30F1B;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: skew(-15deg, 0deg);
  -webkit-transform: skew(-15deg, 0deg);
}
.p-newsPost_cont h2 {
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 0.7rem 0 0.7rem 2.5rem;
  border-left: 0.5rem solid #D30F1B;
  margin: 6rem 0 3rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-newsPost_cont h2 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost_cont h2 {
    font-size: 1.8rem;
    padding: 0.5rem 0 0.5rem 1rem;
    margin: 4rem 0 2rem;
    border-left: 0.3rem solid #D30F1B;
  }
}
.p-newsPost_cont h3 {
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 0.7rem 0 0.7rem 2.5rem;
  border-left: 0.5rem solid #DDD;
  margin: 4rem 0 2rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .p-newsPost_cont h3 {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 767px) {
  .p-newsPost_cont h3 {
    font-size: 1.8rem;
    padding: 0.5rem 0 0.5rem 1rem;
    border-left: 0.3rem solid #DDD;
  }
}
.p-newsPost_cont p,
.p-newsPost_cont li,
.p-newsPost_cont figcaption {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
}
@media screen and (max-width: 767px) {
  .p-newsPost_cont p,
.p-newsPost_cont li,
.p-newsPost_cont figcaption {
    font-size: 1.4rem;
  }
}
.p-newsPost_cont p strong,
.p-newsPost_cont li strong,
.p-newsPost_cont figcaption strong {
  font-weight: 700;
}
.p-newsPost_cont img {
  margin: 6rem 0;
}
@media screen and (max-width: 767px) {
  .p-newsPost_cont img {
    margin: 3rem 0;
  }
}
.p-newsPost_cont ul.col-2 {
  display: flex;
}
@media screen and (max-width: 767px) {
  .p-newsPost_cont ul.col-2 {
    flex-direction: column;
  }
}
.p-newsPost_cont ul.col-2 li {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}
@media screen and (max-width: 767px) {
  .p-newsPost_cont ul.col-2 li {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
.p-newsPost_cont ul.col-2 li p img {
  margin: 0;
}
.p-newsPost_cont ul.row-2 {
  display: flex;
}
@media screen and (max-width: 767px) {
  .p-newsPost_cont ul.row-2 {
    flex-direction: column;
  }
}
.p-newsPost_cont ul.row-2 li {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  text-align: center;
}
.p-newsPost_cont ul.row-2 li p {
  margin-bottom: 1rem;
}
.p-newsPost_cont ul.row-2 li p img {
  margin: 0;
}
.p-newsPost_cont dl.row2 dt {
  float: left;
  margin-bottom: 1rem;
}
@media screen and (max-width: 767px) {
  .p-newsPost_cont dl.row2 dt {
    float: none;
    width: 100%;
    margin-bottom: 0.5rem;
  }
}
.p-newsPost_cont dl.row2 dd {
  margin-bottom: 1rem;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .p-newsPost_cont dl.row2 dd {
    margin-bottom: 2rem;
  }
}
.p-newsPost_cont .figwrap {
  display: flex;
}
.p-newsPost_cont .figwrap figure {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  align-items: center;
}
.p-newsPost_cont .figwrap figure img {
  margin: 0;
}
.p-newsPost_cont .figwrap figure figcaption {
  text-align: center;
}
.p-newsPost_cont .pd-l__1 {
  padding-left: 1em;
}
.p-newsPost_cont .pd-l__2 {
  padding-left: 2em;
}
.p-newsPost_cont .pd-l__3 {
  padding-left: 3em;
}
.p-newsPost_cont .pd-l__4 {
  padding-left: 4em;
}
.p-newsPost_cont .pd-l__5 {
  padding-left: 5em;
}
.p-newsPost_cont .pd-l__6 {
  padding-left: 6em;
}
.p-newsPost_cont .youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.p-newsPost_cont .youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
}

.p-maintenance {
  width: 100%;
  height: 100vh;
}
.p-maintenance_wrap {
  padding: 0 2rem;
}

.post-content {
  font-family: "Noto Sans JP", sans-serif;
}