.p-treatment {
  &Intro {
    &_list {
      &--item {
        width: 30.5%;
        margin-right: 4.25%;
        @include sp {
          width: 48%;
          margin-right: 0;
        }

        &:not(:nth-child(-n+3)) {
          margin-top: 3.5rem;
        }

        &:nth-child(2n) {
          @include sp {
            margin-left: 4%;
          }
        }

        &:not(:nth-child(-n+2)) {
          @include sp {
            margin-top: 2rem;
          }
        }

        &.right {
          margin-right: 0;
        }
      }

      &--pic {
        box-shadow: $shadow04;
        @include sp {
          box-shadow: $shadowSp04;
        }
      }
    }
  }

  &Exercise {
    &_ttl {
      font-size: 4rem;
    }
  }
}