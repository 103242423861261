.c-table {
  &_company {
    width: 100%;
    border-top: 0.1rem solid #D3D3D3;
    border-left: 0.1rem solid #D3D3D3;
    th,td {
      padding: 3rem 4rem;
      border-bottom: 0.1rem solid #D3D3D3;
      border-right: 0.1rem solid #D3D3D3;
      text-align: left;
      @include sp {
        display: block;
        padding: 1rem 2rem;
      }
    }
    th {
      width: 18rem;
      background-color: $gray;
      letter-spacing: 0.2rem;
      @include sp {
        width: 100%;
      }
    }

    td {
      width: calc( 100% - 18rem );
      letter-spacing: 0.1rem;
      @include sp {
        width: 100%;
      }

      .pc {
        display: none;
        @include pc {
          display: inline-block;
        }
      }

      .sp {
        display: none;
        @include sp {
          display: inline-block;
        }
      }

      .map {
        font-size: 1.4rem;
        width: 15rem;
        height: 2.8rem;
        padding: 0 1rem;
        margin-left: 2rem;
        border-radius: 0.4rem;
        line-height: 2.8rem;
        display: inline-block;
        position: relative;
        @include tab02 {
          font-size: 1.2rem;
          width: 12.5rem;
          height: 2rem;
          line-height: 2rem;
          margin: 0.5rem 0 0;
          display: block;
        }
        &:after {
          content: '';
          width: 1.4rem;
          height: 1.4rem;
          background: url('../img/common/ico_link01_w.svg') no-repeat;
          background-size: contain;
          position: absolute;
          right: 1.3rem;
          top: 0.6rem;
          @include tab02 {
            width: 1.2rem;
            height: 1.2rem;
            top: 0.3rem;
            right: 0.8rem;
          }
        }
      }
    }
  }

  &_history {
    width: 100%;

    tr {
      &:not(:last-child) {
        border-bottom: 0.1rem solid #D3D3D3;
      }

      &:first-child {
        th {
          &:before {
            content: '';
            width: 0.4rem;
            height: 3.4rem;
            background-color: $white;
            position: absolute;
            top: 0;
            right: -0.2rem;
            @include sp {
              height: 2.1rem;
            }
          }
        }
      }

      &:last-child {
        th {
          &:before {
            content: '';
            width: 0.2rem;
            height: 3.2rem;
            background: url('../img/common/line_table01.svg') no-repeat;
            background-size: contain;
            position: absolute;
            bottom: -4.3rem;
            right: -0.2rem;
          }
        }
      }
    }
    th {
      font-size: 2.4rem;
      font-weight: bold;
      letter-spacing: 0.2rem;
      line-height: 1;
      width: 12rem;
      padding: 3rem 0;
      border-right: 0.2rem solid $red;
      position: relative;
      @include sp {
        font-size: 1.6rem;
        letter-spacing: 0.1rem;
        width: 7.5rem;
        padding: 1.5rem 0;
      }
      &:after {
        content: '';
        width: 2.2rem;
        height: 2.2rem;
        background: url('../img/common/ico_table01.svg') no-repeat;
        background-size: contain;
        position: absolute;
        top: 3.3rem;
        right: -1.2rem;
        @include sp {
          width: 1.6rem;
          height: 1.6rem;
          right: -0.9rem;
          top: 1.7rem;
        }
      }
    }

    td {
      width: calc( 100% - 12rem);
      padding: 3rem 0 3rem 5.5rem;
      @include sp {
        font-size: 1.2rem;
        width: calc( 100% - 7.5rem);
        padding: 1.5rem 0 1.5rem 2rem;
      }
    }
  }
}