.p-hybrid {
  &Intro {
    &_logo {
      @include sp {
        width: 25rem;
      }
    }
  }

  &Effect {
    &_ttl {
      img {
        width: auto;
        height: 5.72rem;
        vertical-align: baseline;
        margin-right: 1rem;
        @include tab {
          height: 4.5rem;
        }
        @include sp {
          height: 3.5rem;
        }
      }
    }
  }
}