.p-career {
  position: relative;
  &Contents {
    &_text {
      @include sp {
        text-align: left;
      }

      p {
        &:not(:first-child) {
          @include sp {
            margin-top: 1rem;
          }
        }
      }
    }
    &_img {
      max-width: 56.3rem;
      width: 100%;
      margin: 4rem auto 0;
      @include sp {
        margin-top: 3rem;
      }
    }
  }

  &Curriculum {
    &_text {
      @include sp {
        text-align: left;
      }
    }
  }

  &CurriculumDetail {
    &_list {
      @include tab {
        display: block;
      }

      &--item {
        @include tab {
          max-width: none;
          width: 100%;
        }
        &:not(:first-child) {
          @include tab {
            margin-top: 3rem;
          }
          @include sp {
            margin-top: 3rem;
          }
        }
      }

      &--pic {
        @include sp {
          margin-top: 1rem;
        }
      }
    }
  }

  &Examination {
    .c-mt--40 {
      @include sp {
        margin-top: 2rem;
      }
    }
  }

  &Special {
    &_ttl {
      &--sub {
        span {
          display: inline-block;
          position: relative;
          &:before,
          &:after {
            content: '';
            width: 0.4rem;
            height: 3rem;
            background-color: $red;
            position: absolute;
            top: 1rem;
            @include sp {
              width: 0.2rem;
              height: 2rem;
              top: 0.6rem;
            }
          }
          &:before {
            transform: rotate(-25deg);
            left: -2rem;
            @include sp {
              left: -1.5rem;
            }
          }
          &:after {
            transform: rotate(25deg);
            right: -2rem;
            @include sp {
              right: -1.5rem;
            }
          }
        }
      }
    }
  }

  &Price {
    &_list {
      @include tab {
        display: block;
      }

      &--item {
        @include tab {
          max-width: 40rem;
          width: 100%;
          margin: 0 auto;
        }

        &:last-child {
          @include tab {
            margin-top: 3rem;
          }
          @include sp {
            margin-top: 2rem;
          }
        }
      }

      &--btn {
        a {
          &:after {
            right: 1.5rem;
          }
        }
      }
    }

    &_btn {
      &--text {
        @include sp {
          letter-spacing: 0;
        }
      }
    }
  }

  &Session {
    &_ttl {
      span {
        font-size: 3.2rem;
        @include tab {
          font-size: 2.4rem;
        }
      }
    }

    &_list {
      &--item {
        padding-left: 4rem;
        position: relative;
        @include sp {
          padding-left: 2.5rem;
        }
        span {
          font-size: 2.4rem;
          line-height: 1;
          position: absolute;
          top: 0.2rem;
          left: 0;
          @include sp {
            font-size: 1.8rem;
            top: 0.3rem;
          }
        }

        &:not(:first-child) {
          margin-top: 3rem;
        }
      }
    }

    &_btn {
      max-width: 40rem;
      width: 100%;
      margin: 6rem auto 0;
      @include sp {
        margin-top: 4rem;
      }
    }

    &_code {
      &--img {
        width: 19.5rem;
        margin: 2rem auto 0;
        @include sp {
          margin-top: 1rem;
        }
      }
    }
  }

  &Faq {
    &_btn {
      &List {
        @include tab02 {
          display: block;
        }
        &--item {
          &.mail {
            max-width: 51.4rem;
            width: 54%;
            @include tab02 {
              width: 100%;
              margin: 0 auto;
            }

            .c-btn_white--text {
              font-size: 1.8rem;
              @include sp {
                font-size: 1.4rem;
              }
            }
          }

          &.tel {
            max-width: 40rem;
            width: 41%;
            @include tab02 {
              width: 100%;
              margin: 3rem auto 0;
            }
          }
        }
      }
    }
  }

  &_ico {
    position: fixed;
    bottom: 25rem;
    right: 9rem;
    z-index: 10;
    opacity: 0;
    transition: opacity $anime;
    pointer-events: none;
    @include tab {
      bottom: 18rem;
      right: 4.5rem;
    }
    @include sp {
      right: 1.5rem;
      bottom: 14rem;
    }
    a {
      width: 15rem;
      height: 15rem;
      border-radius: 1.4rem;
      display: block;
      overflow: hidden;
      box-shadow: 0.2rem 0.3rem 2rem rgba( $black, 0.26 );
      transform: rotate(-45deg);
      @include tab {
        width: 10rem;
        height: 10rem;
        border-radius: 1rem;
      }
      @include sp {
        width: 7rem;
        height: 7rem;
        border-radius: 0.7rem;
      }
    }

    &.is-view {
      opacity: 1;
      pointer-events: auto;
    }

    &.is-absolute {
      position: absolute;
      bottom: 13rem;
      @include tab {
        bottom: 8rem;
      }
      @include sp {
        bottom: 6rem;
      }
    }
  }
}