.p-sports {
  &Case {
    &_movie {
      &--pic {
        max-width: 40rem;
        width: 100%;
        margin: 2.5rem auto 0;
      }
    }
  }
}