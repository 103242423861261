.p-facility {

  &Search {
    &_list {
      @include sp {
        display: flex;
        justify-content: space-between;
      }
      &--item {
        position: relative;
        @include sp {
          width: 48%;
        }

        &:nth-child(-n + 2) {
          margin-top: 0 !important;
        }
      }
      &--wrap {
        width: 100%;
        border-top: 0.2rem solid $gray;
        position: absolute;
        top: 7rem;
        left: 0;
        z-index: 1;
        @include sp {
          top: 5rem;
        }
      }

      &--scroll {
        width: 100%;
        max-height: 30rem;
        overflow: scroll;
        scrollbar-width: thin;
        @include sp {
          max-height: 20rem;
        }
        &::-webkit-scrollbar {
          width: 1.1rem;
          height: 0;
          @include sp {
            width: 0.5rem;
          }
        }
        &::-webkit-scrollbar-thumb {
          background-color: $dullColor;
          border-radius: 0.6rem;
          @include sp {
            border-radius: 0.3rem;
          }
        }
      }
    }

    &_district {
      width: 100%;
      height: 100%;
      overflow: scroll;
      position: absolute;
      top: 0;
      right: 100%;
      display: none;
      scrollbar-width: thin;
      @include sp {
        right: auto;
        left: 100%;
      }
      &.osaka {
        right: auto;
        left: 100%;
        @include pc {
          padding-left: 1.1rem;
          left: calc( 100% - 1.1rem);
        }
        @include sp {
          right: 100%;
          left: auto;
        }
      }
      &.is-view {
        display: block;
      }
      &::-webkit-scrollbar {
        width: 1.1rem;
        height: 0;
        @include sp {
          width: 0.5rem;
        }
      }
      &::-webkit-scrollbar-thumb {
        background-color: $dullColor;
        border-radius: 0.6rem;
        @include sp {
          border-radius: 0.3rem;
        }
      }

      .c-btn_search--sub {
        border-bottom: 0.2rem solid $white;
        &:hover {
          background-color: $dullColor;
        }
        &.is-selected {
          background-color: $dullColor;
        }
      }
    }
  }

  &_box {
    border-bottom: 0.2rem solid $gray;
    &--ttl {
      padding: 0 4rem;
      @include sp {
        padding: 0 2rem;
      }
    }
  }

  &_sec {
    padding: 120px 0 0;
    @include sp {
      padding: 6rem 0 0;
    }
  }

	&Cont {

		.p-facilityPets {
			.c-btn_link {
				position: relative;
				width: auto;
				height: 7rem;
				padding: 0 4em 0 2em;
				@include sp {
					padding-left: 7.5rem;
					//padding-right: 7.5rem;
				}

				&:after {
					content: '';
					width: 1.3rem;
					height: 0.8rem;
					margin: auto 0;
					background: url('../img/common/arrow_index01.svg') no-repeat;
					background-size: contain;
					position: absolute;
					top: 0;
					bottom: 0;
					right: 7%;
					transform: rotate(-90deg);
				}

				.c-boxMedicell_top--ico {
					position: static;
					transform: translateY(0);
					@include sp {
						position: absolute;
						transform: translateY(-50%);
					}

					img {
						width: 40px;
						@include sp {
							width: auto;
						}
					}
				}
				.c-boxMedicell_top--ttl {
					width: 18em;
					@include sp {
						width: auto;
					}
				}
			}
		}
		&Pets {
			.c-ttl_line {
				justify-content: center;
				img {
					width: 100px;
					height: auto;
					@include sp {
						width: 4rem;
					}
				}
			}
			.p-facilityPets {
				.c-btn_link {
					@include sp {
						padding-left: 2em;
					}
					.c-boxMedicell_top--ttl {
						width: auto;
					}
				}
			}
		}
	}

}
