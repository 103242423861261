.p-skinlift {
  &_logo {
    display: block;
    margin: auto;
    width: 46rem;
    max-width: 60%;
    height: auto;
  }

  &_title {
    margin-top: 3rem;

    @include sp {
      margin-top: 2rem;
    }

    span {
      @include tab {
        letter-spacing: 0.2rem;
      }
      @include sp {
        font-feature-settings: 'palt';
        letter-spacing: 0;
        padding: 0 0.5rem 1rem;
      }
    }
  }

  &_lead {
    margin-top: 5rem;
    line-height: 1.6;

    @include sp {
      font-feature-settings: 'palt';
      margin-top: 3rem;
    }
  }

  &_slide {
    margin-top: 4rem;

    @include sp {
      margin-top: 3rem;
    }
  }

  &_text {
    margin-top: 6rem;

    @include sp {
      margin-top: 3rem;
    }
  }

}